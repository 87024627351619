import { readDeliveryTime } from './read-delivery-time';
import { readProviderDescription } from './read-provider-description';

export const mailOrderProviderFields = {
    description: {
        read: readProviderDescription,
    },
    deliveryTimeDisplay: {
        read: readDeliveryTime,
    },
};

export const pharmacyProviderFields = {
    description: {
        read: readProviderDescription,
    },
};
