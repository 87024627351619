import { BareCard, Body1, Box, Flex } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { ClaimInfoFragment } from '../../graphql/generated/graphql';
import {
    isValidPatientCost,
    isValidPlanCost,
} from '../../graphql/policies/util';
import { formatToLocalizedCurrency } from '../../util';

interface PhxBenefitsClaimProps {
    claim: ClaimInfoFragment;
}

const PhxBenefitsClaim = ({ claim }: PhxBenefitsClaimProps) => {
    const { t } = useTranslation();
    const { patientPayAmount, planPayAmount } = claim;
    const isValidCost =
        isValidPatientCost({ patientResponsibilityCost: patientPayAmount }) &&
        isValidPlanCost({ planPaysAmount: planPayAmount });

    if (!isValidCost) {
        return null;
    }

    const subtitle = t('claim.planMap.phxBenefitsPlan.subtitle', {
        price: formatToLocalizedCurrency(planPayAmount!),
    });

    return (
        <BareCard
            header={
                <Flex justify="space-between">
                    <Box>
                        {t('claim.planMap.phxBenefitsPlan.withInsurance')}
                    </Box>
                    <Box>{formatToLocalizedCurrency(patientPayAmount!)}</Box>
                </Flex>
            }
        >
            <Body1>{subtitle}</Body1>
        </BareCard>
    );
};

export default PhxBenefitsClaim;
