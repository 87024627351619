import {
    ApolloError,
    type QueryHookOptions,
    type TypedDocumentNode,
    useQuery,
} from '@apollo/client';
import { Box } from '@phx/design-system';
import type { DocumentNode } from 'graphql';
import { Suspense } from 'react';

import { Loader } from '../components/common/Loader';
import RootErrorRoute from '../routes/error/RootErrorRoute';
import type { RequiredQueryVariables, ResultOf } from '../types';

export type ExtractDocumentNodeDataValue<Type> =
    Type extends TypedDocumentNode<infer X, infer _Y> ? X : never;
export type ExtractDocumentNodeVariableValue<Type> =
    Type extends TypedDocumentNode<infer _X, infer Y> ? Y : never;

export type LoadingIndicatorProps<Q extends DocumentNode> = {
    query: Q;
    loadingPlaceholderComponent?: JSX.Element;
    renderError?: (error: ApolloError) => JSX.Element | null;
    errorComponent?: JSX.Element;
    queryOptions?: Partial<Omit<QueryHookOptions, 'variables'>>;
} & RequiredQueryVariables<Q> &
    (
        | {
              component: (props: { data: ResultOf<Q> }) => JSX.Element;
              render?: undefined;
          }
        | {
              render: (data: ResultOf<Q>) => JSX.Element | null;
              component?: undefined;
          }
    );

export type DataHandlerType<Q extends DocumentNode, OtherProps = object> = (
    props: {
        data: ResultOf<Q>;
    } & OtherProps
) => JSX.Element;

export const QueryLoader = <Q extends DocumentNode>(
    props: LoadingIndicatorProps<Q>
) => {
    const queryOptions: QueryHookOptions | undefined =
        'variables' in props
            ? ({ variables: props.variables } as QueryHookOptions)
            : undefined;

    const {
        query,
        component: Child,
        render,
        errorComponent,
        loadingPlaceholderComponent,
        renderError,
    } = props;

    const { data, loading, error } = useQuery(query, {
        ...queryOptions,
        ...props.queryOptions,
    });

    if (loading) {
        return loadingPlaceholderComponent || <Loader />;
    }

    //TODO: better error handling is needed. We probably should get rid of the query loader entirely.
    // If error policy not set to 'all' or 'ignore' OR we passed an error handler
    if (
        (error && props.queryOptions?.errorPolicy === 'none') ||
        (error && (errorComponent || renderError))
    ) {
        console.error(
            `threw an error when query ${JSON.stringify(query)} resolved.`
        );

        return (
            renderError?.(error) ??
            errorComponent ?? (
                <Box p="md">
                    <RootErrorRoute />
                </Box>
            )
        );
    }

    return (
        <Suspense>
            {Child && <Child data={data} />}
            {render?.(data)}
        </Suspense>
    );
};
