import { useTranslation } from 'react-i18next';

/**
 * Pluralizes dosage forms based on the provided form and quantity (count).
 * @param {string} form - The type dosage form.
 * @param {number} count - The quantity of the dosage form.
 * @returns {string} - A translated dosage form string.
 */
export const useDosageForm = (form: string, count: number) => {
    const { t } = useTranslation();
    const formKey = form?.toLowerCase();

    switch (formKey) {
        case 'tablet':
            return t('common.dosageForm.tablet', { count });
        case 'packet':
            return t('common.dosageForm.packet', { count });
        case 'capsule':
            return t('common.dosageForm.capsule', { count });
        case 'patch':
            return t('common.dosageForm.patch', { count });
        case 'pen':
            return t('common.dosageForm.pen', { count });
        case 'suppository':
            return t('common.dosageForm.suppository', { count });
        case 'ring':
            return t('common.dosageForm.ring', { count });
        default:
            return t('common.dosageForm.default', { count, dosageForm: form });
    }
};
