import {
    Body1,
    Flex,
    IconButton,
    IconControlledSubstance,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { RxForUser } from '../../cabinet/rx-for-user/RxForUser';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import { useNavigate } from 'react-router-dom';
import { getAbsoluteRoute } from '../../../util';
import type { PatientProductDetailsProps } from './PatientProductDetails';

export const PatientProductDetailsHeader = ({
    patientName,
    productDescription,
    quantity,
}: Omit<PatientProductDetailsProps, 'prescriberOrder'>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // temp until CS field comes in
    const isControlledSubstance = false;

    return (
        <Stack gap="xxs">
            {patientName ? (
                <RxForUser patient={{ fullName: patientName }} />
            ) : null}
            <Flex align="center">
                <Subtitle2 data-testid="prescriber-order-product-details-header">
                    {productDescription}
                </Subtitle2>
                {isControlledSubstance ? (
                    <IconButton
                        Icon={IconControlledSubstance}
                        withStyles={false}
                        onClick={() =>
                            navigate(getAbsoluteRoute('helpCenter.medications'))
                        }
                    />
                ) : null}
            </Flex>
            <ConditionalComponent condition={!!quantity}>
                <Body1>
                    {t('order.prescribedQuantity', {
                        quantity,
                    })}
                </Body1>
            </ConditionalComponent>
        </Stack>
    );
};
