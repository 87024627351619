import type { FieldReadFunction } from '@apollo/client';

import i18n from '../../../i18n';
import { isNullOrUndefined } from '../util';

export const readDeliveryTime: FieldReadFunction = (_, { readField }) => {
    const min = readField<number>('deliveryDaysMin');
    const max = readField<number>('deliveryDaysMax');
    /*
    X to Y business days, where X is minimum and Y is maximum
    If X is only provided then we can say X business days, maybe in the future we could say at least X business days
    If Y is only provided then we can say Y business days, maybe in the future we could say up to Y business days
    */

    if (isNullOrUndefined(min) && isNullOrUndefined(max)) {
        return i18n.t('offerPharmacyCard.deliveryTime');
    } else if (!isNullOrUndefined(min) && !isNullOrUndefined(max)) {
        return i18n.t('offerPharmacyCard.deliveryRange', { min, max });
    }

    return !isNullOrUndefined(min)
        ? i18n.t('offerPharmacyCard.deliveryMin', { min })
        : i18n.t('offerPharmacyCard.deliveryMax', { max });
};
