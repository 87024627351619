import { Anchor, Body1, Flex, Stack } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

import { DeliveryStep, type LocationInfo } from './NextSteps';

export const MailOrderNextSteps = ({
    locationInfo,
}: {
    locationInfo: LocationInfo;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            {locationInfo.url ? (
                <Stack gap={0}>
                    <Flex align="start" py="xxs">
                        <Trans
                            i18nKey="orderConfirmation.mailOrderUrl.stepOne"
                            values={{
                                locationName: locationInfo.name,
                            }}
                        >
                            <Body1>
                                1. Sign in or sign up to{' '}
                                <Anchor href={locationInfo.url} target="_blank">
                                    {locationInfo.name}
                                </Anchor>
                            </Body1>
                        </Trans>
                    </Flex>
                    <DeliveryStep
                        stepTexts={[
                            t('orderConfirmation.mailOrderUrl.stepTwo'),
                            t('orderConfirmation.mailOrderUrl.stepThree'),
                            t('orderConfirmation.mailOrderUrl.stepFour'),
                            t('orderConfirmation.mailOrderUrl.stepFive'),
                            t('orderConfirmation.mailOrderUrl.footNote'),
                        ]}
                    />
                </Stack>
            ) : (
                <Stack gap={0}>
                    <DeliveryStep
                        stepTexts={[
                            t('orderConfirmation.mailOrderPhone.description'),
                            t('orderConfirmation.mailOrderPhone.stepOne'),
                            t('orderConfirmation.mailOrderPhone.stepTwo'),
                            t('orderConfirmation.mailOrderPhone.stepThree'),
                            t('orderConfirmation.mailOrderPhone.footNote'),
                        ]}
                    />
                </Stack>
            )}
        </Stack>
    );
};
