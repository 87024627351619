import {
    Body1,
    Body2,
    FilledCard,
    IconChevronRight,
    IconSavings,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const ComparePricesCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const drugSearchPage = getAbsoluteRoute('drug.search.root');

    return (
        <FilledCard
            color="gray"
            Icon={IconSavings}
            header={
                <Body1 bold>{t('startSavingPage.comparePrices.heading')}</Body1>
            }
            footer={
                <TextButton RightIcon={IconChevronRight}>
                    {t('startSavingPage.comparePrices.button')}
                </TextButton>
            }
            onClick={() => navigate(drugSearchPage)}
        >
            <Body2>{t('startSavingPage.comparePrices.body')}</Body2>
        </FilledCard>
    );
};
