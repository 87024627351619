import { AuthorizedOutlet } from '@phx/auth/client';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import { DrugOfferSelectionRoute } from './DrugOfferSelectionRoute';
import { DrugRoute } from './DrugRoute';
import { DrugSearchRoute } from './DrugSearchRoute';

const drugRoutes: RouteObject[] = [
    {
        path: routes.drug.root,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate to={routes.drug.search.root} />,
            },
            {
                path: routes.drug.drugId.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <DrugRoute />,
                    },
                    {
                        path: routes.drug.drugId.providerOffers.root,
                        element: <AuthorizedOutlet />,
                        children: [
                            {
                                index: true,
                                element: <DrugOfferSelectionRoute />,
                            },
                        ],
                    },
                ],
            },
            {
                path: routes.drug.search.root,
                element: <DrugSearchRoute />,
                children: [
                    {
                        path: routes.drug.search.drugId,
                        element: <div>/drug/search/:drugId</div>,
                    },
                ],
            },
        ],
    },
];

export default drugRoutes;
