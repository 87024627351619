import type { ResultOf } from '@graphql-typed-document-node/core';
import { type PropsWithChildren as PWC, createContext } from 'react';

import { getFragment } from '../graphql';
import {
    GetPatientDependentsDocument,
    type PatientInfoFragment,
} from '../graphql/generated/graphql';
import { usePatientContext } from '../hooks/use-patient-context';
import { QueryLoader } from '../loaders';

export type ProfileContextType = {
    patient: PatientInfoFragment;
    profile: NonNullable<
        ResultOf<typeof GetPatientDependentsDocument>['patient']
    >;
};

export const ProfileContext = createContext<ProfileContextType | null>(null);

const InnerProfileProvider = ({
    children,
    profile,
}: PWC<{
    patient: ProfileContextType['patient'];
    profile: ProfileContextType['profile'];
}>) => {
    const patient = getFragment(profile);

    const ctx = { profile, patient };

    return (
        <ProfileContext.Provider value={ctx}>
            {children}
        </ProfileContext.Provider>
    );
};

export const ProfileProvider = ({ children }: PWC) => {
    const { primaryPatientId: patientId } = usePatientContext();
    const Loader = QueryLoader<typeof GetPatientDependentsDocument>;

    return (
        <Loader
            query={GetPatientDependentsDocument}
            variables={{
                patientId,
                input: {
                    relationshipTypes: ['DEPENDENT', 'SPOUSE'],
                },
            }}
            component={({ data }) => {
                if (data?.patient) {
                    const patient = getFragment(data.patient);

                    if (patient) {
                        return (
                            <InnerProfileProvider
                                patient={patient}
                                profile={data.patient}
                            >
                                {children}
                            </InnerProfileProvider>
                        );
                    }
                }

                // @TODO: fail safe
                throw Error('Unknown');
            }}
        />
    );
};
