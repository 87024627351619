import { Box, Caption } from '@phx/design-system';

export const AppVersion = () => {
    const version = import.meta.env.VITE_BUILD_NUMBER;

    if (!version) {
        return null;
    }

    return (
        <Box px="sm" py="xs" style={{ color: 'var(--mantine-color-gray-3)' }}>
            <Caption color="currentColor">{`v. ${version}`}</Caption>
        </Box>
    );
};
