import { useApolloClient } from '@apollo/client';

/**
 * usePatientFavoritesCache is meant to only encapsulate logic for
 * useFavoritePharmacies and not to be used elsewhere.
 */
export const usePatientFavoritesCache = (patientId: string) => {
    const { cache } = useApolloClient();
    return <T>(edges: T | undefined) => {
        if (edges === undefined) {
            return;
        }
        cache.modify({
            id: `Patient:${patientId}`,
            fields: {
                preferences: () => {
                    return {
                        preferredLanguage: null,
                        notificationsPreferences: null,
                        patientFavoriteLocationsConnection: {
                            edges,
                        },
                    };
                },
            },
        });
    };
};
