import { useCallback, useState } from 'react';

export const DEFAULT_INITIAL_PRESCRIPTION_COUNT = 25;

export const useVisiblePrescriptionCount = (
    count: number = DEFAULT_INITIAL_PRESCRIPTION_COUNT
) => {
    const [visibleCount, setVisibleCount] = useState(count);

    const showMorePrescriptions = useCallback(() => {
        setVisibleCount((prev) => prev + count);
    }, [count, setVisibleCount]);

    return { visibleCount, showMorePrescriptions };
};
