import { IconPermContactCalendar, SideNavLink } from '@phx/design-system';
import { usePwaContext } from '@phx/pwa';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebarContext } from '../../../contexts/SidebarProvider';

export const AddToContactsMenuItem = () => {
    const { t } = useTranslation();
    const { addContact } = usePwaContext();
    const { close } = useSidebarContext();

    const handler = useCallback(() => {
        addContact();
        close();
    }, [addContact, close]);

    return (
        <SideNavLink
            label={t('sidebar.helpAndSettings.addToContacts')}
            Icon={IconPermContactCalendar}
            onClick={handler}
        />
    );
};
