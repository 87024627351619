import { Body1, Stack, Subtitle1 } from '@phx/design-system';
import { IllustrationCard, NoResultsIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

export const NoResults = () => {
    const { t } = useTranslation();

    return (
        <IllustrationCard
            illustrationPosition="top"
            Illustration={NoResultsIllustration}
            illustrationSize="lg"
            variant="bare"
            withShadow={false}
        >
            <Stack gap="xxs">
                <Subtitle1 ta="center">
                    {t('pharmacy.inNetwork.noResults.header')}
                </Subtitle1>
                <Body1 ta="center">
                    {t('pharmacy.inNetwork.noResults.body')}
                </Body1>
            </Stack>
        </IllustrationCard>
    );
};
