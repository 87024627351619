import { Carousel, Flex } from '@phx/design-system';
import { getCurrentOs, usePwaContext, usePwaFlags } from '@phx/pwa';
import { useMemo } from 'react';

import { useSwipeableViews } from '../../hooks/use-swipeable-views';

import { AddContactCard } from './AddContactCard';
import { AddToHomeCard } from './AddToHomeCard';
import { SaveMoneyFamilyCard } from './SaveMoneyFamilyCard';

const userOs = getCurrentOs();

export const MarketingCards = () => {
    const { flags } = usePwaFlags('phx');
    const { addToContacts, addToHome } = flags;
    const { canInstall, isInstalled, isStandalone } = usePwaContext();

    const cards = useMemo(() => {
        const arr = [<SaveMoneyFamilyCard key="save-money" />];

        if (userOs === 'desktop') {
            return arr;
        }

        if (!addToHome && canInstall && !isInstalled) {
            arr.push(<AddToHomeCard key="add-home" />);
        }

        if (!addToContacts) {
            arr.push(<AddContactCard key="add-contact" />);
        }

        return arr;
    }, [
        addToContacts,
        addToHome,
        canInstall,
        isInstalled,
        isStandalone,
        userOs,
    ]);

    const useSwipe = useSwipeableViews(cards);

    if (cards.length === 0) {
        return null;
    }

    if (useSwipe) {
        return (
            <Carousel
                cropped
                slides={cards}
                indicatorOffset="var(--mantine-spacing-xxs)"
            />
        );
    }

    return <Flex gap="xs">{cards.map((card) => card)}</Flex>;
};
