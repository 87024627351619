import { createFormContext } from '@phx/design-system/hooks';

export type MergeAccountFormDetails = {
    firstName: string;
    lastName: string;
    day: string;
    month: string;
    year: string;
    memberId: string;
};

export const [
    MergeAccountFormProvider,
    useMergeAccountFormContext,
    useMergeAccountForm,
] = createFormContext<MergeAccountFormDetails>();
