/**
 * Formats input to localized date.
 * TODO: Update with user locale when available in cache.
 * @param {Date | number} date - Date to be converted. This parameter may be given in either a Date object or as milliseconds in number format.
 * @param {Intl.DateTimeFormatOptions} [options] - Optional. Date Time Format options, For more information, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat.
 * @returns {string | RangeError} This function returns a string in the format of d/m/yyyy. If the passed date is not valid, it will return a range error.
 */

export const formatToLocalizedDate = (
    date: Date | number,
    options?: Intl.DateTimeFormatOptions,
    locale?: string
): string => {
    try {
        return new Intl.DateTimeFormat(locale ?? 'en-US', {
            timeZone: 'UTC',
            ...options,
        }).format(date);
    } catch (error) {
        // TODO add error reporting
        return '';
    }
};
export default formatToLocalizedDate;
