import { useQuery } from '@apollo/client';
import { IconPaid, IconSavings, NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import {
    GetAltProductOffersDocument,
    type ProviderOffersInfoFragment,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { usePrescriptionInputVariables } from '../../../routes/cabinet/prescription/use-prescription-variables';
import { formatToLocalizedCurrency } from '../../../util';
import { getAltProducts } from '../../../util/alternatives/get-alt-products';
import { getCheapestOffer } from '../../../util/alternatives/get-cheapest-offer';

type AlternativesLinkProps = {
    onAltsClick: () => void;
    cheapestOffers: ProviderOffersInfoFragment | undefined;
};

export const AlternativesLink = (props: AlternativesLinkProps) => {
    const { onAltsClick, cheapestOffers } = props;
    const { prescriptionId } = useParams();
    const { scopedPatientId } = usePatientContext();
    const distance = useNumericSearchParam('within');
    const { t } = useTranslation();

    if (!prescriptionId || !cheapestOffers) {
        return null;
    }
    const cheapestOffer = getCheapestOffer(cheapestOffers);

    const input = usePrescriptionInputVariables(distance, scopedPatientId);
    const { data, loading } = useQuery(GetAltProductOffersDocument, {
        variables: {
            prescriberOrderId: prescriptionId,
            input,
        },
    });
    if (loading || !data) {
        return null;
    }

    const { products, bestPatientSavings, bestPlanSavings } = getAltProducts(
        data.prescriberOrder?.fillRecommendationsConnection,
        cheapestOffer,
        getFragment(cheapestOffer.pharmacyProvider).id
    );

    if (
        products.length === 0 ||
        (bestPatientSavings <= 1 && bestPlanSavings < 20)
    ) {
        return null;
    }

    const { savingsText, Icon } =
        bestPatientSavings <= 1 && bestPlanSavings >= 20
            ? {
                  savingsText: t('pickYourPharmacy.altPlanSavings', {
                      value: formatToLocalizedCurrency(bestPlanSavings, {
                          maximumFractionDigits: 0,
                      }),
                  }),
                  Icon: IconPaid,
              }
            : {
                  savingsText: t('pickYourPharmacy.altSavings', {
                      value: formatToLocalizedCurrency(bestPatientSavings, {
                          maximumFractionDigits: 0,
                      }),
                  }),
                  Icon: IconSavings,
              };
    return (
        <NavLink
            label={savingsText}
            color="saving"
            variant="light"
            Icon={Icon}
            onClick={onAltsClick}
        />
    );
};
