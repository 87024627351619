import { initializeProvider } from '@phx/instrumentation/web';

/*
 * Sets up a basic telemetry provider with click tracking. Once the React app
 * loads, it will augment the provider with the AppInsights React plugin.
 */
window.telemetry = initializeProvider(
    import.meta.env.VITE_APPLICATION_INSIGHTS_CONNECTION_STRING,
    import.meta.env.VITE_BUILD_NUMBER
);
