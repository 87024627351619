import { NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../../util';

export const SpendSummaryLink = () => {
    const { t } = useTranslation();
    const { patient } = usePatientContext();
    const navigate = useNavigate();

    return (
        <ConditionalComponent condition={!!patient?.hasPbmPlan}>
            <NavLink
                label={t('cabinet.spendSummaryNav')}
                onClick={() => navigate(getAbsoluteRoute('plan.summary'))}
                variant="subtle"
                data-testid="cabinet-spend-route-btn"
            />
        </ConditionalComponent>
    );
};
