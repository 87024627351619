import {
    Body1,
    HorizontalDivider,
    OutlinedCard,
    SearchInput,
    Stack,
} from '@phx/design-system';
import { NoResultsIllustration } from '@phx/myphx-lib';
import Fuse, { type IFuseOptions } from 'fuse.js';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    ClaimGeneratedPrescriberOrderInfoFragment,
    DigitallyReceivedPrescriberOrderInfoFragment,
} from '../../../graphql/generated/graphql';
import { sortPrescriptionsByDate } from '../../../util/cabinet/prescriber-orders';

import { HistorySearchItem } from './HistorySearchItem';

export type Prescription =
    | ClaimGeneratedPrescriberOrderInfoFragment
    | DigitallyReceivedPrescriberOrderInfoFragment;

type HistorySearchProps = {
    prescriptions: Prescription[];
};

const fuseOptions: IFuseOptions<Prescription> = {
    keys: [
        { name: 'productDescription', weight: 2 }, // default weight is 1
        'patient.firstName',
        'patient.lastName',
        'prescribedProduct.formValue',
        'prescribedProduct.strengthUnit',
    ],
    threshold: 0.1,
    distance: 100,
};

export const HistorySearch = (props: HistorySearchProps) => {
    const { prescriptions } = props;
    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const filtered = useMemo(() => {
        if (!search) return prescriptions.sort(sortPrescriptionsByDate);

        const fuse = new Fuse<Prescription>(prescriptions, fuseOptions);

        return fuse
            .search(search)
            .map((f) => f.item)
            .sort(sortPrescriptionsByDate);
    }, [prescriptions, search, sortPrescriptionsByDate]);

    return (
        <Stack gap="lg">
            <SearchInput
                label="Find a prescription"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                clear={() => setSearch('')}
            />
            {filtered.length > 0 && (
                <OutlinedCard>
                    <Stack gap="sm">
                        {filtered.map((p, i) => (
                            <Fragment key={p.id}>
                                {i > 0 && <HorizontalDivider />}
                                <HistorySearchItem item={p} />
                            </Fragment>
                        ))}
                    </Stack>
                </OutlinedCard>
            )}
            {filtered.length === 0 && (
                <Stack align="center" justify="center">
                    <NoResultsIllustration />
                    <Body1 ta="center">{t('cabinet.history.noMatch')}</Body1>
                </Stack>
            )}
        </Stack>
    );
};
