import { getFragment, getFragments } from '../../graphql';
import {
    type OffersConnectionInfoFragment,
    SuggestedProviderOffersReason,
} from '../../graphql/generated/graphql';

export const getFeaturedProviderOffers = (
    offersConnection: OffersConnectionInfoFragment | undefined | null
) => {
    if (!offersConnection) {
        return undefined;
    }
    const suggested = getFragments(offersConnection.suggested);
    const featured =
        suggested.find(
            (x) => x.reason === SuggestedProviderOffersReason.Featured
        ) ??
        suggested.find(
            (x) => x.reason === SuggestedProviderOffersReason.Cheapest
        );
    const providerOffers = getFragment(
        offersConnection.edges.find((x) => x.node.id === featured?.targetId)
            ?.node
    );
    return (
        providerOffers &&
        featured && {
            providerOffers,
            reason: featured.reason,
        }
    );
};
