import { useAuthContext } from '@phx/auth/client';
import { useNavBar } from '@phx/design-system';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DrugSearch } from '../../components/drug-search/DrugSearch';
import { DrugSearchV2 } from '../../components/drug-search-v2/DrugSearchV2';
import { useFlags } from '../../hooks/use-flags';
import { getAbsoluteRoute } from '../../util';

export const DrugSearchRoute = () => {
    const { setBackOverrideState } = useNavBar();
    const { isAuthenticated } = useAuthContext();
    const { typeaheadSearchEnabled } = useFlags();

    const navigate = useNavigate();
    const onDrugSelect = (drugId: string, searchParams?: string) => {
        //@TODO: Telemetry
        const path = getAbsoluteRoute('drug.drugId.root', { drugId });
        navigate({
            pathname: path,
            search: searchParams ?? '',
        });
    };

    const handleBack = useCallback(() => {
        if (isAuthenticated) {
            navigate(getAbsoluteRoute('home'));
        } else {
            navigate(getAbsoluteRoute('root'));
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: handleBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [handleBack]);

    if (typeaheadSearchEnabled) {
        return <DrugSearchV2 shouldAutoFocus onDrugSelect={() => {}} />;
    }

    return <DrugSearch shouldAutoFocus onDrugSelect={onDrugSelect} />;
};
