import { useLayoutEffect, useRef } from 'react';

export const AppLoadedEventDispatcher = () => {
    const loadedState = useRef<boolean>(false);

    useLayoutEffect(() => {
        if (loadedState.current === false) {
            setTimeout(() => {
                loadedState.current = true;
                const event = new Event('applicationloaded');
                document.dispatchEvent(event);
            }, 0);
        }
    }, []);

    return null;
};
