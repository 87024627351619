import type { Reference, StoreObject, StoreValue } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const assertFieldExists = (value: any, fieldName: string) => {
    if (value === undefined || value === null) {
        throw new Error('Required field "' + fieldName + '" was not defined');
    }
};

export const useRequiredField =
    (readField: ReadFieldFunction) =>
    <T extends StoreValue>(
        fieldName: string,
        from?: StoreObject | Reference | undefined
    ) => {
        const value = from
            ? readField<T>(fieldName, from)
            : readField<T>(fieldName);
        assertFieldExists(value, fieldName);
        return value as NonNullable<typeof value>;
    };
