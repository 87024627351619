import { Checkbox, Heading2, Stack } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

import { ConditionalComponent } from '../utility/ConditionalComponent';

type FilterCheckboxGroupProps = {
    withInsurance: boolean;
    toggleWithInsurance: () => void;
    smartPrice: boolean;
    toggleSmartPrice: () => void;
    favorite: boolean;
    toggleFavorite: () => void;
    hasPhxBenefitsPlan: boolean;
};

const FilterCheckboxGroup = ({
    withInsurance,
    toggleWithInsurance,
    smartPrice,
    toggleSmartPrice,
    favorite,
    toggleFavorite,
    hasPhxBenefitsPlan,
}: FilterCheckboxGroupProps) => {
    const { t } = useTranslation();

    return (
        <>
            <ConditionalComponent condition={hasPhxBenefitsPlan}>
                <Stack>
                    <Heading2>
                        {t('filterAndSort.subtitle.pricingOptions')}
                    </Heading2>

                    <Stack>
                        <Checkbox
                            label={t('filterAndSort.checkBox.withInsurance')}
                            onChange={toggleWithInsurance}
                            checked={withInsurance}
                        />
                        <Checkbox
                            label={t('filterAndSort.checkBox.smartPRICE', {
                                tm: constants.unicode.TRADEMARK,
                            })}
                            onChange={toggleSmartPrice}
                            checked={smartPrice}
                        />
                    </Stack>
                </Stack>
            </ConditionalComponent>
            <Stack>
                <Heading2>{t('filterAndSort.subtitle.pharmacies')}</Heading2>
                <Checkbox
                    label={t('filterAndSort.checkBox.favoritePharmacies')}
                    onChange={toggleFavorite}
                    checked={favorite}
                />
            </Stack>
        </>
    );
};

export default FilterCheckboxGroup;
