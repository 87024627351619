import {
    Anchor,
    Body1,
    Box,
    Carousel,
    FilledCard,
    Flex,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCoverageCardData } from '../../hooks/use-coverage-card-data';
import { usePatientContext } from '../../hooks/use-patient-context';
import { useSwipeableViews } from '../../hooks/use-swipeable-views';
import { getAbsoluteRoute } from '../../util';

export const BenefitCards = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { patient } = usePatientContext();

    const patientId = patient?.id;
    const patientName = patient?.fullName;
    const coverages = useCoverageCardData();
    const useSwipe = useSwipeableViews(coverages);

    const cards = useMemo(() => {
        if (coverages.length === 0 || !patientName || !patientId) {
            return [];
        }
        return coverages.map((coverage) => {
            return (
                <Box
                    flex={1}
                    key={`${coverage.variant}-${coverage.planInfo.bin}`}
                >
                    <CoverageCard {...coverage} />
                </Box>
            );
        });
    }, [coverages, patientId, patientName]);

    if (cards.length === 0) {
        return null;
    }

    return (
        <FilledCard
            data-testid="carousel-card"
            header={
                <Stack gap="xs">
                    <Subtitle1>{t('home.benefitCards.heading')}</Subtitle1>
                    <Flex gap="xs">
                        <Body1>{t('home.benefitCards.subheading')}</Body1>
                        <Anchor
                            size="sm"
                            onClick={() =>
                                navigate(getAbsoluteRoute('profile.benefit'))
                            }
                        >
                            {t('home.benefitCards.detailsBtn')}
                        </Anchor>
                    </Flex>
                </Stack>
            }
        >
            {useSwipe ? (
                <Stack mb="sm">
                    <Carousel
                        cropped
                        slides={cards}
                        indicatorOffset="calc(var(--mantine-spacing-md) * -1)"
                    />
                </Stack>
            ) : (
                <Flex gap="sm">{cards}</Flex>
            )}
        </FilledCard>
    );
};
