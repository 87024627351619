import type { FieldFunctionOptions } from '@apollo/client';

import { readFormStrengthDisplayText } from './read-form-strength-display-text';

export const formFields = {
    strengthDisplayText: {
        read: readFormStrengthDisplayText,
    },
    packages: {
        merge(
            existing: any = [],
            incoming: any[],
            { readField }: FieldFunctionOptions
        ) {
            const results = new Map<string, any>();
            for (const item of existing) {
                results.set(readField<string>('id', item) ?? '', item);
            }
            for (const item of incoming) {
                results.set(readField<string>('id', item) ?? '', item);
            }
            return [...results.values()];
        },
    },
};
