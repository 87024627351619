import { Anchor, Body1 } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

export const AppealedDescription = ({
    onOpenDecisionLetter,
}: {
    onOpenDecisionLetter?: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Body1>
            {t('priorAuthorization.statusPage.appealed.partOne')}{' '}
            {onOpenDecisionLetter ? (
                <Trans
                    i18nKey="priorAuthorization.statusPage.appealed.partTwo"
                    components={{
                        2: <Anchor onClick={onOpenDecisionLetter} />,
                    }}
                />
            ) : (
                t(
                    'priorAuthorization.statusPage.appealed.partTwoNoDecisionLetter'
                )
            )}
        </Body1>
    );
};
