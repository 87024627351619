import { CAPSULE_AND_TABLET_FORM_CODES } from '@phx/rx-common';

import type {
    FormInfoFragment,
    PackageInfoFragment,
} from '../../graphql/generated/graphql';
import i18n from '../../i18n';

export const PACK_CODE = 'PA';
export const QUANTITY_OPTION_COUNT = 3;

function getPackageDaysSupply(
    formPackage?: Pick<PackageInfoFragment, 'modeDaysSupply'>
) {
    return formPackage?.modeDaysSupply && formPackage.modeDaysSupply > 0
        ? formPackage.modeDaysSupply
        : 30;
}

function getPackUnitTotalText(
    chosenQuantity: number,
    formCode: string,
    formPackage: PackageInfoFragment
) {
    const packText = i18n.t(PACK_CODE, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });
    const packageType = i18n.t(formPackage.packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: formPackage?.packageQuantity ?? 1,
    });

    const formText = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: formPackage?.packageQuantity ?? 1,
    });

    const total =
        (formPackage?.packageSize ?? 1) *
        (formPackage?.packageQuantity ?? 1) *
        chosenQuantity;

    return {
        longText: `${chosenQuantity} ${packText} ${i18n.t('productConfig.of')} ${
            formPackage?.packageQuantity ?? 1
        } ${packageType} (${total} ${formText})`,
        shortText: `${total} ${formText}`,
        formulaText: `${chosenQuantity} ${packText} * ${
            formPackage?.packageQuantity ?? 1
        } ${packageType} * ${
            formPackage?.packageSize ?? 1
        } ${formText} = ${total} ${formText}`,
        value: total,
        quantityDescriptor: packText,
    };
}

function getTotalPackageIrrelevant(
    chosenQuantity: number,
    formCode: string,
    formPackage: PackageInfoFragment
) {
    if (formPackage?.packageQuantity && formPackage.packageQuantity > 1) {
        //Pack Units
        return getPackUnitTotalText(chosenQuantity, formCode, formPackage);
    }

    //Individual unit
    const formDescription = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: chosenQuantity,
    });
    const totalText = `${chosenQuantity} ${formDescription}`;
    return {
        longText: totalText,
        shortText: totalText,
        formulaText: totalText,
        quantityDescriptor: formDescription,
        value: chosenQuantity,
    };
}

function getTotalPackageRelevant(
    chosenQuantity: number,
    form: FormInfoFragment,
    formPackage: PackageInfoFragment
) {
    if (formPackage.packageQuantity && formPackage.packageQuantity > 1) {
        //pack units
        return getPackUnitTotalText(chosenQuantity, form.formCode, formPackage);
    }

    const packageDescription = i18n.t(formPackage.packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });
    const total = (formPackage?.packageSize ?? 1) * chosenQuantity;
    const formDescription = i18n.t(form.formCode, {
        ns: 'code-locales/form-code-mapping',
        count: total,
    });
    return {
        longText: `${chosenQuantity} ${packageDescription} (${total} ${formDescription})`,
        shortText: `${total} ${formDescription}`,
        formulaText: `${chosenQuantity} ${packageDescription} * ${
            formPackage?.packageSize ?? 1
        } ${formDescription} = ${total} ${formDescription}`,
        quantityDescriptor: packageDescription,
        value: total,
    };
}

function getComplexTotalPackUnit(
    chosenQuantity: number,
    formPackage: PackageInfoFragment,
    formCode: string
) {
    const packText = i18n.t(PACK_CODE, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });

    const packageQuantity = formPackage?.packageQuantity ?? 1;

    const packageDesc = i18n.t(formPackage.packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: packageQuantity,
    });

    const total =
        (formPackage?.packageSize ?? 1) *
        (formPackage?.packageQuantity ?? 1) *
        chosenQuantity;

    const formDesc = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: 1,
    });

    const of = i18n.t('productConfig.of');
    return {
        longText: `${chosenQuantity} ${packText} ${of} ${packageQuantity} ${packageDesc} ${of} ${formDesc} (${total}${
            formPackage?.packageSizeUnit ?? ''
        })`,
        shortText: `${total}${formPackage?.packageSizeUnit ?? ''}`,
        formulaText: `${chosenQuantity} ${packText} * ${formPackage?.packageQuantity} ${packageDesc} * ${
            formPackage?.packageSize ?? 1
        }${formPackage?.packageSizeUnit ?? ''} = ${total}${
            formPackage?.packageSizeUnit ?? ''
        } `,
        value: total,
        quantityDescriptor: packText,
    };
}

export function getTotalQuantityDescription(
    chosenQuantity: number,
    form: FormInfoFragment,
    formPackage: PackageInfoFragment
): {
    longText: string;
    shortText: string;
    formulaText: string;
    value: number;
    quantityDescriptor: string;
} {
    const formCode = form.formCode.toUpperCase();
    const packageSizeUnit = formPackage?.packageSizeUnit
        ? formPackage.packageSizeUnit.toLowerCase()
        : '';
    //Simply Quantity
    if (packageSizeUnit === 'ea') {
        //Package not relevant
        if (
            CAPSULE_AND_TABLET_FORM_CODES.has(formCode) &&
            formPackage.packageQuantity === 1 &&
            formPackage.packageCode !== 'DP'
        ) {
            return getTotalPackageIrrelevant(
                chosenQuantity,
                formCode,
                formPackage
            );
        }

        return getTotalPackageRelevant(chosenQuantity, form, formPackage);
    }

    //Complex Quantity
    else if (packageSizeUnit === 'ml' || packageSizeUnit === 'gm') {
        if (formPackage?.packageQuantity && formPackage.packageQuantity > 1) {
            return getComplexTotalPackUnit(
                chosenQuantity,
                formPackage,
                formCode
            );
        }

        const total = (formPackage?.packageSize ?? 1) * chosenQuantity;
        const packageDescription = i18n.t(formPackage.packageCode, {
            ns: 'code-locales/package-code-mapping',
            count: chosenQuantity,
        });
        const formDescription = i18n.t(form.formCode, {
            ns: 'code-locales/form-code-mapping',
            count: 1,
        });

        return {
            longText: i18n.t(
                'productConfig.totalQuantityDescriptions.longText',
                {
                    chosenQuantity,
                    packageDescription,
                    formDescription,
                    total,
                    packageSizeUnit: formPackage?.packageSizeUnit ?? '',
                }
            ),
            shortText: `${total}${formPackage?.packageSizeUnit ?? ''}`,
            formulaText: `${chosenQuantity} ${packageDescription} * ${
                formPackage?.packageSize ?? 1
            }${formPackage?.packageSizeUnit ?? ''} = ${total}${
                formPackage?.packageSizeUnit ?? ''
            }`,
            value: total,
            quantityDescriptor: packageDescription,
        };
    }

    return {
        longText: chosenQuantity.toString(),
        shortText: chosenQuantity.toString(),
        formulaText: chosenQuantity.toString(),
        quantityDescriptor: i18n.t('productConfig.quantity'),
        value: chosenQuantity,
    };
}

export function getIndividualFormQuantities(
    formPackage: Pick<
        PackageInfoFragment,
        'modeQuantity' | 'modeDaysSupply' | 'formCode'
    >
) {
    const results = [];
    const modeDaysSuply = getPackageDaysSupply(formPackage);

    const modeQuantity =
        formPackage?.modeQuantity && formPackage.modeQuantity > 0
            ? formPackage.modeQuantity
            : 30;

    for (let i = 1; i <= QUANTITY_OPTION_COUNT; i++) {
        const quantity = modeQuantity * i;
        results.push({
            displayText: `${quantity} ${i18n.t(formPackage.formCode, {
                ns: 'code-locales/form-code-mapping',
                count: modeQuantity,
            })}`,
            value: quantity.toString(),
            daysSupply: (i * modeDaysSuply).toString(),
        });
    }
    return results;
}

export function getPackUnitQuantities(
    formPackage?: Pick<PackageInfoFragment, 'modeDaysSupply'>
) {
    const results = [];
    const modeDaysSuply = getPackageDaysSupply(formPackage);

    for (let i = 1; i <= QUANTITY_OPTION_COUNT; i++) {
        results.push({
            displayText: `${i} ${i18n.t(PACK_CODE, { ns: 'code-locales/package-code-mapping', count: i })}`,
            value: i.toString(),
            daysSupply: modeDaysSuply * i,
        });
    }
    return results;
}

export function getPackageCodeQuantities(
    formPackage: Pick<PackageInfoFragment, 'packageCode' | 'modeDaysSupply'>
) {
    const results = [];
    const modeDaysSuply = getPackageDaysSupply(formPackage);

    for (let i = 1; i <= QUANTITY_OPTION_COUNT; i++) {
        results.push({
            displayText: `${i} ${i18n.t(formPackage.packageCode, {
                ns: 'code-locales/package-code-mapping',
                count: i,
            })}`,
            value: i.toString(),
            daysSupply: modeDaysSuply * i,
        });
    }
    return results;
}

export function getPackUnitPackagingText(
    packageQuantity: number,
    packageCode: string,
    packageSize: number,
    formCode: string
) {
    const formDescription = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: packageSize,
    });
    const packageType = i18n.t(packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: packageQuantity,
    });

    return i18n.t('productConfig.packUnitPackagingText', {
        packageQuantity,
        packageSize,
        packageType,
        formDescription,
    });
}

/**
 * Get the quantity selection options to display for a given drug package
 */
export function getComplexQuantityOptions(
    packageInfo: Pick<
        PackageInfoFragment,
        | 'modeQuantity'
        | 'modeDaysSupply'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageCode'
    >
) {
    const {
        packageQuantity,
        packageCode,
        packageSize,
        modeDaysSupply,
        modeQuantity,
    } = packageInfo;

    const results = [];
    const quantityDescriptorCode =
        packageQuantity && packageQuantity > 1 ? PACK_CODE : packageCode;

    let baseQuantity = Math.ceil(
        (modeQuantity ?? 1) / (packageSize ?? 1) / (packageQuantity ?? 1)
    );

    if (baseQuantity < 1) {
        baseQuantity = 1;
    }

    for (let i = 1; i <= QUANTITY_OPTION_COUNT; i++) {
        const quantity = baseQuantity * i;
        results.push({
            displayText: `${quantity} ${i18n.t(quantityDescriptorCode, {
                ns: ['code-locales/package-code-mapping'],
                count: quantity,
            })}`,
            value: quantity.toString(),
            daysSupply: ((modeDaysSupply ?? 1) * i).toString(),
        });
    }

    return results;
}

export function getSimplePackageFormatDescription(
    packageInfo: Pick<
        PackageInfoFragment,
        'packageQuantity' | 'packageSize' | 'packageCode' | 'formCode'
    >,
    isPackageRelevant: boolean
) {
    const { packageQuantity, packageCode, packageSize, formCode } = packageInfo;

    if (isPackageRelevant) {
        //Package Relevant
        if (packageQuantity === 1) {
            //Individual Units
            const packageType = i18n.t(packageCode, {
                ns: 'code-locales/package-code-mapping',
                count: 2,
            });
            const formType = i18n.t(formCode ?? '', {
                ns: 'code-locales/form-code-mapping',
                count: packageSize ?? 1,
            });

            return i18n.t('productConfig.individualFormatDescription', {
                packageType,
                packageSize: packageSize ?? 1,
                formType,
            });
        } else if (packageQuantity && packageQuantity > 1) {
            return getPackUnitPackagingText(
                packageQuantity,
                packageCode,
                packageSize ?? 1,
                formCode
            );
        }
    }
    //Package not relevant
    if (packageQuantity === 1) {
        return `${i18n.t('productConfig.Individual')} ${i18n.t(formCode, {
            ns: 'code-locales/form-code-mapping',
            count: 2,
        })}`;
    } else if (packageQuantity && packageQuantity > 1) {
        return getPackUnitPackagingText(
            packageQuantity,
            packageCode,
            packageSize ?? 1,
            formCode
        );
    }

    return '';
}

export function getComplexPackageFormatDescription(
    packageInfo: Pick<
        PackageInfoFragment,
        'packageQuantity' | 'packageSize' | 'packageCode' | 'packageSizeUnit'
    >
) {
    const { packageQuantity, packageCode, packageSize, packageSizeUnit } =
        packageInfo;

    if (packageQuantity && packageQuantity > 1) {
        const formDescription = i18n.t(packageCode, {
            ns: 'code-locales/package-code-mapping',
            count: packageQuantity,
        });

        return i18n.t('productConfig.complexPackUnitFormat', {
            packageQuantity,
            formDescription,
            packageSize: packageSize,
            sizeUnit: packageSizeUnit ?? '',
        });
    }
    const packageTypePlural = i18n.t(packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: 2,
    });
    return i18n.t('productConfig.complexIndividualUnitFormat', {
        packageType: packageTypePlural,
        packageSize: packageSize ?? '',
        packageUnit: packageSizeUnit ?? '',
    });
}

export function computeDaysSupply(
    packageInfo: Pick<PackageInfoFragment, 'quantityOptions'>,
    chosenQuantity: number
) {
    const { quantityOptions } = packageInfo;

    const matchingQuantityOption = quantityOptions.find(
        (option) => Number(option.value) === chosenQuantity
    );

    if (matchingQuantityOption && !!matchingQuantityOption.daysSupply) {
        return Number(matchingQuantityOption.daysSupply);
    } else if (quantityOptions.length) {
        //Find which index the chosenValue would fall into if inserted in order
        const values = quantityOptions.map((option) => Number(option.value));
        let index = values.findIndex((current) => chosenQuantity <= current);
        if (index === -1) {
            index = values.length;
        }

        if (index >= values.length) {
            return 90; // caps out 90
        }

        return quantityOptions[index]?.daysSupply
            ? Number(quantityOptions[index].daysSupply)
            : index * 30;
    }

    return 30; //fallback days supply
}

//NOTE: This is a temporary short term solution for Bug 63155. The long term solution should probably be handled at ElasticDrugService and once that is taken care of this can be removed.
export const removeGenericSuffix = (drugName: string): string => {
    const genericIndex = drugName.toLowerCase().indexOf('(generic');
    if (genericIndex !== -1) {
        // Remove the generic part and any trailing spaces
        return drugName.slice(0, genericIndex).trim();
    }
    // If no generic part found, return the original input
    return drugName;
};
