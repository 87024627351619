import formatToLocalizedDate from './format-to-localized-date';

export const formatDateString = (
    dateString: string,
    locale: string = 'en-US',
    options: Intl.DateTimeFormatOptions = { dateStyle: 'short' }
) => {
    const date = new Date(dateString);

    return formatToLocalizedDate(
        date,
        options,
        locale
    );
};
