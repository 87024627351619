import { useFlags as useFlagsBase } from '@phx/feature-flags/client';

import type { FlagSet } from '../generated/feature-flags';

const useOverrides = import.meta.env.VITE_ENV !== 'production';

/**
 * Preferred hook for accessing feature flags in the application.
 * This hook automatically determines whether to use overrides based on the environment.
 */
export const useFlags = () => useFlagsBase<FlagSet>(useOverrides);
