export const getOptimisticPatient = <T>(edges: T) => {
    return {
        patient: {
            preferences: {
                patientFavoriteLocationsConnection: {
                    edges,
                },
            },
        },
    };
};
