import type { AddressProps, WeeklyHours } from '@phx/design-system';
import { type Geolocation, geolocationDistance } from '@phx/location-utils';
import type { PharmacyInfoProps } from '@phx/myphx-lib';
import type { TFunction } from 'i18next';

import { getFragment } from '../../graphql';
import type {
    ChainProviderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../graphql/generated/graphql';
import { getPrimaryProvider } from '../provider-offers';

import { mapWeeklyHours } from './map-weekly-hours';

export interface ExtendedPharmacyProviderInfoFragment
    extends PharmacyProviderInfoFragment {
    chainName?: string;
    chainPharmacies?: PharmacyProviderInfoFragment[];
}

export type ProcessedProvider = {
    id: string;
    name: string;
    favorite: boolean;
    phone?: string | null;
    url?: string | null;
    shippingTime?: string | null;
    address?: AddressProps['address'];
    distance?: PharmacyInfoProps['distance'];
    weeklyHours?: WeeklyHours;
    moreProviders?: PharmacyProviderInfoFragment[];
    chainName?: string;
};

/**
 * Processes and extracts provider details into a standardized format for PharmacyInfo component.
 * @param provider - The provider to process and pass to PharmacyInfo.
 * @param geolocation - The current user geolocation coordinates.
 * @param checkIsFavorite - Function from patientcontext preferences to check if a provider is a favorite for the user.
 * @returns The processed provider data to be passed to PharmacyInfo.
 */
export const processProviderToPharmacyInfo = (
    provider:
        | ExtendedPharmacyProviderInfoFragment
        | MailOrderProviderInfoFragment,
    geolocation: { latitude: number; longitude: number },
    checkIsFavorite: (locationId: string) => boolean,
    t: TFunction
): ProcessedProvider => {
    const props = {
        id: provider.id,
        name: provider.name,
        favorite: checkIsFavorite(provider.id),
        phone: provider.phoneNumber,
    };

    switch (provider.__typename) {
        case 'MailOrderProvider':
            return {
                ...props,
                url: provider?.url,
                shippingTime: provider.deliveryTimeDisplay,
            };
        case 'PharmacyProvider': {
            const address = getFragment(provider.address);
            const distanceAmount = geolocationDistance(geolocation, {
                latitude: provider.latitude,
                longitude: provider.longitude,
            });
            const distance: PharmacyInfoProps['distance'] = {
                distance: distanceAmount,
                units: t('common.distance.distanceUnit'),
            };
            const weeklyHours = provider.weeklyHoursByDayOfWeek
                ? mapWeeklyHours(getFragment(provider.weeklyHoursByDayOfWeek))
                : undefined;

            const moreProviders = provider.chainPharmacies?.length
                ? provider.chainPharmacies
                : undefined;

            const chainName = provider.chainName;

            return {
                ...props,
                weeklyHours,
                address,
                distance,
                moreProviders,
                chainName,
            };
        }
        default:
            return props;
    }
};

/**
 * Compiles all non-chain pharmacy providers and chain providers into a single list of providers.
 * @param pharmacyProviders - List of individual pharmacy providers.
 * @param chainProviders - List of chain providers.
 * @param geolocation - The current user geolocation coordinates (optional).
 * @returns A list of pharmacy providers.
 */
export const combineChainAndPharmacyProviders = (
    pharmacyProviders: ExtendedPharmacyProviderInfoFragment[],
    chainProviders: ChainProviderInfoFragment[],
    geolocation?: Geolocation
): ExtendedPharmacyProviderInfoFragment[] => {
    const allPharmacyProviders: ExtendedPharmacyProviderInfoFragment[] = [
        ...pharmacyProviders,
    ];

    chainProviders.forEach((chainProvider) => {
        const primaryPharmacy = getPrimaryProvider(chainProvider, geolocation);

        if (primaryPharmacy?.__typename === 'PharmacyProvider') {
            const chainPharmacies = chainProvider.pharmacies
                .map((pharmacy) => getFragment(pharmacy))
                .filter((pharmacy) => pharmacy.id !== primaryPharmacy.id);

            allPharmacyProviders.push({
                ...primaryPharmacy,
                chainName: chainProvider.chainName,
                chainPharmacies,
            });
        }
    });

    return allPharmacyProviders;
};

/**
 * Filters and sorts pharmacy providers based on optional distance and optional search query.
 * @param providers - List of pharmacy providers.
 * @param geolocation - The current user geolocation coordinates (optional).
 * @param distance - Maximum distance to filter providers (optional).
 * @param searchQuery - Search query to filter providers by name (optional).
 * @returns A list of filtered and sorted providers.
 */
export const filterAndSortPharmacyProviders = (
    providers: ExtendedPharmacyProviderInfoFragment[],
    geolocation?: { latitude: number; longitude: number },
    distance?: number,
    searchQuery?: string
): ExtendedPharmacyProviderInfoFragment[] => {
    return providers
        .map((provider) => {
            const distanceAmount = geolocation
                ? geolocationDistance(geolocation, {
                      latitude: provider.latitude,
                      longitude: provider.longitude,
                  })
                : Infinity;
            return { ...provider, distance: distanceAmount };
        })
        .filter((location) => {
            return (
                (!searchQuery ||
                    matchesSearchQuery(location.name, searchQuery)) &&
                (distance ? location.distance <= distance : undefined)
            );
        })
        .sort((a, b) => a.distance - b.distance);
};

/**
 * Checks if a location name matches a search query - case-insensitive.
 * @param title - The location name to check.
 * @param search - The search query to match against.
 * @returns True if the location name matches the search query, false otherwise.
 */
export const matchesSearchQuery = (title: string, search: string) => {
    return title.toLowerCase().includes(search.toLowerCase());
};
