import {
    Body1,
    Button,
    Flex,
    Heading2,
    IconButton,
    IconControlledSubstance,
    Stack,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import type { PrescriberInfoFragment } from '../../../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../../../util';
import { Prescriber } from '../../../prescriber/Prescriber';

type AltsPrescriberSectionProps = {
    prescriber: PrescriberInfoFragment;
    controlledSubstance?: boolean;
};

export const AltsPrescriberSection = ({
    prescriber,
    controlledSubstance = false,
}: AltsPrescriberSectionProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack gap="sm">
            {controlledSubstance ? (
                <Stack gap="xxs">
                    <Flex gap="xxs">
                        <IconButton
                            withStyles={false}
                            Icon={IconControlledSubstance}
                            onClick={() =>
                                navigate(
                                    getAbsoluteRoute('helpCenter.medications')
                                )
                            }
                        />
                        <Body1>
                            {t('controlledSubstance.modal.controlledSubstance')}
                        </Body1>
                    </Flex>
                </Stack>
            ) : null}
            <Heading2>{t('alternatives.newPrescription.title')}</Heading2>
            <Body1>{t('alternatives.newPrescription.description')}</Body1>
            <Prescriber prescriber={prescriber} />
            <Button
                onClick={() =>
                    (location.href = `tel:${prescriber.phoneNumber}`)
                }
            >
                {t('alternatives.newPrescription.callPrescriber')}
            </Button>
        </Stack>
    );
};
