import {
    Body1,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    Stack,
} from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type { ClaimInfoFragment } from '../../graphql/generated/graphql';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../util';
import { getClaimComponent } from '../../util/claims/get-claim-component';
import { RxForUser } from '../cabinet/rx-for-user/RxForUser';
import { ContactUsWidget } from '../common/ContactUsWidget';
import DrugDetails from '../common/DrugDetails';
import { LocationInfo } from '../common/LocationInfo';

type GreatPriceProps = {
    claim: ClaimInfoFragment;
};

export const GreatPrice = ({ claim }: GreatPriceProps) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!claim.plan?.__typename) {
        return (
            <Body1 data-testid="claim-great-price-no-price-component">
                {t('claim.greatPrice.NoPlan')}
            </Body1>
        );
    }

    const ClaimComponent = getClaimComponent(claim.plan.__typename);
    const fill = getFragment(claim.fill);
    const patient = getFragment(claim.patient);
    const product = getFragment(fill.product);
    const pharmacy = getFragment(fill.pharmacyProvider);

    return (
        <Stack>
            <RxForUser patient={patient} />
            <Heading1 data-testid="claim-great-price-product-name">
                {product.name}
            </Heading1>
            {drugDetailsSplitEnabled ? (
                <DrugDetails
                    product={product}
                    quantity={getFragment(claim.fill).quantity}
                />
            ) : null}
            <ClaimComponent claim={claim} />
            <HorizontalDivider />
            <Body1 data-testid="claim-great-price-pharmacy-details-with-date">
                {t('claim.greatPrice.dateSentToPharmacy', {
                    pharmacyName: pharmacy?.name,
                    localizedDate: fill.dateFilled
                        ? formatToLocalizedDate(new Date(fill.dateFilled), {
                              dateStyle: 'medium',
                          })
                        : undefined,
                })}
            </Body1>
            <HorizontalDivider />
            <Heading2 data-testid="claim-great-price-title">
                {t('claim.greatPrice.title')}
            </Heading2>
            <Body1 data-testid="claim-great-price-description">
                {t('claim.greatPrice.description')}
            </Body1>
            {pharmacy && <LocationInfo location={pharmacy} />}
            <Button
                size="lg"
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
            <HorizontalDivider />
            <ContactUsWidget />
        </Stack>
    );
};
