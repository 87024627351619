import { Body1, Box, Modal, Stack, Subtitle1 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type BenefitCardInfoModalProps = {
    isOpen: boolean;
    close: () => void;
};

export const BenefitCardInfoModal = ({
    isOpen,
    close,
}: BenefitCardInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={close}
            title={t('planCards.benefit.modal.title')}
        >
            <Stack gap="sm">
                <Box>
                    <Subtitle1>
                        {t('planCards.benefit.modal.whatIsIt.heading')}
                    </Subtitle1>
                    <Body1>{t('planCards.benefit.modal.whatIsIt.body')}</Body1>
                </Box>
                <Box>
                    <Subtitle1>
                        {t('planCards.benefit.modal.isItDifferent.heading')}
                    </Subtitle1>
                    <Body1>
                        {t('planCards.benefit.modal.isItDifferent.body')}
                    </Body1>
                </Box>
            </Stack>
        </Modal>
    );
};
