import type { FieldReadFunction, Reference } from '@apollo/client';

import {
    type Provider,
    type ProviderOffersEdge,
} from '../../generated/graphql';

export const readProviderOffersByType: FieldReadFunction = (
    _,
    { readField }
) => {
    try {
        const edges = readField<ProviderOffersEdge[]>('edges');
        const pickup: Readonly<Reference>[] = [];
        const delivery: Readonly<Reference>[] = [];

        edges?.forEach((edge) => {
            const node = readField<Reference>('node', edge);
            const providerRef = readField<Provider>('provider', node);
            const providerTypename = readField<string>(
                '__typename',
                providerRef
            );

            switch (providerTypename) {
                case 'MailOrderProvider':
                    node && delivery.push(node);
                    break;
                case 'ChainProvider':
                case 'PharmacyProvider':
                default:
                    node && pickup.push(node);
                    break;
            }
        });

        return {
            pickup,
            delivery,
        };
    } catch (error) {
        return {
            pickup: [],
            deliver: [],
        };
    }
};
