import { SpendSummaryCard } from '@phx/myphx-lib';

import { getFragment } from '../../../graphql';
import type { PlanSummaryProps } from '../PlanSummary';

type IndividualSummaryCardProps = {
    coverages: PlanSummaryProps['coverageEdges'];
};

export const IndividualSummaryCard = ({
    coverages,
}: IndividualSummaryCardProps) => {
    return coverages.map((coverage, id) =>
        coverage.individualAccum ? (
            <SpendSummaryCard
                key={`individual-accumulator-card-${id}`}
                data-testid={`individual-accumulator-card-${id}`}
                variant="individual"
                deductible={{
                    max: coverage.individualDeductibleMax ?? 0,
                    spent: getFragment(coverage.individualAccum)
                        .towardsDeductible,
                }}
                outOfPocket={{
                    max: coverage.individualOutOfPocketMax ?? 0,
                    spent: getFragment(coverage.individualAccum)
                        .towardsOutOfPocketMax,
                }}
            />
        ) : null
    );
};
