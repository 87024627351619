import { useAuthContext } from '@phx/auth/client';
import { IconHome, SideNavLink } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ConditionalComponent } from '../../utility/ConditionalComponent';
import { MenuSection } from '../MenuSection';

export const GettingStartedSection = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { isAuthenticated, signUp } = useAuthContext();

    const register = useCallback(
        () => signUp(location.pathname),
        [signUp, location.pathname]
    );

    return (
        <ConditionalComponent condition={!isAuthenticated}>
            <MenuSection label={t('sidebar.gettingStarted.heading')}>
                <SideNavLink
                    label={t('sidebar.gettingStarted.createAccount')}
                    Icon={IconHome}
                    onClick={register}
                />
            </MenuSection>
        </ConditionalComponent>
    );
};
