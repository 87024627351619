import { getLocalItem } from '@phx/browser-utils';
import { ldFlagStorageKey } from './storage-key';
export const getOverriddenFlagsFromStorage = () => {
    try {
        const flags = getLocalItem(ldFlagStorageKey);
        if (typeof flags === 'object' &&
            flags !== null &&
            !Array.isArray(flags)) {
            return flags;
        }
    }
    catch {
        // Throw away error
    }
    return {};
};
