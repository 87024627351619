import type { FieldReadFunction } from '@apollo/client';
import { default as i18next } from 'i18next';

import { formatToLocalizedDate } from '../../../util';

export const readLatestSelectedPriceQuoteDate: FieldReadFunction = (
    _,
    { readField }
) => {
    const latestSelectedPriceQuoteTimestamp = readField<number>(
        'latestSelectedPriceQuoteTimestamp'
    );
    const currLocale = i18next.language;
    if (latestSelectedPriceQuoteTimestamp) {
        return formatToLocalizedDate(
            new Date(latestSelectedPriceQuoteTimestamp),
            {
                dateStyle: 'medium',
                localeMatcher: 'best fit',
            },
            currLocale
        );
    }
    return '';
};

export const readLatestSelectedOfferDate: FieldReadFunction = (
    _,
    { readField }
) => {
    const latestSelectedOfferTimestamp = readField<number>(
        'latestSelectedOfferTimestamp'
    );
    const currLocale = i18next.language;
    if (latestSelectedOfferTimestamp) {
        return formatToLocalizedDate(
            new Date(latestSelectedOfferTimestamp),
            {
                dateStyle: 'medium',
                localeMatcher: 'best fit',
            },
            currLocale
        );
    }
    return '';
};
