import { Body1, ElevatedCard, Stack } from '@phx/design-system';
import { useGeolocation } from '@phx/location-utils';
import { PharmacyInfo } from '@phx/myphx-lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    ChainProviderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import {
    type ExtendedPharmacyProviderInfoFragment,
    combineChainAndPharmacyProviders,
    filterAndSortPharmacyProviders,
    processProviderToPharmacyInfo,
} from '../../../util/pharmacy/process-providers';
import { MoreProviders } from '../../provider-offers';
import { NoProviderOffers } from '../../provider-offers/cards/NoProviderOffers';

type LocationCardListProps = {
    providers: Array<
        | MailOrderProviderInfoFragment
        | PharmacyProviderInfoFragment
        | ChainProviderInfoFragment
        | ExtendedPharmacyProviderInfoFragment
    >;
    onSelect: (id: string) => void;
};

export const LocationCardList = ({
    providers,
    onSelect,
}: LocationCardListProps) => {
    const { t } = useTranslation();
    const { geolocation } = useGeolocation();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;

    const distance = useNumericSearchParam('within');

    const { pharmacyProviders, chainProviders } = useMemo(() => {
        const chainProviders: ChainProviderInfoFragment[] = [];
        const mailOrderProviders: MailOrderProviderInfoFragment[] = [];
        const pharmacyProviders: PharmacyProviderInfoFragment[] = [];

        providers.forEach((provider) => {
            if (provider.__typename === 'ChainProvider') {
                chainProviders.push(provider);
            } else if (provider.__typename === 'MailOrderProvider') {
                mailOrderProviders.push(provider);
            } else if (provider.__typename === 'PharmacyProvider') {
                pharmacyProviders.push(provider);
            }
        });
        return {
            chainProviders,
            mailOrderProviders,
            pharmacyProviders,
        };
    }, [providers]);

    const sortedPharmacyProviders = useMemo(() => {
        const allPharmacyProviders = combineChainAndPharmacyProviders(
            pharmacyProviders,
            chainProviders,
            geolocation
        );

        return filterAndSortPharmacyProviders(
            allPharmacyProviders,
            geolocation,
            distance
        );
    }, [pharmacyProviders, chainProviders, geolocation, distance]);

    return (
        <Stack gap="lg">
            <Body1>
                {t('providerOffers.pharmacies', { count: providers.length })}
            </Body1>
            <Stack gap="sm" role="list">
                {sortedPharmacyProviders && geolocation ? (
                    sortedPharmacyProviders.map((provider) => {
                        {
                            const processedProvider =
                                processProviderToPharmacyInfo(
                                    provider,
                                    geolocation,
                                    checkIsFavorite,
                                    t
                                );

                            const {
                                distance,
                                address,
                                favorite,
                                name,
                                chainName,
                                moreProviders,
                                id,
                            } = processedProvider;

                            return (
                                <ElevatedCard
                                    role="listitem"
                                    key={`pharmacy-info-container-${id}`}
                                    footer={
                                        moreProviders ? (
                                            <MoreProviders
                                                primaryName={chainName || name}
                                                moreProviders={moreProviders}
                                                onSelected={onSelect}
                                            />
                                        ) : null
                                    }
                                >
                                    <PharmacyInfo
                                        address={address}
                                        distance={distance}
                                        favorite={favorite}
                                        toggleFavorite={() =>
                                            toggleFavorite(id)
                                        }
                                        name={name}
                                        day={t('pharmacy.hours.today')}
                                    />
                                </ElevatedCard>
                            );
                        }
                    })
                ) : (
                    <NoProviderOffers />
                )}
            </Stack>
        </Stack>
    );
};
