import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    NonPriceableOfferInfoFragment,
} from '../../graphql/generated/graphql';

export const isPriceableOffer = (
    offer?:
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment
        | NonPriceableOfferInfoFragment
        | null
): offer is CashOfferInfoFragment | InsuranceOfferInfoFragment => {
    if (!offer) {
        return false;
    }

    return (
        offer.__typename === 'CashOffer' ||
        offer?.__typename === 'InsuranceOffer'
    );
};
