import { Body1, Modal } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const PlanMembersModal = ({
    close,
    isOpen,
}: {
    close: () => void;
    isOpen: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            title={t('planMembers.modal.heading')}
            onClose={close}
        >
            <Body1>{t('planMembers.modal.body')}</Body1>
        </Modal>
    );
};
