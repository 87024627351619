import type { PlanInfoFragment } from '../../../generated/graphql';
import { isPhxBenefitsPlan } from '../is-phx-benefits-plan';
import { isSmartpricePlan } from '../is-smartprice-plan';

export interface DisplayablePlan {
    __typename: 'PhxBenefitsPlan' | 'SmartpricePlan';
    id: string;
    groupPlanCode: string;
    bin: string;
    pcn: string;
}

export function isDisplayablePlan(
    plan: PlanInfoFragment
): plan is DisplayablePlan {
    return (
        (isPhxBenefitsPlan(plan) || isSmartpricePlan(plan)) &&
        !!plan.bin &&
        !!plan.pcn
    );
}
