import type { ComponentType } from 'react';

import PhxBenefitsClaim from '../../components/claim/PhxBenefitsClaim';
import SmartpriceClaim from '../../components/claim/SmartPriceClaim';
import UnknownCoverage from '../../components/claim/UnknownCoverage';
import type {
    ClaimInfoFragment,
    PlanInfoFragment,
} from '../../graphql/generated/graphql';

export const getClaimComponent = (
    claimType: PlanInfoFragment['__typename']
): ComponentType<{ claim: ClaimInfoFragment }> => {
    switch (claimType) {
        case 'SmartpricePlan':
            return SmartpriceClaim;
        case 'PhxBenefitsPlan':
            return PhxBenefitsClaim;
        default:
            return UnknownCoverage;
    }
};
