import {
    Body1,
    FilledCard,
    Flex,
    Heading2,
    Stack,
    TextButton,
} from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { isDisplayablePlan } from '../../../graphql/policies/util';
import { getAbsoluteRoute } from '../../../util';
import { mapPlanData } from '../../../util/offers/get-offer-plan-data';
import { ConditionalComponent } from '../../utility/ConditionalComponent';
import type { OrderConfirmationProps } from '../OrderConfirmation';

export const ShareWithPharmacy = ({
    offer,
    prescriberOrder,
}: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const pharmacyProvider = getFragment(offer.pharmacyProvider);
    const patient = getFragment(prescriberOrder.patient);
    const offerPlanData = isDisplayablePlan(offer.plan)
        ? [
              mapPlanData(
                  offer.plan,
                  patient.fullName,
                  patient.coveragesConnection.edges
              ),
          ]
        : [];

    let description;
    switch (pharmacyProvider.__typename) {
        case 'MailOrderProvider':
            if (pharmacyProvider.url) {
                description = t(
                    'orderConfirmation.coverageCard.mailOrderUrlDescription'
                );
            } else if (pharmacyProvider.phoneNumber) {
                description = t(
                    'orderConfirmation.coverageCard.mailOrderPhoneDescription'
                );
            }
            break;
        default:
            description = t('orderConfirmation.coverageCard.pickUpDescription');
            break;
    }

    return (
        <ConditionalComponent condition={!!offer}>
            <FilledCard>
                <Stack gap="sm">
                    <Flex justify="space-between" align="center">
                        <Heading2>
                            {t('orderConfirmation.coverageCard.subHeader')}
                        </Heading2>
                        <TextButton
                            onClick={() =>
                                navigate(getAbsoluteRoute('profile.benefit'))
                            }
                        >
                            {t('orderConfirmation.coverageCard.viewDetails')}
                        </TextButton>
                    </Flex>
                    <Body1>{description}</Body1>
                    {offerPlanData && <CoverageCard {...offerPlanData[0]} />}
                </Stack>
            </FilledCard>
        </ConditionalComponent>
    );
};
