import { useMemo } from 'react';

import { isDisplayablePlan } from '../graphql/policies/util';
import { mapPlanData } from '../util/offers/get-offer-plan-data';

import { useProfile } from './use-profile';

export const useCoverageCardData = (
    typeName?: 'PhxBenefitsPlan' | 'SmartpricePlan' | 'UnknownPlan'
) => {
    const { patient } = useProfile();

    return useMemo(() => {
        let displayableCoverages = patient.displayableCoverages;

        if (typeName) {
            displayableCoverages = displayableCoverages.filter(
                (plan) =>
                    (typeName === 'PhxBenefitsPlan' ||
                        typeName === 'SmartpricePlan') &&
                    plan.__typename === typeName
            );
        }

        return displayableCoverages
            .filter(isDisplayablePlan)
            .map((plan) =>
                mapPlanData(
                    plan,
                    patient.fullName,
                    patient.coveragesConnection.edges
                )
            )
            .toSorted((a) => {
                if (a.variant === 'benefit') {
                    return -1;
                }
                return 0;
            });
    }, [patient, typeName]);
};
