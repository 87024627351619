import type { FieldReadFunction } from '@apollo/client';

import { readFullName } from '../patient';

export const readPrescriberTitle: FieldReadFunction = (_, { readField }) => {
    const fullName = readField<string>('fullName');
    const prefix = readField<string>('prefix');
    const suffix = readField<string>('suffix');

    return [
        prefix ? prefix + ' ' : '',
        fullName,
        suffix ? ', ' + suffix : '',
    ].join('');
};

export const prescriberFields = {
    fullName: {
        read: readFullName,
    },
    fullNameWithTitle: {
        read: readPrescriberTitle,
    },
};
