/**
 * Formats a given input string by capitalizing the first letter of each word
 * and making the rest of the letters lowercase.
 *
 * @param input The string to format.
 * @returns The formatted string.
 */
export const titleCase = (input: string | undefined): string => {
    if (!input) {
        return '';
    }

    const words = input.split(' ');

    const formattedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    const formattedResult = formattedWords.join(' ');

    return formattedResult;
};
