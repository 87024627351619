import {
    Badge,
    Flex,
    HorizontalDivider,
    IconButton,
    IconChevronRight,
    IconInfo,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';
import { MedicineCabinetInfoModal } from '../MedicineCabinetInfoModal';

export type OrdersCardHeaderProps = {
    count: number;
};

export const OrdersCardHeader = ({ count }: OrdersCardHeaderProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);

    const navigateToCabinetRoot = useCallback(() => {
        navigate(getAbsoluteRoute('cabinet.root'));
    }, [navigate]);

    return (
        <Stack gap="xs">
            <Flex justify="space-between">
                <Flex gap="xxs">
                    <Subtitle1 color="currentColor">
                        {t('cabinet.ordersCard.header')}
                    </Subtitle1>
                    <IconButton
                        Icon={IconInfo}
                        color="primary"
                        onClick={open}
                        withStyles={false}
                    />
                    <MedicineCabinetInfoModal close={close} isOpen={opened} />
                </Flex>
                {count > 3 ? (
                    <TextButton
                        onClick={navigateToCabinetRoot}
                        data-testid="medicine-cabinet-card-see-all-button"
                    >
                        <Flex gap="xxs" align="center">
                            {t('cabinet.ordersCard.buttons.seeAll')}
                            <Badge>{count < 10 ? `${count}` : '9+'}</Badge>
                        </Flex>
                    </TextButton>
                ) : (
                    <IconButton
                        withStyles={false}
                        Icon={IconChevronRight}
                        onClick={navigateToCabinetRoot}
                        alt={t('cabinet.ordersCard.buttons.seeAll')}
                    />
                )}
            </Flex>
            <HorizontalDivider />
        </Stack>
    );
};
