import {
    ElevatedCard,
    Flex,
    Highlight,
    IconSearch,
    SearchInput,
    Stack,
} from '@phx/design-system';
import Fuse from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { usePatientContext } from '../../../../hooks/use-patient-context';
import EnglishTranslations from '../../../../public/help-center-locales/en/help-center.json';
import SpanishTranslations from '../../../../public/help-center-locales/es/help-center.json';
import helpCenterRoutes from '../../index';

import { useUserPreferences } from '../../../../hooks/use-user-preferences';
import classes from './search-input.module.css';

type SearchResult = {
    key: string;
    content: string;
};

export const HelpCenterSearchInput = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);
    const { language } = useUserPreferences();
    const { patient } = usePatientContext();

    const [value, setValue] = useState<string>('');
    const [results, setResults] = useState<SearchResult[]>([]);
    const navigate = useNavigate();

    const faqSearchData = useMemo(() => {
        const data: SearchResult[] = [];
        const faqSection =
            language === 'en'
                ? EnglishTranslations.faq
                : SpanishTranslations.faq;

        if (faqSection) {
            Object.entries(faqSection).forEach(([key, faqSubSection]) => {
                if (Array.isArray(faqSubSection)) {
                    //Exclude employer FAQs if user is not PBM
                    if (!patient?.hasPbmPlan && key === 'employer') {
                        return;
                    }

                    faqSubSection.forEach((item, index) => {
                        if (item?.question) {
                            data.push({
                                key: `faq.${key}[${index}].question`,
                                content: item.question,
                            });
                        }
                    });
                }
            });
        }

        return data;
    }, [SpanishTranslations, EnglishTranslations, patient?.hasPbmPlan]);

    const fuse = useMemo(
        () =>
            new Fuse(faqSearchData, {
                keys: ['content'],
            }),
        [faqSearchData]
    );

    const handleInputChange = useCallback(
        (input: string) => {
            setValue(input);

            if (fuse && input) {
                const searchResults = fuse
                    .search(input)
                    .map((result) => result.item);
                setResults(searchResults);
            } else {
                setResults([]);
            }
        },
        [fuse]
    );

    const handleResultClick = useCallback(
        (key: string, defaultValue: string) => {
            const matchedRoute = helpCenterRoutes[0].children?.find(
                (route) => route.id && key.includes(route.id)
            );

            if (matchedRoute?.path) {
                const queryParam = key.match(/\[(\d+)\]/)?.[1];

                navigate(
                    {
                        pathname: matchedRoute.path,
                        search:
                            queryParam &&
                            createSearchParams({ q: queryParam }).toString(),
                    },
                    {
                        state: {
                            defaultValue,
                        },
                    }
                );
            }
        },
        [navigate, t]
    );

    return (
        <Stack gap={0} pos="relative">
            <SearchInput
                value={value}
                onChange={(e) => handleInputChange(e.target.value)}
                clear={() => handleInputChange('')}
                label={t('helpCenter.searchLabel')}
                autoFocus={false}
            />
            <Stack className={classes.elevatedCard}>
                {results.length > 0 && (
                    <ElevatedCard>
                        <Stack gap="xs" className={classes.searchResults}>
                            {results.map((result, index) => (
                                <Flex
                                    style={{ cursor: 'pointer' }}
                                    gap="xxs"
                                    key={index}
                                    onClick={() =>
                                        handleResultClick(
                                            result.key,
                                            result.content
                                        )
                                    }
                                >
                                    <IconSearch color="gray" />
                                    <Highlight highlight={value}>
                                        {result.content}
                                    </Highlight>
                                </Flex>
                            ))}
                        </Stack>
                    </ElevatedCard>
                )}
            </Stack>
        </Stack>
    );
};
