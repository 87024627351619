import type { FieldMergeFunction, Reference } from '@apollo/client';

// Compare across units
export const mergeProductEdges: FieldMergeFunction = (
    _,
    incoming: Reference[],
    { readField }
) => {
    const compareProductStrength = (aRef?: Reference, bRef?: Reference) => {
        const a = readField<Reference>('node', aRef);
        const b = readField<Reference>('node', bRef);
        if (
            readField<string>('name', a)?.trim() !==
            readField<string>('name', b)?.trim()
        ) {
            return 0;
        }
        if (!a && !b) {
            return 0;
        }
        if (!b) {
            return -1;
        }
        if (!a) {
            return 1;
        }

        const strengthA = Number(readField<string>('strengthText', a));
        const strengthB = Number(readField<string>('strengthText', b));

        if (isNaN(strengthB) && isNaN(strengthA)) {
            return 0;
        }
        if (isNaN(strengthB)) {
            return -1;
        }
        if (isNaN(strengthA)) {
            return 1;
        }

        return strengthA === strengthB ? 0 : strengthA - strengthB;
    };

    return [...incoming].sort(compareProductStrength);
};
