import { Anchor, Body1 } from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { getAbsoluteRoute } from '../../../util';
import { isUnclaimedPrescriberOrder } from '../../../util/cabinet/prescriber-orders';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import type { MedicationOrderProps } from './MedicationOrder';

export const MedicationOrderDetails = ({ order }: MedicationOrderProps) => {
    const { priorAuthorizationEnabled } = useFlags();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isUnclaimed = isUnclaimedPrescriberOrder(order);

    const digitallyPrescribedProductId = order.drxId ?? order.id;

    const navigateToPriorAuthStatus = useCallback(() => {
        navigate(
            getAbsoluteRoute('orders.prescriberOrderId.priorAuthorization', {
                prescriberOrderId: digitallyPrescribedProductId,
            })
        );
    }, [navigate]);

    const applicablePriorAuthorization =
        order.__typename === 'DigitallyReceivedPrescriberOrder'
            ? order.applicablePriorAuthorization
            : null;

    return (
        <>
            {isUnclaimed ? (
                <Body1 data-testid="medication-entry-verify-medication-text">
                    {t('cabinet.medicationOrder.verifyPatient')}
                </Body1>
            ) : (
                <>
                    <Body1
                        data-testid={`medication-entry-patient-name-${order.patient.id}`}
                    >
                        {t('cabinet.medicationOrder.forName', {
                            name: getFragment(order.patient).fullName,
                        })}
                    </Body1>
                    <ConditionalComponent
                        condition={
                            priorAuthorizationEnabled &&
                            applicablePriorAuthorization
                        }
                    >
                        <Body1>
                            <Trans
                                i18nKey={t(
                                    'cabinet.medicationOrder.trackStatus'
                                )}
                                components={{
                                    1: (
                                        <Anchor
                                            onClick={navigateToPriorAuthStatus}
                                        />
                                    ),
                                }}
                            />
                        </Body1>
                    </ConditionalComponent>
                </>
            )}
        </>
    );
};
