import {
    Body1,
    Body2,
    Drawer,
    type DrawerProps,
    Flex,
    Heading1,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useFlagOverrides, useFlags } from '@phx/feature-flags/client';
import { useCallback, useEffect, useState } from 'react';

import type { FlagSet } from '../../../../generated/feature-flags';

import { FlagValueControl } from './FlagValueControl';

export const FlagPanel = ({
    opened,
    onClose,
}: Pick<DrawerProps, 'opened' | 'onClose'>) => {
    const flags = useFlags(false);
    const {
        setFlagOverride,
        clearFlagOverride,
        clearFlagOverrides,
        overriddenFlags,
    } = useFlagOverrides();

    const [localFlags, setLocalFlags] = useState<Record<string, unknown>>({});
    const [tempValues, setTempValues] = useState<Record<string, unknown>>({});

    useEffect(() => {
        const mergedFlags = { ...flags, ...overriddenFlags };
        setLocalFlags(mergedFlags);
        setTempValues(mergedFlags);
    }, [flags, overriddenFlags]);

    const handleSetValue = useCallback(
        (flag: string, newValue: unknown) => {
            const originalValue = flags[flag as keyof FlagSet];
            if (JSON.stringify(originalValue) === JSON.stringify(newValue)) {
                clearFlagOverride(flag);
            } else {
                setFlagOverride({ key: flag, value: newValue });
            }
        },
        [flags, clearFlagOverride, setFlagOverride]
    );

    const handleClearOverride = useCallback(
        (flag: string) => {
            clearFlagOverride(flag);
        },
        [clearFlagOverride]
    );

    return (
        <Drawer
            header={
                <Stack gap="xxs">
                    <Heading1>Feature Flags</Heading1>
                    <Body2>
                        An asterisk* indicates the value has been overridden.
                    </Body2>
                </Stack>
            }
            opened={opened}
            onClose={onClose}
            position="left"
        >
            <Stack gap="xs" p="xs">
                <TextButton
                    disabled={Object.keys(overriddenFlags).length === 0}
                    onClick={clearFlagOverrides}
                >
                    Reset overrides
                </TextButton>
                {Object.entries(localFlags).map(([flag, value], idx) => (
                    <Flex key={idx} style={{ justifyContent: 'space-between' }}>
                        <Body1>
                            {flag}
                            {overriddenFlags[flag] !== undefined ? '*' : ''}
                        </Body1>
                        <FlagValueControl
                            flag={flag}
                            value={value}
                            localFlags={localFlags}
                            tempValues={tempValues}
                            setTempValues={setTempValues}
                            handleSetValue={handleSetValue}
                            handleClearOverride={handleClearOverride}
                        />
                    </Flex>
                ))}
            </Stack>
        </Drawer>
    );
};
