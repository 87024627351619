import { FilledCard, Flex, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { formatToLocalizedCurrency } from '../../../../util';
import { ConditionalComponent } from '../../../utility/ConditionalComponent';

type PaidAmountProps = {
    amount?: number | null;
};

export const PaidAmount = ({ amount }: PaidAmountProps) => {
    const { t } = useTranslation();

    return (
        <ConditionalComponent condition={typeof amount === 'number'}>
            <FilledCard
                color="gray"
                header={
                    <Flex justify="space-between">
                        <Subtitle2>
                            {t('claimHistory.claimDetails.youPaid')}
                        </Subtitle2>
                        <Subtitle2 data-testid="claim-history-details-you-paid-amount">
                            {formatToLocalizedCurrency(amount!)}
                        </Subtitle2>
                    </Flex>
                }
            />
        </ConditionalComponent>
    );
};
