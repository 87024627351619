import { Body1, Box, FilledCard, Flex } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

import type { ClaimInfoFragment } from '../../graphql/generated/graphql';
import { isValidPatientCost } from '../../graphql/policies/util';
import { formatToLocalizedCurrency } from '../../util';

interface SmartpriceClaimProps {
    claim: ClaimInfoFragment;
}

const SmartpriceClaim = ({ claim }: SmartpriceClaimProps) => {
    const { t } = useTranslation();
    const subtitle = isValidPatientCost({
        patientResponsibilityCost: claim.patientPayAmount,
    })
        ? t('claim.planMap.smartpricePlan.subtitleWithoutInsurance')
        : t('claim.planMap.smartpricePlan.subtitleConfirmPrice');

    return (
        <FilledCard
            color="gray"
            header={
                <Flex justify="space-between">
                    <Box>
                        {t('claim.planMap.smartpricePlan.smartPRICE', {
                            tm: constants.unicode.TRADEMARK,
                        })}
                    </Box>
                    <Box>
                        {isValidPatientCost({
                            patientResponsibilityCost: claim.patientPayAmount,
                        })
                            ? formatToLocalizedCurrency(
                                  {
                                      patientResponsibilityCost:
                                          claim.patientPayAmount,
                                  }.patientResponsibilityCost ?? 0
                              )
                            : undefined}
                    </Box>
                </Flex>
            }
        >
            <Body1>{subtitle}</Body1>
        </FilledCard>
    );
};

export default SmartpriceClaim;
