import { Body1, Button, Flex, Heading1, Stack } from '@phx/design-system';
import { ClaimDeniedIllustration } from '@phx/myphx-lib';
import { TalkativeWidget } from '@phx/talkative';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const ClaimNotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Stack justify="center">
            <Stack align="center">
                <ClaimDeniedIllustration size="xl" />
                <Heading1>{t('claim.notFound.Title')}</Heading1>
            </Stack>
            <Body1 ta="center">{t('claim.notFound.subtitle')}</Body1>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('claim.notFound.home')}
            </Button>
            <Flex justify="center">
                <TalkativeWidget />
            </Flex>
        </Stack>
    );
};
