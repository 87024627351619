interface Sortable {
    distance: number | null;
    location: {
        id: string;
    };
    prices: {
        plan?: { __typename: string } | undefined;
        patientResponsibilityCost?: number | undefined;
        planPaysAmount?: number | undefined;
    }[];
}

const sortBestPrice = (
    a: Sortable | undefined,
    b: Sortable | undefined,
    bestPriceId: string
) =>
    a?.location.id === bestPriceId
        ? -1
        : b?.location.id === bestPriceId
          ? 1
          : 0;

export const sortByOption: Record<
    string,
    (
        a: Sortable,
        b: Sortable,
        bestPriceId?: string,
        noFallback?: boolean
    ) => number
> = {
    // Ascending sort / Least to greatest
    distance: (
        a: Sortable,
        b: Sortable,
        bestPriceId?: string,
        noFallback = false
    ) =>
        (bestPriceId && sortBestPrice(a, b, bestPriceId)) ||
        (a.distance !== null &&
            b.distance !== null &&
            a.distance - b.distance) ||
        (noFallback
            ? 0
            : byCostAmount('patientResponsibilityCost', 1)(a, b, bestPriceId)),
    // Ascending sort / Least to greatest, null costs should go last
    'you-pay': (a: Sortable, b: Sortable, bestPriceId?: string) =>
        byCostAmount('patientResponsibilityCost', 1)(a, b, bestPriceId) ||
        sortByOption['distance'](a, b, bestPriceId, true),
    // Decending sort / Greatest to least, null plan pays should go last
    'plan-pays': (a: Sortable, b: Sortable, bestPriceId?: string) =>
        byCostAmount('planPaysAmount', -1)(a, b, bestPriceId) ||
        sortByOption['distance'](a, b, bestPriceId, true),
};

//+/-Infinity handles null costs.
//If sortBy is 'you-pay', then +Infinity, being greater than any possible cost amount, ensures that null costs are sorted to the end of the array when sorting in ascending order of patientResponsibilityCost.
//If sortBy is 'plan-pays', then -Infinity, being less than any possible cost amount, ensures that null plan pays are sorted to the end of the array when sorting in descending order of planPaysAmount.
function byCostAmount(
    priceQuotesNodeKey: 'patientResponsibilityCost' | 'planPaysAmount',
    sortBy: 1 | -1 = 1
): (a: Sortable, b: Sortable, bestPriceId?: string) => number {
    return (a, b, bestPriceId) => {
        const bestPriceSort = bestPriceId && sortBestPrice(a, b, bestPriceId);
        if (bestPriceSort) {
            return bestPriceSort;
        }
        const fallback =
            sortBy === 1 ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;

        const aInsurance = a.prices.find(
            (x) => x.plan?.__typename === 'PhxBenefitsPlan'
        );
        const aSmartPrice = a.prices.find(
            (x) => !x.plan || x.plan.__typename === 'SmartpricePlan'
        );
        const bInsurance = b.prices.find(
            (x) => x.plan?.__typename === 'PhxBenefitsPlan'
        );
        const bSmartPrice = b.prices.find(
            (x) => !x.plan || x.plan.__typename === 'SmartpricePlan'
        );
        const aCost =
            priceQuotesNodeKey === 'planPaysAmount'
                ? aInsurance?.planPaysAmount ?? fallback
                : Math.min(
                      aInsurance?.patientResponsibilityCost ?? fallback,
                      aSmartPrice?.patientResponsibilityCost ?? fallback
                  );
        const bCost =
            priceQuotesNodeKey === 'planPaysAmount'
                ? bInsurance?.planPaysAmount ?? fallback
                : Math.min(
                      bInsurance?.patientResponsibilityCost ?? fallback,
                      bSmartPrice?.patientResponsibilityCost ?? fallback
                  );
        return sortBy * (aCost - bCost);
    };
}
