import {
    BareCard,
    Body1,
    IconChevronRight,
    IconSupportAgent,
    Stack,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { useTranslation } from 'react-i18next';

export const ContactUsWidget = () => {
    const { t } = useTranslation();

    return (
        <BareCard Icon={IconSupportAgent} iconColor="secondary">
            <Stack gap="xxxs" mb="xxs">
                <Subtitle2>{t('contactUsWidget.heading')}</Subtitle2>
                <Body1>{t('contactUsWidget.body')}</Body1>
            </Stack>
            <TextButton onClick={openTalkativeUI} RightIcon={IconChevronRight}>
                {t('contactUsWidget.button')}
            </TextButton>
        </BareCard>
    );
};
