import type { FieldReadFunction, Reference } from '@apollo/client';

export const readFormStrengthDisplayText: FieldReadFunction = (
    _,
    { readField }
) => {
    const strength = readField<Reference>('strength');

    const strengthText = readField<string>('strengthText', strength);
    const strengthUnit = readField<string>('strengthUnit', strength);

    if (!!strengthText && !!strengthUnit) {
        return `${strengthText}${strengthUnit.toLowerCase()}`;
    }
    return null;
};
