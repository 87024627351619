import { AuthorizedRoute } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import FavoritePharmaciesRoute from './FavoritePharmaciesRoute';
import { InNetworkPharmaciesRoute } from './InNetworkPharmaciesRoute';

const pharmacyRoutes: RouteObject[] = [
    {
        path: routes.pharmacy.root,
        element: <Outlet />,
        children: [
            {
                path: routes.pharmacy.favorites,
                element: (
                    <AuthorizedRoute>
                        <FavoritePharmaciesRoute />
                    </AuthorizedRoute>
                ),
            },
            {
                path: routes.pharmacy.inNetwork,
                element: <InNetworkPharmaciesRoute />,
            },
        ],
    },
];

export default pharmacyRoutes;
