import type { Address } from '../graphql/generated/graphql';

export const addressToAddressString = (address?: Address) => {
    if (!address) {
        return 'Unknown';
    }

    return (
        `${address.line1} ${address.line2 ?? ''}`.trim() +
        `, ${address.city}, ${address.state} ${address.zipCode}`
    );
};
