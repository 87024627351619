import { mergeProductEdges } from './merge-product-edges';
import { readDescription } from './product-description/read-product-description';
import { readProductStrengthDisplayText } from './read-product-strength-display-text';

export const productsConnectionFields = {
    edges: {
        merge: mergeProductEdges,
    },
};

export const productFields = {
    description: {
        read: readDescription,
    },
    strengthDisplayText: {
        read: readProductStrengthDisplayText,
    },
};
