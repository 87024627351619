import {
    Box,
    Button,
    Heading1,
    Radio,
    RadioGroup,
    Stack,
} from '@phx/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PatientSelectorOption } from '../../../hooks/use-patient-selector-options';
import { Loader } from '../../../loaders';

type PatientSelectorProps = {
    initialSelectedId: string;
    onChange: (option: PatientSelectorOption) => void;
    onClose: () => void;
    options: PatientSelectorOption[];
    loading: boolean;
};

export const PatientSelector = (props: PatientSelectorProps) => {
    const { initialSelectedId, onChange, onClose, options, loading } = props;
    const { t } = useTranslation();
    const [selectedId, setSelectedId] = useState<string>(initialSelectedId);

    if (loading) return <Loader />;

    return (
        <Stack gap="lg" style={{ width: '100%', height: '100%' }}>
            <Heading1>{t('patientSelector.filterHeading')}</Heading1>
            <RadioGroup onChange={setSelectedId} value={selectedId}>
                <Stack gap="sm">
                    {options.map((o) => (
                        <Box
                            key={o.id}
                            data-testid={`patient-selector-radio-button-${o.id}`}
                        >
                            <Radio
                                value={o.id}
                                label={o.name}
                                disabled={o.disabled}
                            />
                        </Box>
                    ))}
                </Stack>
            </RadioGroup>
            <Box style={{ width: '100%', position: 'sticky', bottom: 15 }}>
                <Button
                    fullWidth
                    data-testid="page-primary-action-button"
                    onClick={() => {
                        const selected = options.find(
                            (o) => o.id === selectedId
                        );

                        if (selected) onChange(selected);
                        onClose();
                    }}
                >
                    {t('patientSelector.apply')}
                </Button>
            </Box>
        </Stack>
    );
};
