import { formatTime } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import type { PharmacyProviderInfoFragment } from '../../graphql/generated/graphql';

export function getTodaysHours(provider: PharmacyProviderInfoFragment) {
    const { t } = useTranslation();

    const todaysHours = provider?.weeklyHours?.at(new Date().getDay());
    const todaysHoursFragments = todaysHours?.map((hours) =>
        getFragment(hours)
    );

    return todaysHoursFragments && todaysHoursFragments.length > 0
        ? `${todaysHoursFragments.map((hours) =>
              t('offerPharmacyCard.closeTime', {
                  closeTime: formatTime(hours.endTime),
              })
          )}`
        : t('offerPharmacyCard.closed');
}
