import {
    Body1,
    Flex,
    IconChevronRight,
    OutlinedCard,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { PatientInfoFragment } from '../../../../graphql/generated/graphql';

type MemberSelectionProps = {
    patient: PatientInfoFragment;
    relationshipType: string;
    selectPatient: (patient: PatientInfoFragment) => void;
};

export const MemberSelection = (props: MemberSelectionProps) => {
    const { patient, relationshipType, selectPatient } = props;
    const { t } = useTranslation();

    const relationshipDisplay: Record<string, string | null> = {
        DEPENDENT: t('memberSelection.planMember'),
        SPOUSE: t('memberSelection.planMember'),
        MYSELF: t('memberSelection.myself'),
    };
    return (
        <OutlinedCard onClick={() => selectPatient(patient)}>
            <Flex align="center" justify="space-between">
                <Stack gap="xxs">
                    <Flex gap="xs">
                        <Subtitle2>{patient.fullName}</Subtitle2>
                        <Body1>
                            {t('memberSelection.age', { age: patient.age })}
                        </Body1>
                    </Flex>
                    {relationshipDisplay[relationshipType] ? (
                        <Body1>{relationshipDisplay[relationshipType]}</Body1>
                    ) : null}
                </Stack>
                <IconChevronRight />
            </Flex>
        </OutlinedCard>
    );
};
