import {
    Body1,
    Box,
    Flex,
    IconCheck,
    Image,
    Stack,
    Subtitle1,
    Title1,
} from '@phx/design-system';
import { useTheme } from '@phx/design-system/framework';
import { useIsMobile } from '@phx/design-system/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';
import { DrugSearchBar } from '../drug-search/DrugSearchBar';

import classes from './pharmacy-experience-header.module.css';

export const PharmacyExperienceHeader = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const navigate = useNavigate();

    const handleDrugSearchFocus = useCallback(() => {
        navigate(getAbsoluteRoute('drug.search.root'));
    }, [navigate]);

    return (
        <Flex
            gap="xl"
            align={isMobile ? undefined : 'center'}
            direction={isMobile ? 'column' : 'row-reverse'}
        >
            <Box flex={isMobile ? undefined : '1'}>
                <picture>
                    <source
                        srcSet="/static/img/unauth-home-page-header-lg.png"
                        media={`(min-width: ${theme.breakpoints.sm})`}
                    />
                    <Image
                        src="/static/img/unauth-home-page-header.png"
                        alt={t('unauth.pharmacyExperience.altImage')}
                        classNames={{
                            root: isMobile
                                ? classes['mobile-image']
                                : undefined,
                        }}
                    />
                </picture>
            </Box>
            <Stack
                flex={isMobile ? undefined : '1'}
                gap="md"
                pt="xxl"
                pb="xxxl"
            >
                <Title1>{t('unauth.pharmacyExperience.header')}</Title1>
                <Stack gap={0}>
                    <Flex align="start" gap="xxs" py="xxs">
                        <Box>
                            <IconCheck color="secondary" />
                        </Box>
                        <Body1>
                            {t('unauth.pharmacyExperience.ownPrescriptions')}
                        </Body1>
                    </Flex>
                    <Flex align="start" gap="xxs" py="xxs">
                        <Box>
                            <IconCheck color="secondary" />
                        </Box>
                        <Body1>
                            {t('unauth.pharmacyExperience.takeControl')}
                        </Body1>
                    </Flex>
                    <Flex align="start" gap="xxs" py="xxs">
                        <Box>
                            <IconCheck color="secondary" />
                        </Box>
                        <Body1>
                            {t(
                                'unauth.pharmacyExperience.transferPrescriptions'
                            )}
                        </Body1>
                    </Flex>
                </Stack>
                <Stack pt="xxs">
                    <Subtitle1>
                        {t('unauth.pharmacyExperience.saveDrugSearchHeader')}
                    </Subtitle1>
                    <DrugSearchBar
                        label={t('unauth.pharmacyExperience.drugSearchLabel')}
                        onFocus={handleDrugSearchFocus}
                    />
                </Stack>
            </Stack>
        </Flex>
    );
};
