import { Body1, Flex, Heading2, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type {
    ChainPharmacyInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../graphql/generated/graphql';
import { titleCase } from '../../../util';

import { MailOrderNextSteps } from './MailOrderNextsSteps';

export const DeliveryStep = ({ stepTexts }: { stepTexts: string[] }) => (
    <>
        {stepTexts.map((text) => (
            <Flex key={text} align="start" py="xxs">
                <Body1>{text}</Body1>
            </Flex>
        ))}
    </>
);

export type LocationInfo = {
    name: string;
    phone?: string | null;
    url?: string | null;
    mailOrder?: boolean;
};

type NextStepsProps = {
    location:
        | ChainPharmacyInfoFragment
        | PharmacyInfoFragment
        | PharmacyProviderInfoFragment
        | MailOrderProviderInfoFragment;
};

export const NextSteps = ({ location }: NextStepsProps) => {
    const { t } = useTranslation();

    let locationInfo: LocationInfo = {
        name: titleCase(location?.name),
        phone: location?.phoneNumber,
    };

    if (location.__typename === 'MailOrderProvider') {
        locationInfo = {
            ...locationInfo,
            url: location?.url,
            mailOrder: true,
        };
    }

    return (
        <Stack gap="sm">
            <Heading2>{t('orderConfirmation.subHeader')}</Heading2>
            {locationInfo.mailOrder ? (
                <MailOrderNextSteps locationInfo={locationInfo} />
            ) : (
                <DeliveryStep
                    stepTexts={[t('orderConfirmation.pickUp.description')]}
                />
            )}
        </Stack>
    );
};
