import {
    Body1,
    Carousel,
    Flex,
    Heading1,
    Heading2,
    Image,
    OutlinedCard,
    Stack,
} from '@phx/design-system';
import { useId } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { useSwipeableViews } from '../../hooks/use-swipeable-views';

export const HowToStartUsingMyPHX = () => {
    const { t } = useTranslation();

    const slides = [
        {
            id: useId(),
            content: (
                <Stack gap="xs">
                    <Heading2 color="primary">
                        {t('unauth.startUsingMyPHX.sayPHXCardHeader')}
                    </Heading2>
                    <Body1>{t('unauth.startUsingMyPHX.sayPHXCardBody')}</Body1>
                    <Image
                        radius="md"
                        src="/static/img/unauth-carousel1.png"
                        alt={t('unauth.startUsingMyPHX.sayPHXCardAltImage')}
                    />
                </Stack>
            ),
        },
        {
            id: useId(),
            content: (
                <Stack gap="xs">
                    <Heading2 color="primary">
                        {t('unauth.startUsingMyPHX.textMessageHeader')}
                    </Heading2>
                    <Body1>{t('unauth.startUsingMyPHX.textMessageBody')}</Body1>
                    <Image
                        radius="md"
                        src="/static/img/unauth-carousel2.png"
                        alt={t('unauth.startUsingMyPHX.textMessageAltImage')}
                    />
                </Stack>
            ),
        },
        {
            id: useId(),
            content: (
                <Stack gap="xs">
                    <Heading2 color="primary">
                        {t('unauth.startUsingMyPHX.saveMoneyHeader')}
                    </Heading2>
                    <Body1>{t('unauth.startUsingMyPHX.saveMoneyBody')}</Body1>
                    <Image
                        radius="md"
                        src="/static/img/unauth-carousel3.png"
                        alt={t('unauth.startUsingMyPHX.saveMoneyAltImage')}
                    />
                </Stack>
            ),
        },
    ];

    const useCarousel = useSwipeableViews(slides, 'md');

    return (
        <Stack gap="md" py="xxxl">
            <Stack gap="xs">
                <Heading1>
                    {t('unauth.startUsingMyPHX.startPHXHeader')}
                </Heading1>
                <Body1>{t('unauth.startUsingMyPHX.startPHXSubtitle')}</Body1>
            </Stack>
            {useCarousel ? (
                <Carousel
                    indicatorOffset="var(--mantine-spacing-xxxs)"
                    slides={slides.map((slide) => (
                        <OutlinedCard key={slide.id}>
                            {slide.content}
                        </OutlinedCard>
                    ))}
                />
            ) : (
                <Flex gap="md">
                    {slides.map((slide) => (
                        <OutlinedCard key={slide.id}>
                            {slide.content}
                        </OutlinedCard>
                    ))}
                </Flex>
            )}
        </Stack>
    );
};
