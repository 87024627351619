import {
    Body1,
    Body2,
    Box,
    Breadcrumbs,
    Heading1,
    HorizontalDivider,
    IconChevronRight,
    Image,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useWhatsNewSections } from './whats-new-data';
import type { WhatsNewSectionProps } from './WhatsNewSection';

const WhatsNewRoute = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    const whatsNewSections = useWhatsNewSections();

    const WhatsNewSection = ({
        img,
        alt,
        title,
        description,
        date,
        onClick,
    }: WhatsNewSectionProps) => (
        <Stack gap="sm">
            <Image src={img} alt={alt} />
            <Stack gap="xxxs">
                {date ? <Body2>{date}</Body2> : null}
                <Subtitle1>{title}</Subtitle1>
            </Stack>
            {description ? (
                <Box
                    style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                    }}
                >
                    <Body1>{description}</Body1>
                </Box>
            ) : null}
            <TextButton
                justify="flex-start"
                onClick={onClick}
                RightIcon={IconChevronRight}
            >
                {t('helpCenter.learnMore')}
            </TextButton>
        </Stack>
    );

    const breadcrumbItems = useMemo(
        () => [
            { title: t('helpCenter.breadcrumb.header'), href: '/help-center' },
            {
                title: t('helpCenter.subheader.whatsNew'),
                href: location.pathname,
            },
        ],
        [t, location.pathname]
    );

    return (
        <Stack gap="lg">
            <Breadcrumbs items={breadcrumbItems} />
            <Heading1>{t('whatsNew.header')}</Heading1>
            {whatsNewSections.map((section, idx) => (
                <>
                    <WhatsNewSection key={section.title} {...section} />
                    {idx !== whatsNewSections.length - 1 && (
                        <HorizontalDivider />
                    )}
                </>
            ))}
        </Stack>
    );
};

export default WhatsNewRoute;
