import { AuthorizedOutlet } from '@phx/auth/client';
import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import ClaimHistoryRoute from './ClaimHistoryRoute';
import ClaimsRoute from './ClaimsRoute';

const claimsRoutes: RouteObject[] = [{
    path: routes.claims.root,
    element: <AuthorizedOutlet />,
    children: [
        {
            path: routes.claims.history,
            element: <ClaimHistoryRoute />,
        },
        {
            path: routes.claims.claimId,
            element: <ClaimsRoute />,
        },
    ],
}];

export default claimsRoutes;
