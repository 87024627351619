import type { ProviderOffersInfoFragment } from '../../graphql/generated/graphql';
import { getPriceableOffers } from '../offers/get-priceable-offers';

export const getCheapestOffer = (
    providerOffers: ProviderOffersInfoFragment
) => {
    const offers = getPriceableOffers(providerOffers);
    return offers.reduce(
        (cheapest, next) =>
            next.patientResponsibilityCost < cheapest.patientResponsibilityCost
                ? next
                : cheapest,
        offers[0]
    );
};
