import type { FieldReadFunction } from '@apollo/client';
import { constants } from '@phx/design-system/framework';
import { t } from 'i18next';

import { formatToLocalizedCurrency } from '../../../util';

export const readPricingDetail: FieldReadFunction = (_, { readField }) => {
    const typename = readField<string>('__typename') ?? '';
    const patientCost = readField<number>('patientResponsibilityCost');
    const planPays = readField<number>('planPaysAmount');

    const value =
        typeof patientCost === 'number'
            ? formatToLocalizedCurrency(patientCost)
            : '';
    const formattedPlanPrice =
        typeof planPays === 'number' ? formatToLocalizedCurrency(planPays) : '';

    switch (typename) {
        case 'InsuranceOffer':
            return {
                option: t('offers.pricingInfo.withInsurance'),
                value,
                details: t('offers.pricingInfo.planPaysAmount', {
                    planPaysAmount: formattedPlanPrice,
                }),
            };
        case 'CashOffer':
        default:
            return {
                option: t('offers.pricingInfo.smartprice', {
                    tm: constants.unicode.TRADEMARK,
                }),
                value,
                details: t('offers.pricingInfo.priceWithoutInsurance'),
            };
    }
};
