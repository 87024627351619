import { useAuthContext } from '@phx/auth/client';
import { Stack } from '@phx/design-system';
import { Navigate } from 'react-router-dom';

import { HealthcareTechEmpowers } from '../../components/unauth-home/HealthcareTechEmpowers';
import { HowToStartUsingMyPHX } from '../../components/unauth-home/HowToStartUsingMyPHX';
import { JoinEmployerPlan } from '../../components/unauth-home/JoinEmployerPlan';
import { PharmacyExperienceHeader } from '../../components/unauth-home/PharmacyExperienceHeader';
import { ShopTheCashPrice } from '../../components/unauth-home/ShopTheCashPrice';
import { Testimonials } from '../../components/unauth-home/Testimonials';
import { getAbsoluteRoute } from '../../util';

const UnauthHomeRoute = () => {
    const { isAuthenticated } = useAuthContext();

    if (isAuthenticated) {
        return <Navigate to={getAbsoluteRoute('home')} replace={true} />;
    }

    return (
        <Stack gap="xxl">
            <PharmacyExperienceHeader />
            <JoinEmployerPlan />
            <HowToStartUsingMyPHX />
            <ShopTheCashPrice />
            <HealthcareTechEmpowers />
            <Testimonials />
        </Stack>
    );
};

export default UnauthHomeRoute;
