import type { DocumentNode, OperationDefinitionNode } from 'graphql';

import * as gql from '../../generated/graphql';

const gqlEntries = Object.entries(gql);
const documents = gqlEntries.filter((x) =>
    x[0].endsWith('Document')
) as unknown as [string, DocumentNode][];
export const graphDocuments = documents.map((x) => x[1]);

export const queryDocuments = documents
    .filter((x) => getDocumentDefinition(x[1]).operation === 'query')
    .map((x) => x[1]);

export const queryMap = queryDocuments.reduce(
    (all, x) => ({ ...all, [getDocumentName(x)]: x }),
    {} as Record<string, DocumentNode>
);

export const mutationDocuments = documents
    .filter((x) => getDocumentDefinition(x[1]).operation === 'mutation')
    .map((x) => x[1]);

export const mutationMap = mutationDocuments.reduce(
    (all, x) => ({ ...all, [getDocumentName(x)]: x }),
    {} as Record<string, DocumentNode>
);

export function getDocumentDefinition(
    document: DocumentNode
): OperationDefinitionNode {
    return document.definitions[0] as OperationDefinitionNode;
}

export function getDocumentName(document: DocumentNode): string {
    return getDocumentDefinition(document).name!.value;
}
