import { useContext } from 'react';

import {
    ProfileContext,
    type ProfileContextType,
} from '../contexts/ProfileProvider';

export const useProfile = (): ProfileContextType => {
    const ctx = useContext(ProfileContext);

    if (ctx === null) {
        throw new Error(
            'useProfile() cannot be called ouside a ProfileProvider'
        );
    }

    return ctx;
};
