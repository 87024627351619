import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';

import { isPriceableOffer } from './is-priceable-offer';

export const getPriceableOffers = (
    providerOffers: ProviderOffersInfoFragment
): Array<CashOfferInfoFragment | InsuranceOfferInfoFragment> => {
    return providerOffers.offers
        .map((offer) => getFragment(offer))
        .filter((offer) => isPriceableOffer(offer));
};
