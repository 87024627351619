// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

export type WhatsNewProps = {
    img: string;
    alt: string;
    title: string;
    description?: string;
    date?: string;
    onClick?: () => void;
};

//NOTE: There is nothing to announce under "What's New" yet. The commented out objects are what might be announced soon.
//TODO: Make an announcement page template similar to FeatureAnnouncement in ripx repo

export const useWhatsNewSections = (): WhatsNewProps[] => {
    // const { t } = useTranslation(['help-center-locales/help-center']);
    // const navigate = useNavigate();

    return [
        // {
        //     img: '/static/img/delivery-fulfillment.png',
        //     alt: t('whatsNew.atHome.image.alttext'),
        //     title: t('whatsNew.atHome.title'),
        //     description: t('whatsNew.atHome.description'),
        //     date: '',
        //     onClick: () => {},
        // },
        // {
        //     img: '/static/img/share-prescriptions.png',
        //     alt: t('whatsNew.access.image.alttext'),
        //     title: t('whatsNew.access.title'),
        //     description: t('whatsNew.access.description'),
        //     date: '',
        //     onClick: () => {},
        // },
        // {
        //     img: '/static/img/prescription-status.png',
        //     alt: t('whatsNew.specialty.image.alttext'),
        //     title: t('whatsNew.specialty.title'),
        //     description: t('whatsNew.specialty.description'),
        //     date: '',
        //     onClick: () => {},
        // },
    ];
};
