import type { FieldReadFunction, Reference } from '@apollo/client';

import { formatAddress } from '../../../util/formatting/address/address';
import type { IndividualProvider } from '../../generated/graphql';

export const readProviderDescription: FieldReadFunction = (
    _,
    { readField }
) => {
    const typename = readField(
        '__typename'
    ) as IndividualProvider['__typename'];
    const address = readField<Reference>('address');

    switch (typename) {
        case 'PharmacyProvider':
            return formatAddress({
                city: readField<string>('city', address) ?? '',
                line1: readField<string>('line1', address) ?? '',
                line2: readField<string>('line2', address),
                state: readField<string>('state', address) ?? '',
                zipCode: readField<string>('zipCode', address) ?? '',
            });
        case 'MailOrderProvider':
            return readField<string>('url') ?? '';
        default:
            return;
    }
};
