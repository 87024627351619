import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import SelectLanguageRoute from './SelectLanguageRoute';

const languageRoutes: RouteObject[] = [{
    path: routes.language,
    element: <SelectLanguageRoute />,
}];

export default languageRoutes;
