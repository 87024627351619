export const isActiveDateRange = (
    start?: string | null,
    end?: string | null
) => {
    const now = Date.now();

    return (
        (!start || now > Date.parse(start)) && (!end || now < Date.parse(end))
    );
};
