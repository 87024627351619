import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../../app-routes';

import { AlternativesRoute } from './AlternativesRoute';
import { PrescriptionOfferSelectionRoute } from './PrescriptionOfferSelectionRoute';
import { PrescriptionRoute } from './PrescriptionRoute';
import PreviewDetailsRoute from './PreviewDetailsRoute';
import { VerifyPatientRoute } from './VerifyPatientRoute';
import { VerifyPrescriptionRoute } from './VerifyPrescriptionRoute';

const prescriptionRoutes: RouteObject[] = [
    {
        path: routes.cabinet.rxId.root,
        element: <Outlet />,
        children: [
            { index: true, element: <PrescriptionRoute /> },
            {
                path: routes.cabinet.rxId.alternatives,
                element: <AlternativesRoute />,
            },
            {
                path: routes.cabinet.rxId.verifyPatient,
                element: <VerifyPatientRoute />,
            },
            {
                path: routes.cabinet.rxId.verify,
                element: <VerifyPrescriptionRoute />,
            },
            {
                path: routes.cabinet.rxId.providerOffersId.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <PrescriptionOfferSelectionRoute />,
                    },
                    {
                        path: routes.cabinet.rxId.providerOffersId.offer.root,
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: <PreviewDetailsRoute />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default prescriptionRoutes;
