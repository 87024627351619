const externalRoutes = {
    privacyPolicy: 'https://prescryptive.com/privacy-policy/',
    termsConditions: 'https://prescryptive.com/terms-of-use/',
    waPrivacyNotice:
        'https://prescryptive.com/wa-consumer-health-data-privacy-notice/',
    priorAuthMedicationList:
        'https://prescryptive.com/wp-content/uploads/2024/05/Prior-Authorization-Medication-List.pdf',
    priorAuthForm: 'https://resources.prescryptive.io/231657365426865',
};

export default externalRoutes;
