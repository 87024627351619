import {
    Box,
    Flex,
    HorizontalDivider,
    IconButton,
    IconInfo,
    OutlinedCard,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import { useProfile } from '../../hooks/use-profile';
import { getMemberId } from '../../util/patient/get-member-id';
import { getRelationshipDisplay } from '../../util/patient/get-relationship-display';
import { sortDependentList } from '../../util/patient/sort-dependent-list';
import { PlanMembersModal } from '../plan-members/PlanMembersModal';

import {
    DependentListItem,
    type DependentListItemProps,
    type DependentListItemType,
} from './DependentListItem';

const DEFAULT_VISIBLE_DEPENDENTS = 4;

export const DependentList = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();
    const { patient: currentPatient, profile } = useProfile();

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);
    const openDialog = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);

    const dependents: DependentListItemProps[] =
        profile.patientRelationshipConnection.edges
            .map((edge) => {
                const { relationshipType, relatedAccountId } =
                    edge.relationship;
                const dependentType = relationshipType as DependentListItemType;
                const member = getFragment(edge.node);
                const memberId = getMemberId(member);

                if (relatedAccountId === currentPatient.id) {
                    return {
                        memberId,
                        relationshipType: 'PRIMARY' as const,
                        relationshipTypeName: getRelationshipDisplay('PRIMARY'),
                        patient: member,
                    };
                }

                return {
                    memberId,
                    relationshipType: dependentType,
                    relationshipTypeName: getRelationshipDisplay(dependentType),
                    patient: member,
                };
            })
            .filter(({ memberId }) => Boolean(memberId))
            .toSorted(sortDependentList);

    if (dependents.length === 0) {
        return null;
    }

    const visibleDependents = showAll
        ? dependents
        : dependents.slice(0, DEFAULT_VISIBLE_DEPENDENTS);
    const showMore = visibleDependents.length < dependents.length;

    return (
        <>
            <Box>
                <Box>
                    <Flex align="center" gap="xxs" mb="sm">
                        <Subtitle1>{t('planMembers.heading')}</Subtitle1>
                        <IconButton
                            Icon={IconInfo}
                            onClick={openDialog}
                            withStyles={false}
                        />
                    </Flex>
                    <OutlinedCard>
                        <Stack gap="sm">
                            {visibleDependents.map((dependent, idx) => (
                                <>
                                    {idx > 0 ? <HorizontalDivider /> : null}
                                    <DependentListItem
                                        key={dependent.patient.id}
                                        {...dependent}
                                    />
                                </>
                            ))}
                            {showMore ? (
                                <TextButton
                                    data-testid="see-more-dependents-button"
                                    onClick={() => setShowAll(true)}
                                >
                                    {`${t('planMembers.seeMore')} (${dependents.length - 4})`}
                                </TextButton>
                            ) : null}
                        </Stack>
                    </OutlinedCard>
                </Box>
                <PlanMembersModal isOpen={showDialog} close={closeDialog} />
            </Box>
            <HorizontalDivider />
        </>
    );
};
