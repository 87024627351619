import {
    Flex,
    Heading1,
    IconButton,
    IconInfo,
    Modal,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import type { ReactNode } from 'react';

type InfoHeadingProps = {
    title: string;
    informationDialog: {
        title: string;
        content: ReactNode;
    };
};
export const InfoHeading = (props: InfoHeadingProps) => {
    const { title, informationDialog } = props;
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <Flex align="center" gap="xxs">
            <Heading1>{title}</Heading1>
            {informationDialog && (
                <IconButton
                    withStyles={false}
                    Icon={IconInfo}
                    color="primary"
                    onClick={open}
                />
            )}
            <Modal
                size="lg"
                onClose={close}
                opened={opened}
                title={informationDialog?.title}
            >
                {informationDialog?.content}
            </Modal>
        </Flex>
    );
};
