import { getLocalItem, setLocalItem } from '@phx/browser-utils';
import { useCallback, useEffect, useState } from 'react';
import { ldFlagStorageKey } from './storage-key';
export const useFlagOverrides = () => {
    const [overriddenFlags, setOverriddenFlags] = useState(() => {
        const storedData = getLocalItem(ldFlagStorageKey);
        return storedData || {};
    });
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === ldFlagStorageKey) {
                const newValue = event.newValue
                    ? JSON.parse(event.newValue)
                    : {};
                setOverriddenFlags(newValue);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);
    const setFlagOverride = useCallback(({ key, value }) => {
        setOverriddenFlags((prevFlags) => {
            const updatedFlags = { ...prevFlags, [key]: value };
            setLocalItem(ldFlagStorageKey, updatedFlags);
            return updatedFlags;
        });
    }, []);
    const clearFlagOverrides = useCallback(() => {
        localStorage.removeItem(ldFlagStorageKey);
        setOverriddenFlags({});
    }, []);
    const clearFlagOverride = useCallback((key) => {
        setOverriddenFlags((prevFlags) => {
            const { [key]: _, ...restFlags } = prevFlags;
            setLocalItem(ldFlagStorageKey, restFlags);
            return restFlags;
        });
    }, []);
    return {
        overriddenFlags,
        setFlagOverride,
        clearFlagOverride,
        clearFlagOverrides,
    };
};
