import { Navigate, useParams } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';

export const PrescriptionRedirect = () => {
    const { prescriptionId } = useParams();

    return (
        <Navigate
            to={getAbsoluteRoute('cabinet.rxId.root', {
                prescriptionId: prescriptionId!,
            })}
            replace
        />
    );
};
