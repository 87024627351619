import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { VerifyPatient } from '../../../components/cabinet/prescription/verify-patient/VerifyPatient';
import { getFragment } from '../../../graphql';
import {
    ClaimPrescriberOrderForPatientDocument,
    GetPrescriberOrdersByPatientDocument,
    GetPrescriberOrdersDocument,
    GetUnclaimedPrescriberOrderDocument,
    PrescriberOrderStatus,
    type PatientInfoFragment,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';
import routes from '../../app-routes';

export const VerifyPatientRoute = () => {
    const { prescriptionId } = useParams();
    if (prescriptionId === undefined) {
        // TODO: Better validation
        throw Error('Prescription ID is required');
    }

    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const navigate = useNavigate();

    const refetchQueries = [
        {
            query: GetPrescriberOrdersDocument,
            variables: { patientId: primaryPatientId },
        },
        {
            query: GetPrescriberOrdersByPatientDocument,
            variables: {
                patientId: scopedPatientId,
                input: { statusFilter: [PrescriberOrderStatus.New] },
            },
        },
    ];

    const [ClaimPrescriberOrderForPatient] = useMutation(
        ClaimPrescriberOrderForPatientDocument,
        {
            refetchQueries,
            awaitRefetchQueries: true,
        }
    );
    const handleClaimPrescription = async (patient: PatientInfoFragment) => {
        const result = await ClaimPrescriberOrderForPatient({
            variables: {
                patientId: patient.id,
                prescriberOrderId: prescriptionId,
            },
        });
        if (result.data?.claimPrescriberOrderForPatient.success) {
            navigate(getAbsoluteRoute('cabinet.rxId.root', { prescriptionId }));
        }
    };

    const Loader = QueryLoader<typeof GetUnclaimedPrescriberOrderDocument>;
    return (
        <Loader
            query={GetUnclaimedPrescriberOrderDocument}
            variables={{ prescriberOrderId: prescriptionId }}
            render={(data) => {
                if (data.unclaimedPrescriberOrder) {
                    const prescriberOrder = getFragment(
                        data.unclaimedPrescriberOrder
                    );
                    if (
                        !prescriberOrder.matchingPatientsConnection?.edges
                            .length
                    ) {
                        return (
                            <Navigate
                                to={getAbsoluteRoute('cabinet.rxId.verify', {
                                    prescriptionId,
                                })}
                            />
                        );
                    }
                    return (
                        <VerifyPatient
                            prescriberOrder={prescriberOrder}
                            claimPrescription={handleClaimPrescription}
                        />
                    );
                }
                return <Navigate to={routes.home} />;
            }}
        />
    );
};
