import { AuthorizedRoute } from '@phx/auth/client';
import type { RouteObject } from 'react-router-dom';

import { ProfileProvider } from '../../contexts/ProfileProvider';
import routes from '../app-routes';

import HomeRoute from './HomeRoute';

const homeRoutes: RouteObject[] = [
    {
        path: routes.home,
        element: (
            <AuthorizedRoute>
                <ProfileProvider>
                    <HomeRoute />
                </ProfileProvider>
            </AuthorizedRoute>
        ),
    },
];

export default homeRoutes;
