import { default as i18n } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';

if (import.meta.env.MODE !== 'production') {
    i18n.use(i18nextPlugin);
}

i18n.use(
    resourcesToBackend(async (lang: string, ns: string) => {
        const entries = ns.split('/');
        let path = 'locales';
        let nsName = entries[0];

        if (entries.length > 1) {
            path = entries[0];
            nsName = entries[1];
        }

        const result = await fetch(`/${path}/${lang}/${nsName}.json`);

        if (!result.ok) {
            return null;
        }

        return await result.json();
    })
)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'es'],
        partialBundledLanguages: true,
        fallbackLng: import.meta.env.MODE === 'production' ? 'en' : false,
        interpolation: {
            escapeValue: false,
        },
        ns: [
            'myphx',
            'pds',
            'phx-lib',
            'pwa',
            'loc',
            'tlk',
            'auth',
            'code-locales/package-code-mapping',
            'code-locales/form-code-mapping',
            'help-center-locales/help-center',
        ],
        defaultNS: 'myphx',
        returnEmptyString: false,
    });

export default i18n;
