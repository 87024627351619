import {
    Box,
    ElevatedCard,
    HorizontalDivider,
    OutlinedCard,
    RoundedSkeleton,
    Subtitle1,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type MedicineCabinetPlaceholderProps = {
    variant: 'elevated' | 'outlined';
};

export const MedicineCabinetPlaceholder = ({
    variant,
}: MedicineCabinetPlaceholderProps) => {
    const { t } = useTranslation();

    const Card = variant === 'elevated' ? ElevatedCard : OutlinedCard;

    return (
        <Card
            header={
                <Subtitle1 color="currentColor">
                    {t('cabinet.ordersCard.header')}
                </Subtitle1>
            }
        >
            <Box p="xs">
                <RoundedSkeleton height="48px" width="100%" />
            </Box>
            <HorizontalDivider />
            <Box p="xs">
                <RoundedSkeleton height="48px" width="100%" />
            </Box>
            <HorizontalDivider />
            <Box p="xs">
                <RoundedSkeleton height="48px" width="100%" />
            </Box>
        </Card>
    );
};
