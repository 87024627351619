import { Body2, FilledCard, Flex, Heading2, Stack } from '@phx/design-system';
import {
    FemaleDoctorIllustration,
    IllustrationCard,
    SaveMoneyIllustration,
    TextUpdatesIllustration,
} from '@phx/myphx-lib';
import type { PropsWithChildren as PWC } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './steps.module.css';

const StepNumber = ({ children }: PWC) => {
    return <Flex classNames={{ root: classes.stepNumber }}>{children}</Flex>;
};

export const Steps = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="lg">
            <IllustrationCard
                variant="bare"
                color="success"
                Illustration={FemaleDoctorIllustration}
                illustrationSize="sm"
                header={
                    <Heading2 color="primary">
                        <Flex gap="xs">
                            <StepNumber>1</StepNumber>
                            {t('startSavingPage.steps.step1.title')}
                        </Flex>
                    </Heading2>
                }
            >
                <Stack gap="xxs">
                    <Body2>{t('startSavingPage.steps.step1.body.line1')}</Body2>
                    <FilledCard color="gray">
                        <Flex justify="center">
                            <Body2 bold color="primary">
                                {t('startSavingPage.steps.step1.body.line2')}
                            </Body2>
                        </Flex>
                    </FilledCard>
                    <Body2>{t('startSavingPage.steps.step1.body.line3')}</Body2>
                </Stack>
            </IllustrationCard>
            <IllustrationCard
                variant="bare"
                color="success"
                Illustration={TextUpdatesIllustration}
                illustrationSize="sm"
                header={
                    <Heading2 color="primary">
                        <Flex gap="xs">
                            <StepNumber>2</StepNumber>
                            {t('startSavingPage.steps.step2.title')}
                        </Flex>
                    </Heading2>
                }
            >
                <Stack gap="xxs">
                    <Body2>{t('startSavingPage.steps.step2.body.line1')}</Body2>
                </Stack>
            </IllustrationCard>
            <IllustrationCard
                variant="bare"
                color="warning"
                Illustration={SaveMoneyIllustration}
                illustrationSize="sm"
                header={
                    <Heading2 color="primary">
                        <Flex gap="xs">
                            <StepNumber>3</StepNumber>
                            {t('startSavingPage.steps.step3.title')}
                        </Flex>
                    </Heading2>
                }
            >
                <Stack gap="xxs">
                    <Body2>{t('startSavingPage.steps.step3.body.line1')}</Body2>
                    <Body2>{t('startSavingPage.steps.step3.body.line2')}</Body2>
                </Stack>
            </IllustrationCard>
        </Stack>
    );
};
