import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    DrugFamilyDocument,
    type DrugFamilyInfoFragment,
    DrugFamilyInfoFragmentDoc,
    GetProductDocument,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

import { ProductConfigrationView } from './ProductConfigurationView';

type ProductConfigurationProps = {
    drugId: string;
    drugFamilyId?: string;
    closeEdit: () => void;
};

/**
 * Note: There are limitations with the current search endpoint that require a work around here.
 * The "DrugFamily" ID is not an actual id we can use to search.
 * We have to use the ndc in the url to price or search.
 * The drugFamily returned from the drugFamilyById query will be missing options for configuration.
 * To work around this we need to pull the cached drug family from the product search and pair it with the NDC in the url(the selected product).
 * This is a temporary solution until the next iteration of the search endpoint.
 * 6/14/2024
 */

export const ProductConfiguration = ({
    drugId,
    drugFamilyId,
    closeEdit,
}: ProductConfigurationProps) => {
    const client = useApolloClient();
    const [cachedDrugFamily, setCachedDrugFamily] =
        useState<DrugFamilyInfoFragment | null>(null);
    const Loader = QueryLoader<typeof DrugFamilyDocument>;

    useEffect(() => {
        if (drugFamilyId) {
            const cachedFamily =
                client.cache.readFragment<DrugFamilyInfoFragment>({
                    id: `DrugFamily:${drugFamilyId}`,
                    fragment: DrugFamilyInfoFragmentDoc,
                    fragmentName: 'DrugFamilyInfo',
                });

            setCachedDrugFamily(cachedFamily);
        }
    }, [drugFamilyId]);

    if (cachedDrugFamily) {
        const ProductLoader = QueryLoader<typeof GetProductDocument>;

        return (
            <ProductLoader
                query={GetProductDocument}
                variables={{ productId: drugId }}
                render={(data) => {
                    const product = data?.product
                        ? getFragment(data.product)
                        : getFragment(cachedDrugFamily.defaultProduct);

                    return (
                        <ProductConfigrationView
                            drugFamily={cachedDrugFamily}
                            product={product}
                            closeEdit={closeEdit}
                        />
                    );
                }}
            />
        );
    }

    /**
     * In the event that we have no cache hit we can just go with the default ndc on the drug family returned.
     * Note we may be missing configuration options but this is a limitation of the current endpoint.
     * In most cases we should get a cache hit unless this is a deep link entry to a specific drug.
     */
    return (
        <Loader
            query={DrugFamilyDocument}
            variables={{ id: drugId }}
            render={(data) => {
                if (data?.drugFamily) {
                    const drugFamily = getFragment(data.drugFamily);
                    const productInfo = getFragment(drugFamily.defaultProduct);

                    return (
                        <ProductConfigrationView
                            drugFamily={drugFamily}
                            product={productInfo}
                            closeEdit={closeEdit}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
