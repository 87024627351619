import type {
    InsuranceOfferInfoFragment,
    PriceQuoteInfoFragment,
} from '../../../generated/graphql';

export const isValidPlanCost = (
    quote?: Pick<
        PriceQuoteInfoFragment | InsuranceOfferInfoFragment,
        'planPaysAmount'
    >
) => !!quote && typeof quote?.planPaysAmount === 'number';
