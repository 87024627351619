import {
    Box,
    IconChevronRight,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { ConsumersIllustration, IllustrationCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const SaveMoneyFamilyCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <IllustrationCard
            Illustration={ConsumersIllustration}
            variant="filled"
            color="warning"
            header={<Subtitle1>{t('home.saveMoneyCard.heading')}</Subtitle1>}
            onClick={() => navigate(getAbsoluteRoute('startSaving'))}
            role="tab"
        >
            <Box>
                <TextButton RightIcon={IconChevronRight}>
                    {t('home.saveMoneyCard.button')}
                </TextButton>
            </Box>
        </IllustrationCard>
    );
};
