import { IconKeep, SideNavLink } from '@phx/design-system';
import { usePwaContext } from '@phx/pwa';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebarContext } from '../../../contexts/SidebarProvider';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

export const AddToHomeMenuItem = () => {
    const { t } = useTranslation();
    const { canInstall, isInstalled, installApp } = usePwaContext();
    const { close } = useSidebarContext();

    const showAddToHome = canInstall && !isInstalled;

    const handler = useCallback(() => {
        installApp();
        close();
    }, [installApp, close]);

    return (
        <ConditionalComponent condition={showAddToHome}>
            <SideNavLink
                label={t('sidebar.helpAndSettings.addToHome')}
                Icon={IconKeep}
                onClick={handler}
            />
        </ConditionalComponent>
    );
};
