import type { ClaimInfoFragment } from '../../graphql/generated/graphql';
import { useGetPricingDetail } from '../../hooks/use-get-pricing-detail';

export const useClaimOffer = (claim?: ClaimInfoFragment) => {
    const getPricingDetail = useGetPricingDetail();
    if (!claim) {
        return undefined;
    }
    if (
        claim.plan.__typename === 'PhxBenefitsPlan' &&
        claim.planPayAmount != null
    ) {
        return {
            __typename: 'InsuranceOffer' as const,
            patientResponsibilityCost: claim.patientPayAmount,
            planPaysAmount: claim.planPayAmount,
            pricingDetail: getPricingDetail(
                'insurance',
                claim.patientPayAmount,
                claim.planPayAmount
            ),
        };
    } else {
        return {
            __typename: 'CashOffer' as const,
            patientResponsibilityCost: claim.patientPayAmount,
            pricingDetail: getPricingDetail('cash', claim.patientPayAmount),
        };
    }
};
