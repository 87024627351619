import {
    type PropsWithChildren as PWC,
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';

export type SidebarCtx = {
    close: () => void;
    isOpen: boolean;
    open: () => void;
    toggle: () => void;
};

const SidebarContext = createContext<SidebarCtx | null>(null);

export const SidebarProvider = ({ children }: PWC) => {
    const [isOpen, setIsOpen] = useState(false);

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const open = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const toggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, [setIsOpen]);

    const ctx: SidebarCtx = {
        close,
        isOpen,
        open,
        toggle,
    };

    return (
        <SidebarContext.Provider value={ctx}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => {
    const ctx = useContext(SidebarContext);
    if (ctx === null) {
        throw new Error(
            'useSidebarContext must be called from within a SidebarProvider'
        );
    }

    return ctx;
};
