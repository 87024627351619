import {
    IconHealthAndSafety,
    OutlinedCard,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const JOIN_EMPLOYER_PLAN_CARD_KEY =
    'home-screen:show-join-employer-plan';

export const JoinEmployerPlanCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToVerifyBenefit = () => {
        navigate(getAbsoluteRoute('profile.verify'));
    };

    return (
        <OutlinedCard
            Icon={IconHealthAndSafety}
            iconColor="secondary"
            data-testid="join-employer-plan-card"
            header={
                <Subtitle2>
                    {t('profile.joinEmployerPlanCard.header')}
                </Subtitle2>
            }
        >
            <TextButton onClick={navigateToVerifyBenefit}>
                {t('profile.joinEmployerPlanCard.activateNow')}
            </TextButton>
        </OutlinedCard>
    );
};
