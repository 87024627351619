import { getFragment } from '../../graphql';
import type { OffersConnectionInfoFragment } from '../../graphql/generated/graphql';

export const getOffersForProvider = (
    offersConnection: OffersConnectionInfoFragment | undefined | null,
    providerId: string
) => {
    if (!offersConnection) {
        return undefined;
    }
    return getFragment(
        offersConnection?.edges.find(
            (x) => getFragment(getFragment(x.node).provider).id === providerId
        )?.node
    );
};
