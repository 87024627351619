import { ClaimHistory } from '../../components/claim-history/ClaimHistory';
import { GetClaimHistoryDocument } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { QueryLoader } from '../../loaders';

const ClaimHistoryRoute = () => {
    const { primaryPatientId: patientId } = usePatientContext();
    const Loader = QueryLoader<typeof GetClaimHistoryDocument>;

    return (
        <Loader
            query={GetClaimHistoryDocument}
            variables={{ patientId }}
            component={ClaimHistory}
        />
    );
};

export default ClaimHistoryRoute;
