import type { Product } from '../../../generated/graphql';

export const formatProductDescription = ({
    name,
    strengthText,
    strengthUnit,
    formValue,
}: Pick<Product, 'name' | 'strengthText' | 'strengthUnit' | 'formValue'>) => {
    const strength =
        strengthText && strengthUnit ? ' ' + strengthText + strengthUnit : '';
    const form = formValue ? ' ' + formValue : '';
    return `${name}${strength}${form}`;
};
