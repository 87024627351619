import { AuthorizedOutlet } from '@phx/auth/client';
import { type RouteObject } from 'react-router-dom';

import { ProfileProvider } from '../../contexts/ProfileProvider';
import routes from '../app-routes';

import ProfileBenefitsRoute from './ProfileBenefitsRoute';
import ProfileRoute from './ProfileRoute';
import ProfileVerifyRoute from './ProfileVerifyRoute';

const profileRoutes: RouteObject[] = [
    {
        path: routes.profile.root,
        element: (
            <ProfileProvider>
                <AuthorizedOutlet />
            </ProfileProvider>
        ),
        children: [
            {
                index: true,
                element: <ProfileRoute />,
            },
            {
                path: routes.profile.benefit,
                element: <ProfileBenefitsRoute />,
            },
            {
                path: routes.profile.verify,
                element: <ProfileVerifyRoute />,
            },
        ],
    },
];

export default profileRoutes;
