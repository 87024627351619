import { Anchor, Body1, Box, Heading2, Stack } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { SmartpriceCardInfoModal } from './SmartpriceCardInfoModal';

export const SmartpriceCardDetails = () => {
    const [showModal, handlers] = useDisclosure(false);
    const { t } = useTranslation();

    return (
        <Box>
            <Stack gap="sm">
                <Heading2 data-testid="carousel-rx-card-title">
                    {t('planCards.smartprice.heading', {
                        tm: constants.unicode.TRADEMARK,
                    })}
                </Heading2>
                <Box>
                    <Body1 data-testid="carousel-rx-card-caption">
                        {t('planCards.smartprice.body')}
                    </Body1>
                    <Anchor
                        onClick={handlers.open}
                        data-testid="carousel-rx-card-learn-more-link"
                    >
                        {t('planCards.smartprice.learnMore')}
                    </Anchor>
                </Box>
            </Stack>
            <SmartpriceCardInfoModal
                isOpen={showModal}
                close={handlers.close}
            />
        </Box>
    );
};
