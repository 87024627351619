import type { FieldReadFunction, Reference } from '@apollo/client';

import {
    type PatientPriceQuotesConnection,
    PriceQuoteInfoFragmentDoc,
} from '../../../generated/graphql';
import { useRequiredField } from '../../util';

import { getPriceQuoteResult } from './get-price-quote-result';

const smartpricePlan = {
    id: '200P32F',
    name: 'SmartPrice',
    bin: '610749',
    pcn: 'X01',
    groupPlanCode: '200P32F',
    __typename: 'SmartpricePlan' as const,
    __ref: 'SmartpricePlan:200P32F',
};

export const readPriceQuoteResult: FieldReadFunction = (
    _,
    { readField, variables, cache }
) => {
    const readRequiredField = useRequiredField(readField);

    const priceQuotesConnection =
        readRequiredField<PatientPriceQuotesConnection>(
            'priceQuotesConnection'
        );

    if (!priceQuotesConnection.edges.length) {
        return {
            __typename: 'PriceQuoteResult',
            id: '',
            pharmacyPriceSets: [],
        };
    }

    const cheapestPriceQuote = readField<Reference>('cheapestPriceQuote');
    const locationNode = readField<Reference>('location', cheapestPriceQuote);
    const bestPriceLocationId = readField<string>('id', locationNode);

    const fields = {
        priceQuotesConnection: {
            edges: priceQuotesConnection.edges.map((edge) => {
                const nodeRef = readRequiredField<Reference>('node', edge);
                const planNode = readField<Reference>('plan', nodeRef);
                const locationNode = readRequiredField<Reference>(
                    'location',
                    nodeRef
                );
                const productNode = readRequiredField<Reference>(
                    'product',
                    nodeRef
                );

                return {
                    node: {
                        __ref: nodeRef.__ref,
                        patientResponsibilityCost: readField<number>(
                            'patientResponsibilityCost',
                            nodeRef
                        ),
                        planPaysAmount: readField<number>(
                            'planPaysAmount',
                            nodeRef
                        ),
                        // plan is possibly undefined here in the unauthenticated shopping flow
                        plan: planNode
                            ? {
                                  __ref: planNode.__ref,
                                  __typename: readRequiredField<string>(
                                      '__typename',
                                      planNode
                                  ),
                              }
                            : undefined,
                        location: {
                            __ref: locationNode.__ref,
                            id: readRequiredField<string>('id', locationNode),
                            longitude: readRequiredField<number>(
                                'longitude',
                                locationNode
                            ),
                            latitude: readRequiredField<number>(
                                'latitude',
                                locationNode
                            ),
                            chainCode: readField<string>(
                                'chainCode',
                                locationNode
                            ),
                        },
                        product: {
                            __ref: productNode.__ref,
                            id: readRequiredField<string>('id', productNode),
                        },
                    },
                };
            }),
        },
        cheapestPriceQuote: bestPriceLocationId
            ? {
                  location: {
                      id: bestPriceLocationId,
                  },
              }
            : undefined,
    };

    const addPlanToPriceQuote = (priceQuote: Reference) => {
        const fullPriceQuote = cache.readFragment({
            id: cache.identify(priceQuote),
            fragment: PriceQuoteInfoFragmentDoc,
            fragmentName: 'PriceQuoteInfo',
        });
        if (!fullPriceQuote) {
            return;
        }
        cache.writeFragment({
            id: cache.identify(priceQuote),
            data: {
                ...fullPriceQuote,
                plan: smartpricePlan,
                ' $fragmentName': 'PriceQuoteInfoFragment',
                prescriberOrder: null,
                planPaysAmount: null,
            },
            fragment: PriceQuoteInfoFragmentDoc,
            fragmentName: 'PriceQuoteInfo',
            broadcast: false,
        });
    };

    return getPriceQuoteResult(
        fields,
        {
            coordinatesParams: variables?.coordinatesParams,
            prescriberOrderId: variables?.prescriberOrderId,
            productIds: variables?.products?.map((x: any) => x?.productId),
        },
        addPlanToPriceQuote
    );
};
