import { Body1, Subtitle2 } from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';

import type { ProductInfoFragment } from '../../../graphql/generated/graphql';
import DrugDetails from '../../common/DrugDetails';

type PatientProductDetailsProps = {
    product: ProductInfoFragment;
    productDescription?: string | null;
    quantity?: number | null;
};

export const ProductDetails = ({
    product,
    productDescription,
    quantity,
}: PatientProductDetailsProps) => {
    const { t } = useTranslation();
    const { drugDetailsSplitEnabled } = useFlags();
    return drugDetailsSplitEnabled ? (
        <>
            <Subtitle2>{product.name}</Subtitle2>
            <DrugDetails product={product} quantity={quantity} />
        </>
    ) : (
        <>
            <Subtitle2>{productDescription || product.description}</Subtitle2>
            {quantity != null || product.modeQuantity != null ? (
                <Body1>
                    {t('order.prescribedQuantity', {
                        quantity: quantity ?? product.modeQuantity,
                    })}
                </Body1>
            ) : null}
        </>
    );
};
