import { Body1, Heading2, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    NonPriceableOfferInfoFragment,
} from '../../../graphql/generated/graphql';
import { LocationInfo } from '../../common/LocationInfo';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

export const PharmacyProviderDetails = ({
    offer,
}: {
    offer:
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment
        | NonPriceableOfferInfoFragment;
}) => {
    const { t } = useTranslation();

    const location = getFragment(offer.pharmacyProvider);
    let description;
    switch (location.__typename) {
        case 'MailOrderProvider':
            if (location.url) {
                description = t('previewDetails.description.mailOrderWebsite');
            } else if (location.phoneNumber) {
                description = t('previewDetails.description.mailOrderPhone');
            }
            break;
        default:
            description = t('previewDetails.description.pickUp');
            break;
    }

    return (
        <Stack gap="sm">
            <Heading2>{t('previewDetails.heading')}</Heading2>
            {offer.prescriberOrder ? <Body1>{description}</Body1> : null}
            <ConditionalComponent condition={!!location}>
                <LocationInfo location={location} />
            </ConditionalComponent>
        </Stack>
    );
};
