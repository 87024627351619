import { useAuthContext } from '@phx/auth/client';
import {
    IconApprovalDelegation,
    IconBadge,
    IconFavorite,
    IconMedication,
    SideNavLink,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePatientContext } from '../../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../../util';
import { ConditionalComponent } from '../../utility/ConditionalComponent';
import { MenuSection } from '../MenuSection';

export const MyAccountSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isAuthenticated } = useAuthContext();
    const { preferences, patient } = usePatientContext();
    const { favoritePharmacies } = preferences;

    return (
        <ConditionalComponent condition={isAuthenticated}>
            <MenuSection label={t('sidebar.myAccount.heading')}>
                <SideNavLink
                    label={t('sidebar.myAccount.medCab')}
                    Icon={IconMedication}
                    onClick={() => navigate(getAbsoluteRoute('cabinet.root'))}
                />
                <SideNavLink
                    label={t('sidebar.myAccount.benefitCard')}
                    Icon={IconBadge}
                    onClick={() =>
                        navigate(getAbsoluteRoute('profile.benefit'))
                    }
                />
                <ConditionalComponent condition={!!patient?.hasPbmPlan}>
                    <SideNavLink
                        label={t('sidebar.myAccount.spendSummary')}
                        Icon={IconApprovalDelegation}
                        onClick={() =>
                            navigate(getAbsoluteRoute('plan.summary'))
                        }
                    />
                </ConditionalComponent>

                <ConditionalComponent
                    condition={
                        favoritePharmacies.favoriteLocationIds.length > 0
                    }
                >
                    <SideNavLink
                        label={t('sidebar.myAccount.favoritePharmacies')}
                        Icon={IconFavorite}
                        onClick={() =>
                            navigate(getAbsoluteRoute('pharmacy.favorites'))
                        }
                    />
                </ConditionalComponent>
            </MenuSection>
        </ConditionalComponent>
    );
};
