import { LoginCallback, LogoutRoute } from '@phx/auth/client';
import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import UnauthHomeRoute from './UnauthHomeRoute';

const authRoutes: RouteObject[] = [
    { index: true, element: <UnauthHomeRoute /> },
    {
        path: routes.loginCallback.root,
        element: <LoginCallback />,
    },
    {
        path: routes.logout,
        element: <LogoutRoute returnUrl={routes.root} />,
    },
];

export default authRoutes;
