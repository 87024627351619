import { useQuery } from '@apollo/client';
import { type ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

import {
    GetBasicPrescriberOrdersByPatientDocument,
    type GetBasicPrescriberOrdersByPatientQuery,
    GetBasicPrescriberOrdersDocument,
    type GetBasicPrescriberOrdersQuery,
    PrescriberOrderStatus,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';

import { MedicineCabinetPlaceholder } from './MedicineCabinetPlaceholder';

type QueryDocumentType =
    | typeof GetBasicPrescriberOrdersDocument
    | typeof GetBasicPrescriberOrdersByPatientDocument;

export type MedicineCabinetLoaderProps = {
    patientId: string;
    viewDependentPrescriptions?: boolean;
    Component: ComponentType<{
        patient:
            | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
            | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
        patientId: string;
    }>;
};

export const MedicineCabinetLoader = ({
    patientId,
    viewDependentPrescriptions,
    Component,
}: MedicineCabinetLoaderProps) => {
    const QueryDocument: QueryDocumentType = viewDependentPrescriptions
        ? GetBasicPrescriberOrdersDocument
        : GetBasicPrescriberOrdersByPatientDocument;

    const input = {
        statusFilter: [
            PrescriberOrderStatus.New,
            PrescriberOrderStatus.SentToPharmacy,
            PrescriberOrderStatus.Filled,
        ],
    };

    // @TODO - investigate preloading queries outside of react or with background and loadable query hooks
    // https://www.apollographql.com/docs/react/data/suspense#initiating-queries-outside-react
    //  preload medicine cabinet query
    // const [GetPrescriberOrders] = useLazyQuery(GetPrescriberOrdersDocument, {
    //     variables: {
    //         patientId: primaryPatientId,
    //         input,
    //     },
    //     errorPolicy: 'all',
    // });

    const { data, loading } = useQuery(QueryDocument, {
        variables: { patientId, input },
        errorPolicy: 'all',
    });

    if (loading) return <MedicineCabinetPlaceholder variant="outlined" />;

    if (!data?.patient) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return <Component patient={data.patient} patientId={patientId} />;
};
