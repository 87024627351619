import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    PriceQuoteInfoFragment,
} from '../../../generated/graphql';

export const isValidPatientCost = (
    quote?: Pick<
        | PriceQuoteInfoFragment
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment,
        'patientResponsibilityCost'
    >
) => !!quote && typeof quote?.patientResponsibilityCost === 'number';
