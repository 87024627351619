import { Box, Flex, HorizontalDivider, Subtitle1 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { MedicineCabinetInfoButton } from '../../../components/cabinet/MedicineCabinetInfoButton';

export const MedCabDetailsHeader = () => {
    const { t } = useTranslation();

    return (
        <Box>
            <Flex align="center">
                <Subtitle1>{t('cabinet.ordersCard.header')}</Subtitle1>
                <Box ml="xxxs">
                    <MedicineCabinetInfoButton />
                </Box>
            </Flex>
            <Box mt="xxxs">
                <HorizontalDivider />
            </Box>
        </Box>
    );
};
