import {
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    IconCheckCircle,
    Stack,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type FragmentsOf, getFragment } from '../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    GetOfferQuery,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { LocationInfo } from '../common/LocationInfo';
import { Pricing } from '../common/pricing';
import { Prescriber } from '../prescriber/Prescriber';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { NextSteps } from './components/NextSteps';
import { ShareWithPharmacy } from './components/ShareWithPharmacy';
import { YourPrescription } from './components/YourPrescription';

export type OrderConfirmationProps = {
    offer: NonNullable<FragmentsOf<GetOfferQuery['offer']>>;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const OrderConfirmation = ({
    offer,
    prescriberOrder,
}: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const fillLocation = getFragment(offer.pharmacyProvider);

    return (
        <Stack gap="xl" pb="md">
            <ShoppingHeader>
                <Stack gap="md">
                    <Stack align="center" gap="xxs">
                        <IconCheckCircle
                            filled
                            variant="rounded"
                            width="2em"
                            height="2em"
                            color="success"
                        />
                        <Heading1>{t('orderConfirmation.header')} </Heading1>
                    </Stack>
                    <NextSteps location={fillLocation} />
                </Stack>
            </ShoppingHeader>

            <HorizontalDivider variant="full" />

            <Stack gap="sm">
                <Heading2>{t('orderConfirmation.pharmacy.subHeader')}</Heading2>
                <ConditionalComponent condition={!!fillLocation}>
                    <LocationInfo location={fillLocation} />
                </ConditionalComponent>
            </Stack>

            <ShareWithPharmacy
                offer={offer}
                prescriberOrder={prescriberOrder}
            />

            <YourPrescription offer={offer} prescriberOrder={prescriberOrder} />

            <Pricing offer={offer} />

            <Heading2>{t('orderConfirmation.prescriber.subHeader')}</Heading2>
            <Prescriber prescriber={getFragment(prescriberOrder.prescriber)} />

            <HorizontalDivider variant="full" />

            <ContactUsWidget />

            <Button
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};

export default OrderConfirmation;
