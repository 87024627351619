import { Anchor, Body1, Modal, Stack, Subtitle2 } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

export const NoPricing = () => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Stack gap="xxs">
                <Stack gap="xxxs">
                    <Subtitle2>{t('noPrice.content.card')}</Subtitle2>

                    <Anchor onClick={open} display="flex">
                        <Body1>{t('noPrice.content.anchor')}</Body1>
                    </Anchor>
                </Stack>
            </Stack>
            <Modal
                title={t('noPrice.modal.title')}
                onClose={close}
                opened={opened}
            >
                <Body1>{t('noPrice.modal.body')}</Body1>
            </Modal>
        </>
    );
};
