import type { PriceQuoteInfoFragment } from '../../generated/graphql';

import { getFragment } from './get-fragment';

export const getLocationFragment = (
    location: PriceQuoteInfoFragment['location']
) => {
    return getFragment(getFragment(location));
};

export const getLocationInfoFragment = (
    location: PriceQuoteInfoFragment['location']
) => {
    return getFragment(location);
};
