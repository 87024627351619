import { fieldToLowerCase } from '../util';

import { readPackageFormatDescription } from './read-package-format-description';
import { readPackageQuantityOptions } from './read-package-quantity-options';

export const packageFields = {
    packageFormatDescription: {
        read: readPackageFormatDescription,
    },
    quantityOptions: {
        read: readPackageQuantityOptions,
    },
    packageSizeUnit: { read: fieldToLowerCase },
};
