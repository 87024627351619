import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const NAME_REGEX = /^([a-zA-Z0-9.\- "']|[à-ú]|[À-Ú])+$/;

export const useMergeAccountFormSchema = () => {
    const { t } = useTranslation();

    return z.object({
        firstName: z.string().trim().min(1).regex(NAME_REGEX),
        lastName: z.string().trim().min(1).regex(NAME_REGEX),
        day: z.string().min(1).max(2).regex(/^\d+$/),
        month: z.string().min(1).max(2).regex(/^\d+$/),
        year: z.string().length(4).regex(/^\d+$/),
        memberId: z
            .string()
            .trim()
            .min(1, {
                message: t('profile.verify.formErrors.memberId.required'),
            }),
    });
};
