import { useDobZodSchema, useNameZodSchema } from '@phx/design-system/hooks';
import { z } from 'zod';

export const useVerifyFormSchema = () => {
    return z.object({
        firstName: useNameZodSchema(),
        lastName: useNameZodSchema(),
        dob: useDobZodSchema(false),
        attest: z.boolean(),
    });
};
