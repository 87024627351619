import routes from '../routes/app-routes';

type PathKeys<
    O extends { [key: string]: unknown },
    S extends string | null = null,
    K extends keyof O = keyof O,
> = K extends string
    ? O[K] extends { [key: string]: unknown }
        ? S extends null
            ? PathKeys<O[K], K>
            : PathKeys<O[K], `${S}.${K}`>
        : O[K] extends string
          ? S extends null
              ? K
              : `${S}.${K}`
          : never
    : never;

type RoutePathKey = PathKeys<typeof routes>;

export const getAbsoluteRoute = (
    routeKey: RoutePathKey,
    params: { [key: string]: string } = {}
): string => {
    const parts = routeKey.split('.');

    const sections = [];
    let objPart = routes;
    for (const part of parts) {
        if (part !== 'root') {
            /* @ts-expect-error -- this is a funky operation with weird type recursion and other shenanigans, and it makes TypeScript choke */
            const routePart = objPart[part];
            if (typeof routePart === 'string') {
                sections.push(routePart);
            } else {
                sections.push(routePart.root);
            }
        }

        /* @ts-expect-error -- see above */
        objPart = objPart[part];
    }

    let finalUrl = `/${sections.join('/')}`;

    Object.entries(params).forEach(([token, replace]) => {
        finalUrl = finalUrl.replace(`:${token}`, replace);
    });

    return finalUrl;
};
