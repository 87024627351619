import {
    Anchor,
    Body1,
    Button,
    Flex,
    Heading1,
    Modal,
    Stack,
    Subtitle2,
    formatPhoneNumber,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { ClaimDeniedIllustration } from '@phx/myphx-lib';
import { TalkativeWidget } from '@phx/talkative';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type { ClaimInfoFragment } from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';

export type ClaimReversedProps = {
    claim: ClaimInfoFragment;
};

export const ClaimReversed = ({ claim }: ClaimReversedProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    const fill = getFragment(claim.fill);
    const product = getFragment(fill.product);
    const pharmacy = getFragment(getFragment(fill.location));

    const drugName = product.name;
    const pharmacyName = pharmacy?.name ?? '';
    const pharmacyPhoneNumber = pharmacy?.phoneNumber ?? '';

    return (
        <Stack justify="center">
            <Stack justify="center" align="center">
                <ClaimDeniedIllustration size="xl" />
                <Heading1>{t('claim.reversed.title')}</Heading1>
            </Stack>
            <Trans
                i18nKey="claim.reversed.subtitle"
                values={{ drugName, pharmacyName }}
            >
                <Body1 ta="center" data-testid="claim-reversal-error-component">
                    {pharmacyName} reversed your prescription claim for{' '}
                    {drugName}. Contact the pharmacy for an update on your
                    prescription. <Anchor onClick={open}>Learn More</Anchor>
                </Body1>
            </Trans>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    window.open(
                        `tel:${formatPhoneNumber(pharmacyPhoneNumber)}`
                    );
                }}
            >
                {t('claim.reversed.callPharmacy')}
            </Button>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
            <Modal
                title={t('claim.reversed.modalTitle')}
                onClose={close}
                opened={opened}
            >
                {LearnMoreModalContent()}
            </Modal>
            <Flex justify="center">
                <TalkativeWidget />
            </Flex>
        </Stack>
    );
};

const LearnMoreModalContent = () => {
    const { t } = useTranslation();
    return (
        <Stack gap="md">
            <Subtitle2>
                {t('claim.reversed.modal.prescriptionClaimTitle')}
            </Subtitle2>
            <Body1>{t('claim.reversed.modal.prescriptionClaimSubtitle')}</Body1>
            <Subtitle2>
                {t('claim.reversed.modal.claimReversedTitle')}
            </Subtitle2>
            <Body1>{t('claim.reversed.modal.claimReversedSubtitle')}</Body1>
            <Subtitle2> {t('claim.reversed.modal.readyTitle')}</Subtitle2>
            <Body1>{t('claim.reversed.modal.readySubtitle')}</Body1>
        </Stack>
    );
};
