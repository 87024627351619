import { useMutation } from '@apollo/client';
import {
    Flex,
    Heading1,
    IconDownload,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    GenerateClaimPdfDocument,
    type GetClaimHistoryQuery,
} from '../../graphql/generated/graphql';

import ClaimDetails from './claim-details/ClaimDetails';
import { NoClaims } from './no-claims/NoClaims';

type ClaimHistoryProps = {
    data: GetClaimHistoryQuery;
};

export const ClaimHistory = ({ data }: ClaimHistoryProps) => {
    const { t } = useTranslation();

    const { patient } = data;
    const claims = patient?.claimsConnection.edges;

    const [
        generateClaimHistoryPdf,
        { data: generateClaimPdfData, loading: isClaimDownloadLoading },
    ] = useMutation(GenerateClaimPdfDocument);

    useEffect(() => {
        if (patient?.id) {
            void generateClaimHistoryPdf({
                variables: { patientId: patient.id },
            });
        }
    }, [patient?.id]);

    return (
        <Stack gap="md">
            <Flex justify="space-between" align="center">
                <Heading1>{t('claimHistory.title')}</Heading1>
                {claims?.length && !isClaimDownloadLoading ? (
                    <TextButton
                        LeftIcon={IconDownload}
                        onClick={() =>
                            window.open(
                                generateClaimPdfData?.generateClaimHistoryFile
                                    .downloadUrl
                            )
                        }
                    >
                        {t('claimHistory.download')}
                    </TextButton>
                ) : null}
            </Flex>
            {claims?.length ? (
                <Stack gap="md">
                    {claims?.map((claim) => {
                        return (
                            <ClaimDetails
                                key={claim.node.id}
                                claim={claim.node}
                            />
                        );
                    })}
                </Stack>
            ) : (
                <NoClaims />
            )}
        </Stack>
    );
};
