import { useQuery } from '@apollo/client';
import { Stack } from '@phx/design-system';
import { useDebouncedState } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { getFragments } from '../../graphql';
import { DrugFamilySearchDocument } from '../../graphql/generated/graphql';

import { DrugSearchBar } from './DrugSearchBar';
import { DrugSearchResults } from './DrugSearchResults';

type DrugSearchProps = {
    onDrugSelect: (drugId: string, searchParams?: string) => void;
    onFocus?: () => void;
    shouldAutoFocus?: boolean;
};

export const DrugSearch = ({
    onFocus,
    onDrugSelect,
    shouldAutoFocus,
}: DrugSearchProps) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const paramKey = 'q';

    const [searchInput, setSearchInput] = useDebouncedState(
        searchParams.get(paramKey) ?? '',
        250
    );

    const isValidInputLength = searchInput.length > 2;
    const { data, loading } = useQuery(DrugFamilySearchDocument, {
        variables: {
            searchParams: { name: { contains: searchInput } },
        },
        errorPolicy: 'all',
        skip: !isValidInputLength,
    });

    const onDrugSearch = (input: string) => {
        setSearchParams({ [paramKey]: input }, { replace: true });
        setSearchInput(input);
    };

    const handleDrugSelect = (drugId: string, searchParams?: string) => {
        const params = createSearchParams(searchParams);
        params.set('q', searchInput);
        onDrugSelect(drugId, params.toString());
    };
    const results = getFragments(data?.drugFamilySearch);
    const showResults = !loading && isValidInputLength;

    return (
        <Stack gap="lg">
            <DrugSearchBar
                onFocus={onFocus}
                autoFocus={shouldAutoFocus}
                label={t('drugSearch.searchLabel')}
                queryValue={searchParams.get(paramKey)}
                onChange={onDrugSearch}
            />
            {showResults ? (
                <DrugSearchResults
                    results={results ?? []}
                    searchInput={searchInput}
                    onDrugSelect={handleDrugSelect}
                />
            ) : null}
        </Stack>
    );
};
