import { HorizontalDivider, NavLink } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCoverageCardData } from '../../hooks/use-coverage-card-data';
import { getAbsoluteRoute } from '../../util';

export const ViewSpendSummarySection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const coverages = useCoverageCardData();

    const hasBenefitPlan = coverages.some(
        (coverage) => coverage.variant === 'benefit'
    );

    const viewSpendSummary = useCallback(() => {
        navigate(getAbsoluteRoute('plan.summary'));
    }, [navigate]);

    if (!hasBenefitPlan) {
        return null;
    }

    return (
        <>
            <HorizontalDivider />
            <NavLink
                label={t('planCards.benefit.viewSpendSummary')}
                onClick={viewSpendSummary}
                variant="subtle"
                data-testid="view-spend-summary-button"
            />
        </>
    );
};
