import { SearchInput, type TextInputProps } from '@phx/design-system';
import { useState } from 'react';

export type DrugSearchBarProps = {
    autoFocus?: boolean;
    onFocus?: TextInputProps['onFocus'];
    label?: string | null;
    queryValue?: string | null;
    onChange?: (value: string) => void;
};

export const DrugSearchBar = ({
    label,
    onChange,
    queryValue,
    onFocus,
    autoFocus = false,
}: DrugSearchBarProps) => {
    const [value, setValue] = useState('');

    const handleInputChange = (input: string) => {
        onChange && onChange(input);
        setValue(input);
    };

    const textValue = queryValue ? queryValue : value;

    return (
        <SearchInput
            autoFocus={autoFocus}
            onFocus={onFocus}
            data-testid="search-bar"
            label={label}
            value={textValue}
            onChange={(e) => handleInputChange(e.target.value)}
            clear={() => handleInputChange('')}
        />
    );
};
