import { Heading2, Radio, RadioGroup, Stack } from '@phx/design-system';

type FilterRadioGroupProps = {
    title: string;
    selection?: string;
    onChange: (value: string) => void;
    options: Array<{ label: string; value: string }>;
};

const FilterRadioGroup = ({
    title,
    selection,
    onChange,
    options,
}: FilterRadioGroupProps) => {
    return (
        <Stack gap="sm">
            <Heading2>{title}</Heading2>
            <RadioGroup
                value={selection}
                onChange={(e) => {
                    onChange(e);
                }}
            >
                <Stack>
                    {options.map(({ label, value }) => (
                        <Radio key={value} value={value} label={label} />
                    ))}
                </Stack>
            </RadioGroup>
        </Stack>
    );
};

export default FilterRadioGroup;
