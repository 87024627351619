import { Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const CancelledDescription = () => (
    <Trans
        i18nKey="priorAuthorization.statusPage.cancelled"
        components={{
            2: <Anchor onClick={openTalkativeUI} />,
        }}
    />
);
