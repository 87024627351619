import { Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { ContactUsWidget } from '../../components/common/ContactUsWidget';
import { ViewSpendSummarySection } from '../../components/plan-cards/ViewSpendSummarySection';
import { AskPrescriberCard } from '../../components/profile/AskPrescriberCard';
import { JoinEmployerPlanCard } from '../../components/profile/JoinEmployerPlanCard';
import { PlanCardsSection } from '../../components/profile/PlanCardsSection';
import { useFlags } from '../../hooks/use-flags';
import { useProfile } from '../../hooks/use-profile';

const ProfileBenefitsRoute = () => {
    const { t } = useTranslation();
    const { joinEmployerPlanCardEnabled } = useFlags();
    const { patient } = useProfile();
    const isMobile = useIsMobile();

    const showJoinEmployerPlanSection =
        joinEmployerPlanCardEnabled && !patient.hasPbmPlan;

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                <Heading1>{t('profile.benefits.header')}</Heading1>
                <PlanCardsSection />
            </Stack>
            {isMobile ? null : <ViewSpendSummarySection />}
            <HorizontalDivider />
            {showJoinEmployerPlanSection && (
                <>
                    <JoinEmployerPlanCard />
                    <HorizontalDivider />
                </>
            )}
            <AskPrescriberCard />
            <HorizontalDivider />
            <ContactUsWidget />
        </Stack>
    );
};

export default ProfileBenefitsRoute;
