export const getInitialsFromFullName = (name?: string) => {
    return name
        ? name
              .trim()
              .split(' ')
              .map((a: string) => a[0])
              .join('')
              .toUpperCase()
        : '';
};
