import {
    Body2,
    Flex,
    Heading1,
    IconGenericPharmacy,
    IconLock,
    IconPaid,
    OutlinedCard,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

export const HealthcareTechEmpowers = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile('md');

    return (
        <Stack gap="lg" py="xxxl">
            <Heading1>{t('unauth.healthTechEmpowers.header')}</Heading1>
            <Flex direction={isMobile ? 'column' : 'row'} gap="sm">
                <OutlinedCard
                    Icon={IconPaid}
                    iconBackground="secondary"
                    iconColor="secondary"
                >
                    <Stack gap="xxxs">
                        <Subtitle1>
                            {t('unauth.healthTechEmpowers.freeSubtitle')}
                        </Subtitle1>
                        <Body2>{t('unauth.healthTechEmpowers.freeBody')}</Body2>
                    </Stack>
                </OutlinedCard>
                <OutlinedCard
                    Icon={IconGenericPharmacy}
                    iconBackground="secondary"
                    iconColor="secondary"
                >
                    <Stack gap="xxxs">
                        <Subtitle1>
                            {t('unauth.healthTechEmpowers.pharmaciesSubtitle')}
                        </Subtitle1>
                        <Body2>
                            {t('unauth.healthTechEmpowers.pharmaciesBody')}
                        </Body2>
                    </Stack>
                </OutlinedCard>
                <OutlinedCard
                    Icon={IconLock}
                    iconBackground="secondary"
                    iconColor="secondary"
                >
                    <Stack gap="xxxs">
                        <Subtitle1>
                            {t('unauth.healthTechEmpowers.dataSubtitle')}
                        </Subtitle1>
                        <Body2>{t('unauth.healthTechEmpowers.dataBody')}</Body2>
                    </Stack>
                </OutlinedCard>
            </Flex>
        </Stack>
    );
};
