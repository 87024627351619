import {
    Box,
    Checkbox,
    Flex,
    FormFieldError,
    Stack,
    TextInput,
} from '@phx/design-system';
import { type FormErrors } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { type VerifyFormDetails, useVerifyFormContext } from './verify-form';

export type VerifyFormProps = {
    onError?: (errors: FormErrors<VerifyFormDetails>) => void;
    onSubmit: (data: VerifyFormDetails) => Promise<void>;
};

export const VerifyForm = (props: VerifyFormProps) => {
    const { onSubmit, onError } = props;
    const { t } = useTranslation();
    const form = useVerifyFormContext();

    // These errors get aggregated under one "name" error
    const { error: _firstNameError, ...firstNameProps } =
        form.getInputProps('firstName');
    const { error: _lastNameError, ...lastNameProps } =
        form.getInputProps('lastName');

    return (
        <form
            onSubmit={form.onSubmit(onSubmit, onError)}
            id="verify-prescription"
        >
            <Stack gap="md">
                <Stack gap="xxs">
                    <Flex gap="sm">
                        <Box flex={1}>
                            <TextInput
                                key={form.key('firstName')}
                                name="firstName"
                                label={t('common.formFields.firstName')}
                                error={!!form.errors.name}
                                required
                                {...firstNameProps}
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                key={form.key('lastName')}
                                name="lastName"
                                label={t('common.formFields.lastName')}
                                error={!!form.errors.name}
                                required
                                {...lastNameProps}
                            />
                        </Box>
                    </Flex>
                    {form.errors.name && (
                        <FormFieldError errText={form.errors.name} />
                    )}
                </Stack>
                <TextInput
                    key={form.key('dob')}
                    label={t('common.formFields.dob')}
                    name="dob"
                    placeholder="mm/dd/yyyy"
                    mask="00/00/0000"
                    required
                    {...form.getInputProps('dob')}
                />
                <Box>
                    <Checkbox
                        label={t('verifyPrescription.formFields.attest')}
                        {...form.getInputProps('attest')}
                    />
                </Box>
            </Stack>
        </form>
    );
};
