import { Anchor, Body1, Box, Flex, IconSupportAgent } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { useTranslation } from 'react-i18next';

export const ErrorWidget = () => {
    const { t } = useTranslation();
    return (
        <Flex gap="xxs">
            <IconSupportAgent color="secondary" />
            <Box>
                <Body1 inline>{t('error.persistText')}</Body1>
                <Anchor onClick={openTalkativeUI}>
                    {t('error.contactUs')}
                </Anchor>
                .
            </Box>
        </Flex>
    );
};
