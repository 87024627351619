import { Body1, Button, Modal, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const LeavePrescriptionWarningModal = ({
    isOpen,
    close,
    onLeaveClick,
}: {
    isOpen: boolean;
    close: () => void;
    onLeaveClick: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={close}
            title={t('cabinet.leaveWarningModal.title')}
        >
            <Stack gap="sm">
                <Body1>{t('cabinet.leaveWarningModal.text1')}</Body1>
                <Body1>{t('cabinet.leaveWarningModal.text2')}</Body1>
                <Button onClick={close}>
                    {t('cabinet.leaveWarningModal.stayBtn')}
                </Button>
                <Button onClick={onLeaveClick} variant="outline">
                    {t('cabinet.leaveWarningModal.leaveBtn')}
                </Button>
            </Stack>
        </Modal>
    );
};
