import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';
import { isValidPatientCost } from '../../graphql/policies/util';

export function getCheapestPatientCostOffer(
    offers: (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
) {
    return offers
        .filter((offer) => isValidPatientCost(offer))
        .reduce(
            (lowest, current) =>
                !lowest ||
                (current.patientResponsibilityCost ?? 0) <
                    (lowest.patientResponsibilityCost ?? 0)
                    ? current
                    : lowest,
            undefined as
                | CashOfferInfoFragment
                | InsuranceOfferInfoFragment
                | undefined
        );
}
