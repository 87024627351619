export interface Address {
    line1: string;
    line2?: string | null;
    city: string;
    zipCode: string;
    state: string;
    country?: string | null;
}

export function formatAddress(address?: Address | null) {
    const formattedAddress = [address?.line1, address?.line2]
        .filter(Boolean)
        .join(' ');

    return `${formattedAddress}, ${address?.city}, ${address?.state} ${address?.zipCode}`.trim();
}
