import { useMutation } from '@apollo/client';
import { useAuthContext } from '@phx/auth/client';
import {
    Body1,
    Heading1,
    Radio,
    RadioGroup,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Language,
    SetPreferredLanguageDocument,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    updateStoredPreferences,
    useUserPreferences,
} from '../../hooks/use-user-preferences';
import { getConfirmedLanguage, languageMapping } from '../../util/i18n';

const getLanguageFromValue = (value: string) => {
    switch (value) {
        case 'en': {
            return Language.En;
        }
        case 'es': {
            return Language.Es;
        }
        default: {
            return Language.En;
        }
    }
};

const createLabel = (label: string, value: string) => (
    <Stack gap={0} align="flex-start">
        <Subtitle2>{label}</Subtitle2>
        <Body1>{value}</Body1>
    </Stack>
);

const SelectLanguageRoute = () => {
    const { t, i18n } = useTranslation();
    const { language } = useUserPreferences();
    const { isAuthenticated } = useAuthContext();

    const [lang, setLanguage] = useState(language);
    const { primaryPatientId } = usePatientContext();

    const [SetPatientPreferredLanguage] = useMutation(
        SetPreferredLanguageDocument
    );

    const handleChange = (value: string) => {
        if (isAuthenticated) {
            const preferredLanguage = getLanguageFromValue(value);

            SetPatientPreferredLanguage({
                variables: {
                    patientId: primaryPatientId,
                    preferredLanguage,
                },
            });
        }

        const newLanguage = getConfirmedLanguage(value);

        updateStoredPreferences({ language: newLanguage });

        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    };

    return (
        <Stack gap="lg">
            <Heading1>{t('selectLanguagePage.title')}</Heading1>
            <Stack gap="sm">
                <RadioGroup value={lang} onChange={handleChange}>
                    <Stack gap="sm">
                        {Array.from(languageMapping).map(([key, value]) => (
                            <Radio
                                key={key}
                                label={createLabel(value.native, value.name)}
                                value={key}
                                data-testid={`language-form-control-radio-${key}`}
                            />
                        ))}
                    </Stack>
                </RadioGroup>
            </Stack>
        </Stack>
    );
};

export default SelectLanguageRoute;
