import {
    Body1,
    DefinitionList,
    type DefinitionListProps,
    formatPhoneNumber,
} from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import { useProfile } from '../../hooks/use-profile';
import { formatToLocalizedDate } from '../../util';
import { getMemberId } from '../../util/patient/get-member-id';

export const UserProfile = () => {
    const { t } = useTranslation();
    const { profile } = useProfile();

    const patient = getFragment(profile);

    const profileData = useMemo(() => {
        const items: DefinitionListProps['items'] = [];

        items.push({
            title: t('member.memberId'),
            body: <Body1>{getMemberId(patient)}</Body1>,
            key: 'primary-user-member-id',
        });

        if (patient.email) {
            items.push({
                title: t('member.email'),
                body: <Body1>{patient.email}</Body1>,
                key: 'primary-user-email',
            });
        }

        items.push({
            title: t('member.dateOfBirth'),
            body: (
                <Body1>
                    {formatToLocalizedDate(new Date(patient.dateOfBirth), {
                        dateStyle: 'medium',
                    })}
                </Body1>
            ),
            key: 'primary-user-date-of-birth',
        });

        items.push({
            title: t('member.mobilePhone'),
            body: <Body1>{formatPhoneNumber(patient.phoneNumber ?? '')}</Body1>,
            key: 'primary-user-phone-number',
        });

        if (patient.nicknames.length > 0) {
            items.push({
                title: t('member.alternateNames'),
                body: (
                    <Body1>
                        {patient.nicknames
                            .map((x) => x.fullName)
                            .join(` ${constants.unicode.BULLET} `)}
                    </Body1>
                ),
                key: 'primary-user-alternate-names',
            });
        }

        return items;
    }, [patient]);

    return <DefinitionList items={profileData} />;
};
