import { type DSBreakpoint, useIsMobile } from '@phx/design-system/hooks';

export const useSwipeableViews = (
    items: unknown,
    breakpoint: DSBreakpoint = 'sm'
) => {
    const isMobile = useIsMobile(breakpoint);

    if (!Array.isArray(items)) {
        return false;
    }

    if (items.length <= 1) {
        return false;
    }

    return isMobile;
};
