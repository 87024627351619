import { useQuery } from '@apollo/client';
import { KnownErrorCode } from '@phx/instrumentation/react';
import { Navigate, useParams } from 'react-router-dom';

import { Loader } from '../../components/common/Loader';
import { GetClaimV2Document } from '../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';
import { getErrorCode } from '../../util/get-error-code';
import { usePrescriptionInputVariables } from '../cabinet/prescription/use-prescription-variables';

import { Claims } from './components/Claims';

const ClaimsRoute = () => {
    const { claimId = '' } = useParams();
    const { scopedPatientId } = usePatientContext();

    const distance = useNumericSearchParam('within');
    const input = usePrescriptionInputVariables(distance, scopedPatientId);

    const { data, loading, error } = useQuery(GetClaimV2Document, {
        variables: {
            claimId,
            input,
        },
        skip: !claimId,
    });

    if (!claimId) return <Navigate to={getAbsoluteRoute('error')} />;

    if (loading) return <Loader />;

    if (getErrorCode(error) === KnownErrorCode.Forbidden) {
        return <Navigate to="/forbidden" />;
    }

    return <Claims data={data} />;
};

export default ClaimsRoute;
