import {
    Anchor,
    Body1,
    OutlinedCard,
    Stack,
    Timeline,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import { formatAddress } from '../../../util/formatting/address/address';
import type { VerifyPrescriptionTransferProps } from '../VerifyPrescriptionTransfer';

export const ProviderTransferTimeline = ({
    from,
    to,
}: Pick<VerifyPrescriptionTransferProps, 'from' | 'to'>) => {
    const { t } = useTranslation();

    const getSubtitle = (provider: typeof from | typeof to) => {
        let details;

        switch (provider?.__typename) {
            case 'MailOrderProvider':
                details = provider?.url ? (
                    <Anchor href={provider.url} target="_blank">
                        {provider.url}
                    </Anchor>
                ) : (
                    <Body1>{provider.phoneNumber ?? ''}</Body1>
                );
                break;

            case 'Pharmacy':
            case 'ChainPharmacy':
            case 'PharmacyProvider': {
                const address = getFragment(provider.address);

                details = (
                    <Body1>
                        {t('verify.transfer.details', {
                            details: formatAddress(address),
                        })}
                    </Body1>
                );
                break;
            }

            default:
                return null;
        }

        return (
            <Stack gap="xxs" data-testid="transfer-details">
                <Stack>
                    <Body1>
                        {t('verify.transfer.name', { name: provider.name })}
                    </Body1>
                </Stack>
                {details}
            </Stack>
        );
    };

    return (
        <OutlinedCard>
            <Timeline
                activeStep={0}
                steps={[
                    {
                        title: t('verify.transfer.from'),
                        subtitle: getSubtitle(from) ?? '',
                    },
                    {
                        title: t('verify.transfer.to'),
                        subtitle: getSubtitle(to) ?? '',
                    },
                ]}
            />
        </OutlinedCard>
    );
};
