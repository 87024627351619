import { Box, Button, Flex, Subtitle2 } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useFlags } from '@phx/feature-flags/client';
import { MedFormIcon } from '@phx/myphx-lib';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { getAbsoluteRoute } from '../../../util';
import {
    type BasicPrescriberOrderFragment,
    getMedicationDescription,
    getOrderQuantity,
    getPrescribedProductFragment,
    isUnclaimedPrescriberOrder,
} from '../../../util/cabinet/prescriber-orders';
import DrugDetails from '../../common/DrugDetails';

import { MedicationOrderDetails } from './MedicationOrderDetails';
import { UnclaimedOrderInfoModal } from './UnclaimedOrderInfoModal';

export type MedicationOrderProps = {
    order: BasicPrescriberOrderFragment;
};

export const MedicationOrder = ({ order }: MedicationOrderProps) => {
    const { drugDetailsSplitEnabled, priorAuthorizationEnabled } = useFlags();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [
        showUnclaimedModal,
        { open: openUnclaimedModal, close: closeUnclaimedModal },
    ] = useDisclosure(false);

    const digitallyPrescribedProductId = order.drxId ?? order.id;

    const fillOrder = useCallback(() => {
        navigate(
            getAbsoluteRoute('cabinet.rxId.root', {
                prescriptionId: digitallyPrescribedProductId,
            })
        );
    }, [order, navigate]);

    const verifyOrder = useCallback(() => {
        navigate(
            getAbsoluteRoute('cabinet.rxId.verifyPatient', {
                prescriptionId: digitallyPrescribedProductId,
            })
        );
    }, [order, navigate]);

    const prescribedProduct = getFragment(getPrescribedProductFragment(order));
    const isUnclaimed = isUnclaimedPrescriberOrder(order);
    const description = getMedicationDescription(order);

    const clickHandler = useMemo(() => {
        if (isUnclaimed) {
            return openUnclaimedModal;
        }

        if (digitallyPrescribedProductId) {
            return () =>
                navigate(
                    getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: digitallyPrescribedProductId,
                    }),
                    { state: { prev: 'history' } }
                );
        }

        return undefined;
    }, [digitallyPrescribedProductId, isUnclaimed, openUnclaimedModal]);

    if (!description) {
        return null;
    }

    return (
        <Flex gap="xs" justify="space-between" align="start" role="listitem">
            <Flex gap="xs">
                <MedFormIcon form={prescribedProduct?.formValue} />
                <Box
                    style={{ cursor: clickHandler ? 'pointer' : 'auto' }}
                    onClick={clickHandler}
                >
                    <Box data-testid="medication-entry-medication-description">
                        <Subtitle2>
                            {drugDetailsSplitEnabled
                                ? prescribedProduct?.name
                                : description}
                        </Subtitle2>
                    </Box>
                    {drugDetailsSplitEnabled && prescribedProduct ? (
                        <DrugDetails
                            product={prescribedProduct}
                            quantity={getOrderQuantity(order)}
                        />
                    ) : null}
                    <MedicationOrderDetails order={order} />
                </Box>
            </Flex>
            <Box>
                {isUnclaimed ? (
                    <Button
                        variant="outline"
                        onClick={verifyOrder}
                        size="sm"
                        data-testid="medication-entry-verify-button"
                    >
                        {t('cabinet.orders.verify')}
                    </Button>
                ) : order.__typename === 'DigitallyReceivedPrescriberOrder' ? (
                    priorAuthorizationEnabled &&
                    order?.applicablePriorAuthorization ? null : (
                        <Button
                            variant="contained"
                            onClick={fillOrder}
                            size="sm"
                            data-testid="medication-entry-fill-button"
                        >
                            {t('cabinet.orders.fill')}
                        </Button>
                    )
                ) : null}
            </Box>
            <UnclaimedOrderInfoModal
                onClose={closeUnclaimedModal}
                verifyOrder={verifyOrder}
                visible={showUnclaimedModal}
            />
        </Flex>
    );
};
