import { Cover, Loader } from '@phx/design-system';
import { type PropsWithChildren as PWC, Suspense } from 'react';

export const RootSuspenseBoundary = ({ children }: PWC) => (
    <Suspense
        fallback={
            <Cover visible>
                <Loader />
            </Cover>
        }
    >
        {children}
    </Suspense>
);
