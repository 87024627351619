import { Body1, Button, Modal, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type UnclaimedOrderInfoModalProps = {
    onClose: () => void;
    verifyOrder: () => void;
    visible: boolean;
};

export const UnclaimedOrderInfoModal = ({
    onClose,
    verifyOrder,
    visible,
}: UnclaimedOrderInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={visible}
            onClose={onClose}
            title={t('cabinet.orders.unclaimedModal.title')}
        >
            <Stack gap="lg">
                <Body1>{t('cabinet.orders.unclaimedModal.body')}</Body1>
                <Button variant="filled" onClick={verifyOrder} size="lg">
                    {t('cabinet.orders.verify')}
                </Button>
            </Stack>
        </Modal>
    );
};
