import { locationField } from './location';
import { offerField } from './offer';
import { prescriberOrder } from './prescriber-order';

export const cacheRedirects = {
    prescriberOrder: {
        read: prescriberOrder,
    },
    location: {
        read: locationField,
    },
    offer: {
        read: offerField,
    },
};
