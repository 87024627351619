import { SpendSummaryCard } from '@phx/myphx-lib';

import { getFragment } from '../../../graphql';
import type { PlanSummaryProps } from '../PlanSummary';

type FamilySummaryCardProps = {
    coverages: PlanSummaryProps['coverageEdges'];
};

export const FamilySummaryCard = ({ coverages }: FamilySummaryCardProps) => {
    return coverages.map((coverage, id) =>
        coverage.familyAccum ? (
            <SpendSummaryCard
                key={`family-accumulator-card-${id}`}
                data-testid={`family-accumulator-card-${id}`}
                variant="family"
                deductible={{
                    max: coverage.familyDeductibleMax ?? 0,
                    spent: getFragment(coverage.familyAccum).towardsDeductible,
                }}
                outOfPocket={{
                    max: coverage.familyOutOfPocketMax ?? 0,
                    spent: getFragment(coverage.familyAccum)
                        .towardsOutOfPocketMax,
                }}
            />
        ) : null
    );
};
