import {
    Body1,
    Box,
    IconChevronRight,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { IllustrationCard, PrescriptionIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const HomeCabinetNoOrdersCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <IllustrationCard
            Illustration={PrescriptionIllustration}
            illustrationPosition="right"
            variant="outlined"
            color="gray"
            data-testid="medicine-cabinet-card-empty"
        >
            <Stack gap="xs">
                <Subtitle1>{t('cabinet.noOrdersCard.header')}</Subtitle1>
                <Body1>{t('cabinet.noOrdersCard.body')}</Body1>
                <Box>
                    <TextButton
                        onClick={() =>
                            navigate(getAbsoluteRoute('startSaving'))
                        }
                        RightIcon={IconChevronRight}
                        data-testid="empty-medicine-cabinet-card-manage-button"
                    >
                        {t('cabinet.noOrdersCard.button')}
                    </TextButton>
                </Box>
            </Stack>
        </IllustrationCard>
    );
};
