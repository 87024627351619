import type { FieldReadFunction } from '@apollo/client';

export const offerField: FieldReadFunction = (
    existingDataFromCache,
    { args, toReference }
) => {
    return (
        existingDataFromCache ||
        toReference({
            __typename: 'Offer',
            id: args?.id,
        })
    );
};
