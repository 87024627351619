import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import ForbiddenErrorRoute from './ForbiddenErrorRoute';
import RootErrorRoute from './RootErrorRoute';

const errorRoutes: RouteObject[] = [
    {
        path: routes.error,
        element: <RootErrorRoute />,
    },
    {
        path: routes.forbidden,
        element: <ForbiddenErrorRoute />,
    },
    {
        path: routes.notFound,
        element: <div>/not-found</div>,
    },
];

export default errorRoutes;
