import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Loader } from '../../components/common/Loader';
import { VerifyPrescriptionTransfer } from '../../components/transfer-flow/VerifyPrescriptionTransfer';
import { PatientContext } from '../../contexts/patient/PatientProvider';
import { getFragment, getFragments } from '../../graphql';
import {
    GetPharmacyProviderDocument,
    GetProviderOffersDocument,
    type PharmacyProvider,
    RequestPrescriptionTransferDocument,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { addressToAddressString } from '../../util/address-utils';

export const VerifyTransferRoute = () => {
    const navigate = useNavigate();

    const { patient } = useContext(PatientContext);
    const { locationId, offerId, providerOffersId } = useParams();

    if (!patient || !locationId || !providerOffersId || !offerId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const { data, loading } = useQuery(GetProviderOffersDocument, {
        variables: { id: providerOffersId },
    });

    const { data: toLocationData, loading: toLocationLoading } = useQuery(
        GetPharmacyProviderDocument,
        {
            variables: { id: locationId },
        }
    );

    const [RequestPrescriptionTransfer, { loading: transferLoading }] =
        useMutation(RequestPrescriptionTransferDocument);

    if (loading || toLocationLoading) {
        return <Loader />;
    }

    const providerOffers = getFragment(data?.providerOffers);
    const selectedOffer = getFragments(providerOffers?.offers)?.find(
        (offer) => offer.id === offerId
    );
    const fromLocation = getFragment(toLocationData?.pharmacyProvider);

    if (!selectedOffer || !providerOffers || !fromLocation) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }
    const product = getFragment(providerOffers.product);
    const toLocation = getFragment(selectedOffer.pharmacyProvider);

    return (
        <VerifyPrescriptionTransfer
            from={fromLocation}
            to={toLocation}
            offer={selectedOffer}
            transferLoading={transferLoading}
            onTransferPrescription={(prescriptionId) => {
                RequestPrescriptionTransfer({
                    variables: {
                        sourcePharmacy: {
                            name: fromLocation.name,
                            address:
                                fromLocation.__typename === 'MailOrderProvider'
                                    ? 'Mail Order'
                                    : addressToAddressString(
                                          (fromLocation as PharmacyProvider)
                                              .address
                                      ),
                            phone: fromLocation.phoneNumber ?? 'Unknown',
                            fax: 'TODO',
                        },
                        destinationPharmacy: {
                            name: toLocation.name,
                            address:
                                toLocation.__typename === 'MailOrderProvider'
                                    ? 'Mail Order'
                                    : addressToAddressString(
                                          (toLocation as PharmacyProvider)
                                              .address
                                      ),
                            phone: toLocation.phoneNumber ?? 'Unknown',
                            fax: 'TODO',
                        },
                        prescription: {
                            name: product.name,
                            ndc: product.id,
                            strength: `${product.strengthText}${product.strengthUnit?.toLowerCase()}`,
                            prescriptionNumber: prescriptionId,
                        },
                        patient: {
                            id: patient.id,
                            name: patient?.fullName ?? 'Unknown',
                            dob: patient?.dateOfBirth ?? 'Unknown',
                            address: 'Unknown',
                            phone: patient?.phoneNumber ?? 'Unknown',
                        },
                    },
                    onCompleted: (data) => {
                        if (data.requestPrescriptionTransfer.success) {
                            navigate(
                                getAbsoluteRoute(
                                    'transfer.location.verify.submitted',
                                    {
                                        providerOffersId: providerOffersId,
                                        offerId: offerId,
                                        locationId: locationId,
                                    }
                                ),
                                {
                                    state: {
                                        prescriptionId,
                                        offer: selectedOffer,
                                    },
                                }
                            );
                        }
                    },
                });
            }}
        />
    );
};
