import { useMutation } from '@apollo/client';
import {
    Alert,
    Body1,
    Button,
    FullBleed,
    Heading1,
    Overlay,
    Stack,
    notifications,
} from '@phx/design-system';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    type MergeAccountFormDetails,
    MergeAccountFormProvider,
    useMergeAccountForm,
    useMergeAccountFormSchema,
} from '../../components/account/merge-account-form';
import { MergeAccountsForm } from '../../components/account/MergeAccountForm';
import { MergeAccountsDocument } from '../../graphql/generated/graphql';
import { useProfile } from '../../hooks/use-profile';
import { getAbsoluteRoute } from '../../util';

const ProfileVerifyRoute = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [submissionError, setSubmissionError] = useState<string | null>(null);

    const { patient } = useProfile();
    const patientDate = patient.dateOfBirth.split('-');

    const schema = useMergeAccountFormSchema();
    const form = useMergeAccountForm({
        initialValues: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            day: patientDate[2],
            month: patientDate[1],
            year: patientDate[0],
            memberId: '',
        },
        schema,
        validateInputOnBlur: true,
        onValuesChange: () => {
            setSubmissionError(null);
        },
    });

    const [MergeAccounts] = useMutation(MergeAccountsDocument);

    const handleMerge = useCallback(
        async (formData: MergeAccountFormDetails) => {
            console.dir(formData);
            const { firstName, lastName, day, month, year, memberId } =
                formData;
            const normalizedMonth = `0${month}`.slice(-2);
            const normalizedDay = `0${day}`.slice(-2);

            const result = await MergeAccounts({
                variables: {
                    patientId: patient.id,
                    mergeAccountsInput: {
                        firstName,
                        lastName,
                        dateOfBirth: `${year}-${normalizedMonth}-${normalizedDay}`,
                        memberId,
                    },
                },
            });

            if (result.data?.mergeAccounts.success) {
                notifications.show({
                    message: t('profile.verify.success'),
                    onClose: () => navigate(getAbsoluteRoute('logout')),
                });
            } else {
                setSubmissionError(t('profile.verify.error'));
            }
        },
        [patient.id]
    );

    return (
        <>
            {submissionError && (
                <FullBleed breakpoint="md" color="error">
                    <Alert severity="error">{submissionError}</Alert>
                </FullBleed>
            )}
            <MergeAccountFormProvider form={form}>
                <Stack gap="sm">
                    <Heading1>{t('profile.verify.heading')}</Heading1>
                    <Body1>{t('profile.verify.description')}</Body1>
                    <Stack gap="md">
                        <MergeAccountsForm
                            onSubmit={async (data) => {
                                setIsLoading(true);
                                await handleMerge(data);
                                setIsLoading(false);
                            }}
                        />
                        <Button
                            type="submit"
                            form="verify-prescryptive-benefits"
                            loading={isLoading}
                        >
                            {t('profile.verify.continue')}
                        </Button>
                    </Stack>
                    <Overlay loader fullScreen visible={isLoading} />
                </Stack>
            </MergeAccountFormProvider>
        </>
    );
};

export default ProfileVerifyRoute;
