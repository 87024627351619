import { Body1, Flex, IconPrescription } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type RxForUserProps = {
    patient: {
        firstName?: string;
        fullName?: string;
        lastName?: string;
    };
};

export const RxForUser = ({ patient }: RxForUserProps) => {
    const { t } = useTranslation();

    const { firstName, fullName, lastName } = patient;

    const name = fullName ? fullName : `${firstName} ${lastName}`;

    return (
        <Flex align="center" gap="xxs">
            <IconPrescription color="gray" />
            <Body1>{t('shoppingHeader.forUser', { name })}</Body1>
        </Flex>
    );
};
