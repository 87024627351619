import type { DependentListItemType } from '../../components/dependents/DependentListItem';
import i18n from '../../i18n';

export const getRelationshipDisplay = (relationship: DependentListItemType) => {
    const { t } = i18n;

    switch (relationship) {
        case 'DEPENDENT':
            return t('member.relationship.dependent');
        case 'GUARDIAN':
            return t('member.relationship.guardian');
        case 'SPOUSE':
            return t('member.relationship.spouse');
        case 'PRIMARY':
            return t('member.relationship.primary');
        case 'MYSELF':
        default:
            return '';
    }
};
