import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserPreferences } from '../../hooks/use-user-preferences';

export const UserPrefsInitializer = () => {
    const { i18n } = useTranslation();
    const { language } = useUserPreferences();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return null;
};
