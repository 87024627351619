import type { SessionDetails } from '@phx/instrumentation/web';
import {
    type PropsWithChildren as PWC,
    createContext,
    useContext,
    useState,
} from 'react';

type SessionCtx = {
    sessionDetails?: SessionDetails;
    setSessionDetails: (value: SessionDetails) => void;
};

const ErrorSessionContext = createContext<SessionCtx | null>(null);

export const ErrorSessionProvider = ({ children }: PWC) => {
    const [sessionDetails, setSessionDetails] = useState<
        SessionDetails | undefined
    >();

    return (
        <ErrorSessionContext.Provider
            value={{ sessionDetails, setSessionDetails }}
        >
            {children}
        </ErrorSessionContext.Provider>
    );
};

export const useErrorSessionContext = () => {
    const ctx = useContext(ErrorSessionContext);
    if (ctx === null) {
        throw new Error(
            'useErrorSessionContext must be called from within a ErrorSessionProvider'
        );
    }

    return ctx;
};
