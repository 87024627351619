import type { FieldFunctionOptions } from '@apollo/client';

export const DrugFamilyFields = {
    forms: {
        merge(
            existing: any = [],
            incoming: any[],
            { readField }: FieldFunctionOptions
        ) {
            const results = new Map<string, any>();
            for (const item of existing) {
                results.set(readField<string>('defaultNdc', item) ?? '', item);
            }
            for (const item of incoming) {
                results.set(readField<string>('defaultNdc', item) ?? '', item);
            }
            return [...results.values()];
        },
    },
    formCodes: {
        merge(existing = [], incoming: any[]) {
            return [...new Set([...existing, ...incoming])];
        },
    },
};
