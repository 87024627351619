import { Body1, Flex } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { ConditionalComponent } from '../../../utility/ConditionalComponent';

type DateFilledProps = {
    formattedDate?: string | null;
};

export const DateFilled = ({ formattedDate }: DateFilledProps) => {
    const { t } = useTranslation();

    return (
        <ConditionalComponent condition={!!formattedDate}>
            <Flex justify="space-between">
                <Body1>{t('claimHistory.claimDetails.dateFilled')}</Body1>
                <Body1 data-testid="claim-history-details-date-filled">
                    {formattedDate}
                </Body1>
            </Flex>
        </ConditionalComponent>
    );
};
