import { safeParseJSON } from '@phx/browser-utils';
import {
    Body1,
    Flex,
    IconButton,
    IconCheckCircle,
    IconClose,
    Switch,
    TextInput,
} from '@phx/design-system';
import React from 'react';

type FlagValueControlProps = {
    flag: string;
    value: unknown;
    localFlags: Record<string, unknown>;
    tempValues: Record<string, unknown>;
    setTempValues: React.Dispatch<
        React.SetStateAction<Record<string, unknown>>
    >;
    handleSetValue: (flag: string, newValue: unknown) => void;
    handleClearOverride: (flag: string) => void;
};

export const FlagValueControl = ({
    flag,
    value,
    localFlags,
    tempValues,
    setTempValues,
    handleSetValue,
    handleClearOverride,
}: FlagValueControlProps) => {
    switch (typeof value) {
        case 'boolean':
            return (
                <Switch
                    checked={localFlags[flag] as boolean}
                    onChange={(e) =>
                        handleSetValue(flag, e.currentTarget.checked)
                    }
                />
            );
        case 'string':
        case 'number':
        case 'object':
            return (
                <Flex>
                    <TextInput
                        value={
                            typeof value === 'object'
                                ? JSON.stringify(tempValues[flag], null, 2)
                                : String(tempValues[flag])
                        }
                        onChange={(e) => {
                            const newValue =
                                typeof value === 'number'
                                    ? Number(e.currentTarget.value)
                                    : typeof value === 'object'
                                      ? safeParseJSON(e.currentTarget.value)
                                      : e.currentTarget.value;
                            setTempValues((prev) => ({
                                ...prev,
                                [flag]: newValue,
                            }));
                        }}
                    />
                    <IconButton
                        Icon={IconCheckCircle}
                        onClick={() => handleSetValue(flag, tempValues[flag])}
                    />
                    <IconButton
                        Icon={IconClose}
                        onClick={() => handleClearOverride(flag)}
                    />
                </Flex>
            );
        default:
            return <Body1>Unsupported flag type</Body1>;
    }
};
