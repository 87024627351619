import {
    Body1,
    DefinitionList,
    Flex,
    FullBleed,
    HorizontalDivider,
    IconButton,
    IconInfo,
    Modal,
    Stack,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { AlternativeCard, PricingInfo } from '@phx/myphx-lib';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../../graphql';
import {
    type CashOfferInfoFragment,
    type ClaimGeneratedPrescriberOrderInfoFragment,
    type DigitallyReceivedPrescriberOrderInfoFragment,
    type GetAltProductOffersQuery,
    type GetClaimV2Query,
    type InsuranceOfferInfoFragment,
    type OfferPricingDetail,
    type PatientInfoFragment,
    type ProductInfoFragment,
} from '../../../../graphql/generated/graphql';
import {
    getMedicationDescription,
    getOrderQuantity,
} from '../../../../util/cabinet/prescriber-orders';
import { ContactUsWidget } from '../../../common/ContactUsWidget';
import { InfoHeading } from '../../../common/InfoHeading';
import { ProductDetails } from '../../../preview-details/components/ProductDetails';
import {
    type TranslationBlocks,
    asDefinitionListItem,
} from '../../../utility/as-definition-list-item';
import { RxForUser } from '../../rx-for-user/RxForUser';
import { ShoppingHeader } from '../../shopping-header/ShoppingHeader';

import { EstimatedPriceSection } from './EstimatedPriceSection';
import { AltsPrescriberSection } from './PrescriberSection';

export type AltOffer =
    | (Pick<
          InsuranceOfferInfoFragment,
          | '__typename'
          | 'patientResponsibilityCost'
          | 'planPaysAmount'
          | 'pricingDetail'
      > &
          Partial<Pick<InsuranceOfferInfoFragment, 'pharmacyProvider'>>)
    | Pick<
          CashOfferInfoFragment,
          '__typename' | 'patientResponsibilityCost' | 'pricingDetail'
      >;
export type ProductConnection =
    | NonNullable<
          GetAltProductOffersQuery['prescriberOrder']
      >['fillRecommendationsConnection']
    | NonNullable<
          GetClaimV2Query['claim']
      >['fill']['alternativeProductsConnection'];

export type AltProduct = {
    product: ProductInfoFragment;
    pricing: OfferPricingDetail[];
    planSavings: number;
    patientSavings: number;
};

type AlternativesProps = {
    patient?: PatientInfoFragment;
    product: ProductInfoFragment;
    cheapestOffer: AltOffer;
    prescriberOrder?:
        | ClaimGeneratedPrescriberOrderInfoFragment
        | DigitallyReceivedPrescriberOrderInfoFragment;
    pharmacyName?: string;
    products: AltProduct[];
};
export const Alternatives = (props: AlternativesProps) => {
    const {
        products,
        patient,
        product,
        cheapestOffer,
        prescriberOrder,
        pharmacyName,
    } = props;
    const { t } = useTranslation();

    const dialogContentBlocks: TranslationBlocks = t(
        'alternatives.infoDialog.content',
        { returnObjects: true }
    );
    if (!prescriberOrder) {
        return null;
    }
    const quantity = getOrderQuantity(prescriberOrder) ?? product.modeQuantity;
    const productDescription = getMedicationDescription(prescriberOrder);
    const prescriber = getFragment(prescriberOrder.prescriber);
    const [selectedProduct, setSelectedProduct] = useState<
        ProductInfoFragment | undefined
    >(undefined);
    const [openedAltCard, { open: openAltCard, close: closeAltCard }] =
        useDisclosure(false);
    const [
        openedEstPriceInfo,
        { open: openEstPriceInfo, close: closeEstPriceInfo },
    ] = useDisclosure(false);
    const handleCardClick = (product: ProductInfoFragment) => {
        setSelectedProduct(product);
        openAltCard();
    };

    const pharmacyProvider =
        cheapestOffer.__typename === 'InsuranceOffer'
            ? getFragment(cheapestOffer.pharmacyProvider)
            : undefined;

    return (
        <Stack>
            <ShoppingHeader>
                <Stack>
                    <Stack gap="xs">
                        {patient ? <RxForUser patient={patient} /> : null}
                        <ProductDetails
                            product={product}
                            productDescription={productDescription}
                            quantity={quantity}
                        />
                    </Stack>
                    <Stack gap="xs">
                        <PricingInfo {...cheapestOffer.pricingDetail} />
                        <HorizontalDivider />
                        <Flex justify="space-between" align="center">
                            <Body1>
                                {t('alternatives.estimatedPriceAt', {
                                    name: pharmacyName,
                                })}
                            </Body1>
                            {pharmacyProvider ? (
                                <IconButton
                                    Icon={IconInfo}
                                    onClick={openEstPriceInfo}
                                    withStyles={false}
                                    size="sm"
                                />
                            ) : undefined}
                        </Flex>
                    </Stack>
                </Stack>
            </ShoppingHeader>

            <FullBleed breakpoint="md" color="gray">
                <Stack role="list">
                    <InfoHeading
                        title={t('alternatives.title')}
                        informationDialog={{
                            title: t('alternatives.infoDialog.title'),
                            content: (
                                <DefinitionList
                                    compact={false}
                                    items={dialogContentBlocks.map(
                                        asDefinitionListItem
                                    )}
                                />
                            ),
                        }}
                    />
                    <Stack gap="sm">
                        {products.map((productInfo) => (
                            <AlternativeCard
                                role="listitem"
                                key={productInfo.product.id}
                                onClick={() =>
                                    handleCardClick(productInfo.product)
                                }
                                {...productInfo}
                                product={{
                                    name: productInfo.product.name,
                                    description: `${productInfo.product.strengthDisplayText}`,
                                }}
                            />
                        ))}
                    </Stack>
                </Stack>
            </FullBleed>
            <Stack gap="lg">
                <AltsPrescriberSection prescriber={prescriber} />
                <HorizontalDivider />
                <ContactUsWidget />
            </Stack>
            <Modal
                onClose={closeAltCard}
                opened={openedAltCard}
                title={t('alternatives.newPrescription.switchTo', {
                    name: selectedProduct?.name ?? '',
                })}
            >
                <AltsPrescriberSection prescriber={prescriber} />
            </Modal>
            {pharmacyProvider ? (
                <Modal
                    onClose={closeEstPriceInfo}
                    opened={openedEstPriceInfo}
                    title={t('alternatives.estimatedPrice.header')}
                >
                    <EstimatedPriceSection
                        pharmacyProvider={pharmacyProvider}
                    />
                </Modal>
            ) : undefined}
        </Stack>
    );
};
