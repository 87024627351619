/**
 * Formats a number to localized currency.
 * TODO: Update with user locale when available in cache.
 * @param {number} number - Numerical value to be converted.
 * @returns {string} This function returns a string in the format of $0.00.
 */
export const formatToLocalizedCurrency = (
    number: number,
    options?: Intl.NumberFormatOptions
) => {
    // update with user locale when available in cache
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        ...options,
    }).format(number);
};
