import { ElevatedCard } from '@phx/design-system';

import { CabinetUpToDateCard } from '../../../components/cabinet/orders/CabinetUpToDateCard';
import { MedicationOrders } from '../../../components/cabinet/orders-card/MedicationOrders';
import {
    type GetBasicPrescriberOrdersByPatientQuery,
    type GetBasicPrescriberOrdersQuery,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import {
    type PrescriberOrdersFilter,
    usePrescriberOrders,
} from '../../../hooks/use-prescriber-orders';

import { MedCabDetailsHeader } from './MedCabDetailsHeader';

export type MedCabCardProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
    patientId: string;
    onlyShowUnclaimedOrders: boolean;
};

const getOrderFilter = (
    includeDependents: boolean,
    onlyShowUnclaimedOrders: boolean
): PrescriberOrdersFilter => {
    if (onlyShowUnclaimedOrders) return 'unclaimed';
    if (includeDependents) return 'new-all';
    return 'new-by-patient';
};

export const MedCabDetailCard = ({
    patient,
    patientId,
    onlyShowUnclaimedOrders,
}: MedCabCardProps) => {
    const { primaryPatientId, includeDependents } = usePatientContext();

    const orders = usePrescriberOrders({
        patient,
        patientId,
        primaryPatientId,
        filter: getOrderFilter(includeDependents, onlyShowUnclaimedOrders),
    });

    if (orders.length === 0) {
        return <CabinetUpToDateCard />;
    }

    return (
        <ElevatedCard
            data-testid="medicine-cabinet-detail-card"
            header={<MedCabDetailsHeader />}
        >
            <MedicationOrders orders={orders} showAll />
        </ElevatedCard>
    );
};
