import type { GetPatientFavoriteLocationsQuery } from '../../graphql/generated/graphql';

export const getOtherFavoriteLocations = (
    locationId: string,
    favoriteLocationsData?: GetPatientFavoriteLocationsQuery
) => {
    return favoriteLocationsData?.patient?.preferences.patientFavoriteLocationsConnection.edges.filter(
        (x) => x.node.id !== locationId
    );
};
