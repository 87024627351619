import {
    Flex,
    HorizontalDivider,
    SidebarHeading,
    Stack,
} from '@phx/design-system';
import type { PropsWithChildren as PWC } from 'react';

export type SectionProps = {
    label?: string | null;
};

export const MenuSection = ({ label, children }: PWC<SectionProps>) => (
    <Stack gap="sm">
        <HorizontalDivider variant="middle" />
        <Stack gap={0}>
            {label ? (
                <Flex px="sm" pb="xxs">
                    <SidebarHeading>{label}</SidebarHeading>
                </Flex>
            ) : null}
            {children}
        </Stack>
    </Stack>
);
