import { useQuery } from '@apollo/client';
import { useAuthContext } from '@phx/auth/client';
import { Body1, Button, Flex, notifications } from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/react';
import {
    type PropsWithChildren as PWC,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import {
    GetPatientDocument,
    GetPatientFavoriteLocationsDocument,
    type PatientInfoFragment,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';

import { useFavoritePharmacies } from './use-favorite-pharmacies';

type FavoritePharmacyProps = {
    favoriteLocationIds: string[];
    toggleFavorite: (locationId: string) => Promise<void>;
    checkIsFavorite: (locationId: string) => boolean;
};

export type PatientContextType = {
    patient: PatientInfoFragment | null;
    primaryPatientId: string;
    scopedPatientId: string;
    includeDependents: boolean;
    updatePatientIdScope: (id: string, includeDependents?: boolean) => void;
    preferences: {
        favoritePharmacies: FavoritePharmacyProps;
    };
};

export const PatientContext = createContext<PatientContextType>({
    patient: null,
    primaryPatientId: '',
    scopedPatientId: '',
    includeDependents: true,
    updatePatientIdScope: () => {},
    preferences: {
        favoritePharmacies: {
            favoriteLocationIds: [],
            toggleFavorite: async () => {},
            checkIsFavorite: () => false,
        },
    },
});

type InnerPatientProviderProps = {
    patient: PatientInfoFragment;
};

const InnerPatientProvider = ({
    children,
    patient,
}: PWC<InnerPatientProviderProps>) => {
    const [scopedPatientId, setSelectedPatientId] = useState(patient.id);
    const [includeDependents, setIncludeDependents] = useState(true);
    const [favoriteLocationIds, setFavoriteLocationIds] = useState<string[]>(
        []
    );
    const { t } = useTranslation();

    useQuery(GetPatientFavoriteLocationsDocument, {
        variables: { patientId: scopedPatientId },
        onCompleted: (data) => {
            const favoriteLocationEdges =
                data.patient?.preferences.patientFavoriteLocationsConnection
                    .edges;
            const locationIds =
                favoriteLocationEdges?.map((edge) => edge.node.id) ?? [];
            setFavoriteLocationIds(locationIds);
        },
    });

    const updatePatientIdScope = useCallback(
        (id: string, includeDeps?: boolean) => {
            setSelectedPatientId(id);
            if (id === patient.id && includeDeps != null) {
                setIncludeDependents(includeDeps);
            }
            if (id !== patient.id) {
                setIncludeDependents(false);
            }
        },
        [patient.id]
    );

    const { addFavorite, removeFavorite } =
        useFavoritePharmacies(scopedPatientId);

    const handleAddFavorite = async (locationId: string) => {
        setFavoriteLocationIds((prev) => [...prev, locationId]);
        await addFavorite(locationId);
        notifications.show({
            message: t('pharmacy.favorites.actions.favorite'),
        });
    };

    const handleRemoveFavorite = async (locationId: string) => {
        setFavoriteLocationIds((prev) => [
            ...prev.filter((loc) => loc !== locationId),
        ]);
        await removeFavorite(locationId);
        notifications.show({
            id: locationId,
            message: (
                <Flex align="center" justify="space-between">
                    <Body1 color="white">
                        {t('pharmacy.favorites.actions.unfavorite')}
                    </Body1>
                    <Button
                        color="high-contrast-inverted"
                        variant="subtle"
                        onClick={(e) => {
                            e.stopPropagation();
                            notifications.hide(locationId);
                            handleAddFavorite(locationId);
                        }}
                    >
                        {t('pharmacy.favorites.actions.undo')}
                    </Button>
                </Flex>
            ),
        });
    };
    const checkIsFavorite = useCallback(
        (locationId: string) => {
            return favoriteLocationIds.includes(locationId);
        },
        [favoriteLocationIds]
    );

    const toggleFavorite = async (locationId: string) => {
        const isFavorite = checkIsFavorite(locationId);
        if (isFavorite) {
            return await handleRemoveFavorite(locationId);
        }
        return await handleAddFavorite(locationId);
    };

    const values = useMemo(
        () => ({
            patient,
            primaryPatientId: patient.id,
            scopedPatientId,
            includeDependents,
            updatePatientIdScope,
            preferences: {
                favoritePharmacies: {
                    favoriteLocationIds,
                    toggleFavorite,
                    checkIsFavorite,
                },
            },
        }),
        [
            patient,
            scopedPatientId,
            includeDependents,
            updatePatientIdScope,
            favoriteLocationIds,
            toggleFavorite,
            checkIsFavorite,
        ]
    );

    return (
        <PatientContext.Provider value={values}>
            {children}
        </PatientContext.Provider>
    );
};

type PatientDataLoaderProps = { patientId: string };

const PatientDataLoader = ({
    children,
    patientId,
}: PWC<PatientDataLoaderProps>) => {
    const Loader = QueryLoader<typeof GetPatientDocument>;

    return (
        <Loader
            query={GetPatientDocument}
            component={({ data }) => {
                if (data?.patient) {
                    const patient = getFragment(data.patient);
                    if (patient) {
                        return (
                            <InnerPatientProvider patient={patient}>
                                {children}
                            </InnerPatientProvider>
                        );
                    }
                }

                // Throw an error and trigger error boundary
                throw Error('Forbidden');
            }}
            variables={{ patientId }}
        />
    );
};

export const PatientProvider = ({ children }: PWC) => {
    const { isAuthenticated, user } = useAuthContext();
    const { telemetryInstance } = useTelemetryContext();

    useEffect(() => {
        if (isAuthenticated && user?.id) {
            telemetryInstance.setUserId(user.id);
        }
    }, [isAuthenticated, user?.id]);

    return isAuthenticated ? (
        <PatientDataLoader patientId={user!.id}>{children}</PatientDataLoader>
    ) : (
        <>{children}</>
    );
};
