import type { FieldReadFunction } from '@apollo/client';

import { getInitialsFromFullName } from '../util';

export function calculateAge(date: string) {
    const ageDiff = Date.now() - new Date(date).getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const readFullName: FieldReadFunction = (_, { readField }) => {
    const firstName = readField<string>('firstName');
    const lastName = readField<string>('lastName');
    return [firstName, lastName].filter((x) => x).join(' ');
};

export const readPatientInitials: FieldReadFunction = (_, { readField }) => {
    const fullName = readField<string>('fullName');
    return getInitialsFromFullName(fullName);
};

export const readPatientAge: FieldReadFunction = (_, { readField }) => {
    const dob = readField<string>('dateOfBirth')!;
    return calculateAge(dob);
};

export const patientDetails = {
    fullName: {
        read: readFullName,
    },
    initials: {
        read: readPatientInitials,
    },
    age: {
        read: readPatientAge,
    },
};
