import { NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import type {
    GetBasicPrescriberOrdersByPatientQuery,
    GetBasicPrescriberOrdersQuery,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { usePrescriberOrders } from '../../../hooks/use-prescriber-orders';
import { getAbsoluteRoute } from '../../../util';
import { hasPriorPrescriptions } from '../../../util/orders';

export type PrescriptionHistoryLinkProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
};

export const PrescriptionHistoryLink = ({
    patient,
}: PrescriptionHistoryLinkProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { primaryPatientId, scopedPatientId } = usePatientContext();

    const orders = usePrescriberOrders({
        patient,
        patientId: scopedPatientId ?? primaryPatientId,
        primaryPatientId,
    });

    return (
        <ConditionalComponent condition={hasPriorPrescriptions(orders)}>
            <NavLink
                label={t('cabinet.historyNav')}
                onClick={() => navigate(getAbsoluteRoute('cabinet.history'))}
                variant="subtle"
            />
        </ConditionalComponent>
    );
};
