import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../graphql';
import { GetPatientDependentsDocument, type PatientInfoFragment } from '../graphql/generated/graphql';

import { usePatientContext } from './use-patient-context';

export const VIEW_ALL = 'viewAll';
export const UNCLAIMED = 'unclaimed';

export type PatientSelectorOption = {
    id: string;
    name: string;
    age?: number;
    disabled?: boolean;
};

export const usePatientSelectorOptions = () => {
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const { t } = useTranslation();

    const { data, loading } = useQuery(GetPatientDependentsDocument, {
        variables: {
            patientId: primaryPatientId,
        },
    });

    const getOptionLabel = useCallback((option: PatientSelectorOption) => {
        if (!option.age) return option.name; // If option is not a patient
        if (option.id === primaryPatientId) {
            return t('patientSelector.primaryPatient', { name: option.name });
        }
        return t(`patientSelector.otherPatient`, {
            name: option.name,
            age: option.age,
        });
    }, []);

    const patientSorter = useCallback(
        (pA: PatientInfoFragment, pB: PatientInfoFragment) => {
            if (pA.id === scopedPatientId) return -1;
            if (pB.id === scopedPatientId) return 1;
            return pA.firstName.localeCompare(pB.firstName);
        },
        []
    );

    const options: PatientSelectorOption[] = useMemo(() => {
        const patientFragment = getFragment(data?.patient);
        if (loading || !data || !patientFragment) return [];

        const consentedPatients =
            data?.patient?.consentedPatientsConnection.edges.map((x) =>
                getFragment(x.node)
            ) ?? [];

        const mappedPatients = [patientFragment, ...consentedPatients]
            .sort(patientSorter)
            .map((p) => ({ id: p.id, name: p.fullName, age: p.age }));

        return mappedPatients;
    }, [data, loading]);

    return { loading, getOptionLabel, options };
};
