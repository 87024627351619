import {
    Autocomplete,
    Body1,
    ElevatedCard,
    FilterSelection,
    FullBleed,
    IconLocalShipping,
    IconLocationOn,
    IconShoppingBag,
    Stack,
    Tabs,
    days,
} from '@phx/design-system';
import { useDisclosure, useIsMobile } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { type Geolocation } from '@phx/location-utils';
import { PharmacyInfo } from '@phx/myphx-lib';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    ChainProviderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    combineChainAndPharmacyProviders,
    filterAndSortPharmacyProviders,
    matchesSearchQuery,
    processProviderToPharmacyInfo,
} from '../../util/pharmacy/process-providers';
import { LocationPickerCover } from '../location-picker-cover';
import { MoreProviders } from '../provider-offers';

import { NoResults } from './NoResults';

export const InNetworkPharmacies = ({
    geolocation,
    chainProviders,
    mailOrderProviders,
    pharmacyProviders,
}: {
    geolocation: Geolocation;
    chainProviders: ChainProviderInfoFragment[];
    pharmacyProviders: PharmacyProviderInfoFragment[];
    mailOrderProviders: MailOrderProviderInfoFragment[];
}) => {
    const { telemetryInstance } = useTelemetryContext();
    const { t } = useTranslation();
    const isMobile = useIsMobile('md');
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;
    const [searchQuery, setSearchQuery] = useState('');

    const distance = useNumericSearchParam('within');

    const [opened, { open, close }] = useDisclosure(false);

    const sortedPickupProviders = useMemo(() => {
        const allPharmacyProviders = combineChainAndPharmacyProviders(
            pharmacyProviders,
            chainProviders,
            geolocation
        );

        return filterAndSortPharmacyProviders(
            allPharmacyProviders,
            geolocation,
            distance,
            searchQuery
        );
    }, [pharmacyProviders, chainProviders, searchQuery, geolocation, distance]);

    const sortedMailOrderProviders = useMemo(() => {
        return mailOrderProviders.filter((location) => {
            return (
                !searchQuery || matchesSearchQuery(location.name, searchQuery)
            );
        });
    }, [mailOrderProviders, searchQuery]);

    return (
        <Stack gap="lg">
            <Stack gap="md">
                <LocationPickerCover isOpen={opened} onClose={close} />
                <FilterSelection
                    input={
                        geolocation?.name ??
                        t('pickYourPharmacy.location.setLocationAndDistance')
                    }
                    StartIcon={IconLocationOn}
                    onInputClick={open}
                    distance={
                        geolocation?.name
                            ? `${distance} ${t('common.distance.distanceUnit')}`
                            : undefined
                    }
                />
                <Autocomplete
                    label={t('pharmacy.inNetwork.inputPlaceholder')}
                    onChange={setSearchQuery}
                    value={searchQuery}
                />
            </Stack>
            <Tabs
                defaultValue="pickup"
                grow
                onChange={(value: string | null) => {
                    value &&
                        telemetryInstance.logEvent({
                            name: `Provider select view toggle, ${value}`,
                            source: 'ProviderSelection',
                        });
                }}
                tabBarProps={{ fullBleed: isMobile }}
                tabs={[
                    {
                        label: t('pickYourPharmacy.pickUp'),
                        panel: (
                            <FullBleed breakpoint="md" color="gray">
                                <Stack>
                                    {sortedPickupProviders.length ? (
                                        <Body1>
                                            {t(
                                                'pharmacy.inNetwork.countLabel',
                                                {
                                                    count: sortedPickupProviders.length,
                                                }
                                            )}
                                        </Body1>
                                    ) : (
                                        <NoResults />
                                    )}
                                    {sortedPickupProviders?.map(
                                        (pickUpProvider) => {
                                            {
                                                const processedProvider =
                                                    processProviderToPharmacyInfo(
                                                        pickUpProvider,
                                                        geolocation,
                                                        checkIsFavorite,
                                                        t
                                                    );

                                                const {
                                                    id,
                                                    phone,
                                                    distance,
                                                    address,
                                                    weeklyHours,
                                                    favorite,
                                                    name,
                                                    chainName,
                                                    moreProviders,
                                                } = processedProvider;

                                                const day =
                                                    days[new Date().getDay()];

                                                return (
                                                    <ElevatedCard
                                                        key={`pharmacy-info-container-${id}`}
                                                        footer={
                                                            moreProviders ? (
                                                                <MoreProviders
                                                                    primaryName={
                                                                        chainName ||
                                                                        name
                                                                    }
                                                                    moreProviders={
                                                                        moreProviders
                                                                    }
                                                                />
                                                            ) : null
                                                        }
                                                    >
                                                        <PharmacyInfo
                                                            weeklyHours={
                                                                weeklyHours
                                                            }
                                                            address={address}
                                                            distance={distance}
                                                            phone={phone}
                                                            favorite={favorite}
                                                            toggleFavorite={() =>
                                                                toggleFavorite(
                                                                    id
                                                                )
                                                            }
                                                            name={name}
                                                            day={t(
                                                                'pharmacy.hours.today'
                                                            )}
                                                            hours={
                                                                weeklyHours?.[
                                                                    day
                                                                ]
                                                            }
                                                        />
                                                    </ElevatedCard>
                                                );
                                            }
                                        }
                                    )}
                                </Stack>
                            </FullBleed>
                        ),
                        value: 'pickup',
                        LeftIcon: IconShoppingBag,
                    },
                    {
                        label: t('pickYourPharmacy.delivery'),
                        panel: (
                            <FullBleed breakpoint="md" color="gray">
                                <Stack>
                                    {sortedMailOrderProviders.length ? (
                                        <Body1>
                                            {t(
                                                'pharmacy.inNetwork.countLabel',
                                                {
                                                    count: sortedMailOrderProviders.length,
                                                }
                                            )}
                                        </Body1>
                                    ) : (
                                        <NoResults />
                                    )}
                                    {sortedMailOrderProviders.map(
                                        (mailOrderProvider) => {
                                            const processedProvider =
                                                processProviderToPharmacyInfo(
                                                    mailOrderProvider,
                                                    geolocation,
                                                    checkIsFavorite,
                                                    t
                                                );
                                            const {
                                                id,
                                                url,
                                                phone,
                                                shippingTime,
                                                favorite,
                                                name,
                                            } = processedProvider;
                                            return (
                                                <ElevatedCard
                                                    key={`pharmacy-info-container-${mailOrderProvider.id}`}
                                                >
                                                    <PharmacyInfo
                                                        shippingTime={
                                                            shippingTime ??
                                                            undefined
                                                        }
                                                        url={url ?? undefined}
                                                        phone={phone}
                                                        favorite={favorite}
                                                        toggleFavorite={() =>
                                                            toggleFavorite(id)
                                                        }
                                                        name={name}
                                                    />
                                                </ElevatedCard>
                                            );
                                        }
                                    )}
                                </Stack>
                            </FullBleed>
                        ),
                        value: 'delivery',
                        LeftIcon: IconLocalShipping,
                    },
                ]}
            />
        </Stack>
    );
};
