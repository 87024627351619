import { useAuthContext } from '@phx/auth/client';
import { Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { useNavigate } from 'react-router-dom';

import externalRoutes from '../../../../external-routes';
import { getAbsoluteRoute } from '../../../../util';
import { useCallback } from 'react';

export const useFaqTranslationRoutes = () => {
    const navigate = useNavigate();
    const { signUp } = useAuthContext();

    // const baseUrl = import.meta.env.VITE_AUTH_URL;

    // const recoverPassword = useCallback(() => {
    //     const fullUrl = new URL('/recover-send-code', baseUrl);

    //     window.location.replace(fullUrl.toString());
    // }, [baseUrl, location.pathname]);

    const register = useCallback(
        () => signUp(location.pathname),
        [signUp, location.pathname]
    );

    //NOTE: Ideally the "Recover Account page" is linked to the '/recover-send-code' route but there's no solution or workaround to be able to successfully grab it with a return state url. So commenting this all out for now.

    return {
        // 1: <Anchor onClick={recoverPassword} />,
        2: <Anchor onClick={openTalkativeUI} />,
        3: <Anchor onClick={register} />,
        4: (
            <Anchor
                onClick={() =>
                    window.open(
                        externalRoutes.priorAuthMedicationList,
                        '_blank'
                    )
                }
            />
        ),
        5: (
            <Anchor
                onClick={() =>
                    window.open(externalRoutes.priorAuthForm, '_blank')
                }
            />
        ),
        6: (
            <Anchor
                onClick={() => navigate(getAbsoluteRoute('cabinet.history'))}
            />
        ),
        7: (
            <Anchor
                onClick={() =>
                    window.open(externalRoutes.termsConditions, '_blank')
                }
            />
        ),
        8: (
            <Anchor
                onClick={() => navigate(getAbsoluteRoute('plan.summary'))}
            />
        ),
    };
};
