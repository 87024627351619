import { IconChevronRight, Subtitle1, TextButton } from '@phx/design-system';
import { ContactBookIllustration, IllustrationCard } from '@phx/myphx-lib';
import { usePwaContext } from '@phx/pwa';
import { useTranslation } from 'react-i18next';

export const AddContactCard = () => {
    const { t } = useTranslation();
    const { addContact } = usePwaContext();

    return (
        <IllustrationCard
            Illustration={ContactBookIllustration}
            variant="filled"
            color="error"
            header={<Subtitle1>{t('home.addContactCard.heading')}</Subtitle1>}
            onClick={addContact}
            role="tab"
        >
            <TextButton RightIcon={IconChevronRight}>
                {t('home.addContactCard.button')}
            </TextButton>
        </IllustrationCard>
    );
};
