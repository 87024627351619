import {
    Body1,
    FilledCard,
    Flex,
    Heading2,
    Stack,
    TextButton,
} from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type {
    PatientInfoFragment,
    PhxBenefitsPlan,
    SmartpricePlan,
    UnknownPlan,
} from '../../graphql/generated/graphql';
import { isDisplayablePlan } from '../../graphql/policies/util';
import { getAbsoluteRoute } from '../../util';
import { mapPlanData } from '../../util/offers/get-offer-plan-data';

export const ShowPlanCard = ({
    plan,
    patient,
    heading,
    description,
}: {
    plan: PhxBenefitsPlan | SmartpricePlan | UnknownPlan;
    patient: PatientInfoFragment;
    heading: string;
    description: string;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const planData = isDisplayablePlan(plan)
        ? [
              mapPlanData(
                  plan,
                  patient.fullName,
                  patient.coveragesConnection.edges
              ),
          ]
        : [];

    return (
        <FilledCard>
            <Stack gap="sm">
                <Flex justify="space-between">
                    <Heading2>{heading}</Heading2>
                    <TextButton
                        onClick={() =>
                            navigate(getAbsoluteRoute('profile.benefit'))
                        }
                    >
                        {t('orderConfirmation.coverageCard.viewDetails')}
                    </TextButton>
                </Flex>
                <Body1>{description}</Body1>
                {planData.length && <CoverageCard {...planData[0]} />}
            </Stack>
        </FilledCard>
    );
};
