import {
    Body1,
    Box,
    Button,
    Heading1,
    Heading4,
    HorizontalDivider,
    Image,
    Stack,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useErrorSessionContext } from '../../contexts/ErrorSessionProvider';

import { ErrorWidget } from './ErrorWidget';

const RootErrorRoute = () => {
    const { sessionDetails } = useErrorSessionContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack gap="lg" justify="center" align="center">
            <Box>
                <Image
                    src="/static/img/error404.svg"
                    alt="something-went-wrong"
                    width="200"
                    height="200"
                    fit="contain"
                />
            </Box>
            <Stack gap="sm" justify="center" align="center">
                <Heading1>{t('error.generic.title')}</Heading1>
                <Body1>{t('error.generic.subtitle')}</Body1>
            </Stack>
            <Button
                fullWidth
                variant="contained"
                onClick={() => navigate(-1)}
                data-testid="medication-entry-fill-button"
            >
                {t('error.mainButtonText')}
            </Button>
            <Box mt="10px" style={{ width: '100%' }}>
                <HorizontalDivider />
            </Box>
            <Stack gap="xs" align="center" justify="center">
                <Heading4>{t('error.supportTitle')}</Heading4>
                <Stack gap="xxs" align="center" justify="center">
                    <Body1>{`Session ID: ${sessionDetails?.sessionId ?? 'N/A'}`}</Body1>
                    <Body1>{`Trace ID: ${sessionDetails?.traceId ?? 'N/A'}`}</Body1>
                </Stack>
            </Stack>
            <ErrorWidget />
        </Stack>
    );
};

export default RootErrorRoute;
