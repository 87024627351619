import {
    type PropsWithChildren as PWC,
    createContext,
    useContext,
} from 'react';

import type { AppConfig } from '../types/config';

export type AppConfigCtx = AppConfig;

const AppConfigContext = createContext<AppConfigCtx | null>(null);

/**
 * A proxy is used for local development here to avoid CORS issues. The proxy
 * settings can be found in the vite.config.ts.
 */
const liveRouterUrl =
    import.meta.env.VITE_ENV === 'development'
        ? '/gql'
        : import.meta.env.VITE_GRAPH_URL;

const DEFAULT_CONFIG: AppConfig = {
    apollo: {
        selectedRouter: 'live',
        routers: {
            live: liveRouterUrl,
            synthetic: 'http://localhost:4000/',
        },
    },
    env:
        import.meta.env.VITE_ENV === 'production'
            ? 'production'
            : 'development',
};

export const AppConfigProvider = ({ children }: PWC) => {
    const ctx: AppConfigCtx = DEFAULT_CONFIG;

    return (
        <AppConfigContext.Provider value={ctx}>
            {children}
        </AppConfigContext.Provider>
    );
};

export const useAppConfig = (): AppConfigCtx => {
    const ctx = useContext(AppConfigContext);

    if (ctx === null) {
        throw new Error(
            'useAppConfig() cannot be called ouside an AppConfigProvider'
        );
    }

    return ctx;
};
