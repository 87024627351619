import { Body1, Flex, Subtitle2 } from '@phx/design-system';

export type DisplayValue = { display: string; value: string };

export const DependentDisplayValue = ({ item }: { item: DisplayValue }) => {
    return (
        <Flex gap="xxs">
            <Subtitle2>
                {item.display}
                {': '}
            </Subtitle2>
            <Body1 data-testid="dependent-detail">{item.value}</Body1>
        </Flex>
    );
};
