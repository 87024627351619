import type { FieldReadFunction } from '@apollo/client';

export const prescriberOrder: FieldReadFunction = (
    existingDataFromCache,
    options
) => {
    const { args, toReference } = options;
    return (
        existingDataFromCache ||
        toReference({
            __typename: 'PrescriberOrder',
            id: args?.id,
        })
    );
};
