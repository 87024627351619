import { Navigate, useParams } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    type DigitallyReceivedPrescriberOrder,
    GetPrescriberOrderDocument,
    type GetPrescriberOrderQuery,
    type PriceQuoteInfoFragment,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

import { OrderDetails } from './details/OrderDetails';

const OrderDetailsRoute = ({ data }: { data: GetPrescriberOrderQuery }) => {
    let quote;
    let order;

    const prescriberOrderFragment = getFragment(data?.prescriberOrder);

    if (
        prescriberOrderFragment?.__typename ===
        'DigitallyReceivedPrescriberOrder'
    ) {
        order = getFragment(
            prescriberOrderFragment
        ) as DigitallyReceivedPrescriberOrder;

        if (order?.latestSelectedPriceQuote) {
            quote = getFragment(
                order.latestSelectedPriceQuote
            ) as PriceQuoteInfoFragment;
        }
    }

    if (!order) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return <OrderDetails priceQuote={quote} prescriberOrder={order} />;
};

const OrderDetailsLoader = () => {
    const { prescriberOrderId } = useParams();

    const Loader = QueryLoader<typeof GetPrescriberOrderDocument>;

    if (!prescriberOrderId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetPrescriberOrderDocument}
            variables={{ prescriberOrderId }}
            component={OrderDetailsRoute}
        />
    );
};

export default OrderDetailsLoader;
