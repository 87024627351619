import {
    Body1,
    Box,
    IconChevronRight,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { IllustrationCard, UpToDateIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const HomeCabinetUpToDateCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <IllustrationCard
            Illustration={UpToDateIllustration}
            illustrationPosition="right"
            variant="outlined"
            color="gray"
            data-testid="medicine-cabinet-card-empty"
        >
            <Stack gap="xs">
                <Subtitle1>{t('home.cabinet.upToDateCard.header')}</Subtitle1>
                <Body1>{t('cabinet.upToDate.body')}</Body1>
                <Box>
                    <TextButton
                        onClick={() =>
                            navigate(getAbsoluteRoute('cabinet.root'))
                        }
                        RightIcon={IconChevronRight}
                        data-testid="empty-medicine-cabinet-card-manage-button"
                    >
                        {t('home.cabinet.upToDateCard.cta')}
                    </TextButton>
                </Box>
            </Stack>
        </IllustrationCard>
    );
};
