import { Alternatives } from '../../../components/cabinet/prescription/alternatives/Alternatives';
import { GreatPrice } from '../../../components/claim/ClaimGreatPrice';
import { ClaimNotFound } from '../../../components/claim/ClaimNotFound';
import { ClaimReversed } from '../../../components/claim/ClaimReversed';
import { getFragment } from '../../../graphql';
import {
    ClaimStatus,
    type GetClaimV2Query,
} from '../../../graphql/generated/graphql';
import { getAltProducts } from '../../../util/alternatives/get-alt-products';
import { useClaimOffer } from '../use-claim-offer';

type ClaimsProps = {
    data?: GetClaimV2Query;
};

export const Claims = (props: ClaimsProps) => {
    const { data } = props;

    const claim = getFragment(data?.claim);
    const offer = useClaimOffer(claim);
    const products = offer
        ? getAltProducts(
              data?.claim?.fill.alternativeProductsConnection,
              offer,
              getFragment(getFragment(claim?.fill)?.pharmacyProvider)?.id ?? ''
          )?.products
        : undefined;

    const claimStatusCancelled = claim?.status === ClaimStatus.Cancelled;

    if (!claim) return <ClaimNotFound />;

    if (claimStatusCancelled) return <ClaimReversed claim={claim} />;

    if (offer && products?.length) {
        // TODO: this is a bit wonky
        const fill = getFragment(claim.fill);
        const product = getFragment(fill.product);
        const prescriberOrder = getFragment(fill.prescriberOrder);
        const pharmacy = getFragment(getFragment(fill.location));

        return (
            <Alternatives
                patient={getFragment(claim.patient)}
                prescriberOrder={prescriberOrder}
                pharmacyName={pharmacy!.name}
                product={product}
                cheapestOffer={offer}
                products={products}
            />
        );
    }

    return <GreatPrice claim={claim} />;
};
