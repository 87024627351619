import { useAuthContext } from '@phx/auth/client';
import {
    Anchor,
    Body1,
    Heading1,
    Heading2,
    HorizontalDivider,
    Image,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ComparePricesCard } from '../../components/start-saving/ComparePricesCard';

type HowToSendDrxSectionProps = {
    subheader: string;
    description1: string;
    description2?: string;
    address?: string;
    src: string;
    alt: string;
    children?: JSX.Element;
};

const HowToSendDrxRoute = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    const location = useLocation();
    const { signIn, signUp } = useAuthContext();

    const login = useCallback(
        () => signIn(location.pathname),
        [signIn, location.pathname]
    );
    const register = useCallback(
        () => signUp(location.pathname),
        [signUp, location.pathname]
    );

    const HowToSendDrxSection = ({
        subheader,
        description1,
        description2,
        address,
        src,
        alt,
        children,
    }: HowToSendDrxSectionProps) => (
        <Stack gap="sm">
            <Heading2>{subheader}</Heading2>
            {children}
            <Body1>{description1}</Body1>
            {address && <Subtitle2>{address}</Subtitle2>}
            <Image src={src} alt={alt} />
            {description2 && <Body1>{description2}</Body1>}
        </Stack>
    );

    const howToSendDrxSteps = [
        {
            subheader: t('sendDrx.sayPrescryptive.subheader'),
            description1: t('sendDrx.sayPrescryptive.description1'),
            description2: t('sendDrx.sayPrescryptive.description2'),
            address: t('sendDrx.sayPrescryptive.address'),
            src: '/static/img/prescryptive-address.png',
            alt: t('sendDrx.sayPrescryptive.alt'),
        },
        {
            subheader: t('sendDrx.textMessage.subheader'),
            description1: t('sendDrx.textMessage.description2'),
            src: '/static/img/text-message.png',
            alt: t('sendDrx.textMessage.alt'),
            children: (
                <Body1>
                    <Trans i18nKey="sendDrx.textMessage.description1">
                        {
                            'We’ll notify you when we receive the prescription. Tap the link and '
                        }
                        <Anchor onClick={login}>{'sign in'}</Anchor>
                        {', or '}
                        <Anchor onClick={register}>
                            {'create an account'}
                        </Anchor>
                        {' if you don’t have one.'}
                    </Trans>
                </Body1>
            ),
        },
        {
            subheader: t('sendDrx.saveMoney.subheader'),
            description1: t('sendDrx.saveMoney.description1'),
            src: '/static/img/pick-pharm-header.png',
            alt: t('sendDrx.saveMoney.alt'),
        },
    ];

    return (
        <Stack gap="lg">
            <Heading1>{t('sendDrx.header')}</Heading1>
            {howToSendDrxSteps.map((section, index) => (
                <>
                    <HowToSendDrxSection key={section.subheader} {...section} />
                    {index < howToSendDrxSteps.length - 1 && (
                        <HorizontalDivider />
                    )}
                </>
            ))}

            <ComparePricesCard />
        </Stack>
    );
};

export default HowToSendDrxRoute;
