import type { CoverageCardProps } from '@phx/myphx-lib';

import { getFragment } from '../../graphql';
import type {
    DisplayablePlan,
    PatientInfoFragment,
} from '../../graphql/generated/graphql';
import { formatPhxMemberId } from '../phx-member-id';

type LocalPatientCoverageEdge =
    PatientInfoFragment['coveragesConnection']['edges'][0];

const extractMemberIdFromPlan = (
    patientCoverageEdges: LocalPatientCoverageEdge[],
    plan: DisplayablePlan
) => {
    const coverageEdge = patientCoverageEdges.find((coverageEdge) => {
        const node = getFragment(coverageEdge.node);
        if (
            node.__typename === 'PhxBenefitsPlan' ||
            node.__typename === 'SmartpricePlan'
        ) {
            return node.id === plan.id;
        }
    });

    const coverageFragment = getFragment(coverageEdge?.coverage);
    if (
        getFragment(coverageEdge?.node)?.__typename === 'PhxBenefitsPlan' &&
        coverageFragment?.memberId
    ) {
        return formatPhxMemberId(
            coverageFragment.memberId,
            coverageFragment.personCode
        );
    }
    return coverageFragment?.memberId;
};

export const mapPlanData = (
    plan: DisplayablePlan,
    patientName: string,
    patientCoverageEdges: LocalPatientCoverageEdge[]
): CoverageCardProps => {
    return {
        patient: {
            name: patientName,
            id: extractMemberIdFromPlan(patientCoverageEdges, plan) ?? '',
        },
        planInfo: {
            bin: plan?.bin ?? '',
            pcn: plan?.pcn ?? '',
            group: plan.groupPlanCode,
        },
        variant:
            plan.__typename === 'PhxBenefitsPlan' ? 'benefit' : 'smartprice',
    };
};
