import { getFragment } from '../../graphql';
import type { PatientInfoFragment } from '../../graphql/generated/graphql';
import { isActiveDateRange } from '../is-active-date-range';
import { formatPhxMemberId } from '../phx-member-id';

export const getMemberId = (member: PatientInfoFragment) => {
    const memberIds: string[] = [];
    member.coveragesConnection.edges.forEach((edge) => {
        const coverage = getFragment(edge.coverage);
        if (!isActiveDateRange(coverage.startDate, coverage.endDate)) {
            return;
        }

        if (edge.node.__typename === 'PhxBenefitsPlan') {
            memberIds.unshift(
                formatPhxMemberId(coverage.memberId, coverage.personCode)
            );
            return;
        }

        memberIds.push(coverage.memberId);
    });

    return memberIds.length > 0 ? memberIds[0] : null;
};
