import { Body1, Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const ModalContent = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="md">
            <Stack gap="sm">
                <Subtitle2>
                    {t('planSummary.modalContent.deductible')}
                </Subtitle2>
                <Body1>{t('planSummary.modalContent.deductibleCaption')}</Body1>
            </Stack>
            <Stack gap="sm">
                <Subtitle2>
                    {t('planSummary.modalContent.maxOutOfPocket')}
                </Subtitle2>
                <Body1>
                    {t('planSummary.modalContent.maxOutOfPocketCaption')}
                </Body1>
            </Stack>
        </Stack>
    );
};
