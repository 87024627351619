import type { PropsWithChildren as PWC } from 'react';

type ConditionalComponentProps = {
    condition: boolean | (() => boolean);
};

export const ConditionalComponent = ({
    condition,
    children,
}: PWC<ConditionalComponentProps>) => {
    const result = typeof condition === 'function' ? condition() : condition;

    if (result) {
        return <>{children}</>;
    }

    return null;
};
