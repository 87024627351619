import {
    BareCard,
    Body1,
    Heading2,
    IconSms,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const AskPrescriberCard = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Heading2>{t('profile.askPrescriberCard.header')}</Heading2>
            <BareCard
                data-testid="ask-prescriber-section"
                Icon={IconSms}
                iconColor="secondary"
            >
                <Stack gap="xxxs">
                    <Subtitle1>
                        {t('profile.askPrescriberCard.subtitle')}
                    </Subtitle1>
                    <Body1>{t('profile.askPrescriberCard.body')}</Body1>
                </Stack>
            </BareCard>
        </Stack>
    );
};
