import { getFragment } from '../../graphql';
import type {
    ClaimGeneratedPrescriberOrderBasicInfoFragment,
    ClaimGeneratedPrescriberOrderInfoFragment,
    DigitallyReceivedPrescriberOrderBasicInfoFragment,
    DigitallyReceivedPrescriberOrderInfoFragment,
    UnclaimedPrescriberOrderInfoFragment,
} from '../../graphql/generated/graphql';
import type { Prescription } from '../../routes/cabinet/components/HistorySearch';

export type PrescriberOrderFragment =
    | DigitallyReceivedPrescriberOrderInfoFragment
    | ClaimGeneratedPrescriberOrderInfoFragment
    | UnclaimedPrescriberOrderInfoFragment;

// Omit fragment name so PrescriberOrderFragment *can* be assigned to a basic fragment component
export type BasicPrescriberOrderFragment =
    | Omit<DigitallyReceivedPrescriberOrderBasicInfoFragment, ' $fragmentName'>
    | Omit<ClaimGeneratedPrescriberOrderBasicInfoFragment, ' $fragmentName'>
    | UnclaimedPrescriberOrderInfoFragment;

export const isDigitalPrescriberOrder = (
    order:
        | DigitallyReceivedPrescriberOrderBasicInfoFragment
        | ClaimGeneratedPrescriberOrderBasicInfoFragment
        | undefined
): order is DigitallyReceivedPrescriberOrderBasicInfoFragment => {
    return order?.__typename === 'DigitallyReceivedPrescriberOrder';
};

export const isClaimPrescriberOrder = (
    order:
        | DigitallyReceivedPrescriberOrderBasicInfoFragment
        | ClaimGeneratedPrescriberOrderBasicInfoFragment
        | undefined
): order is ClaimGeneratedPrescriberOrderBasicInfoFragment => {
    return order?.__typename === 'ClaimGeneratedPrescriberOrder';
};

export const isUnclaimedPrescriberOrder = (
    order:
        | BasicPrescriberOrderFragment
        | UnclaimedPrescriberOrderInfoFragment
        | undefined
): order is UnclaimedPrescriberOrderInfoFragment => {
    return order?.__typename === 'UnclaimedPrescriberOrder';
};

export const getMedicationDescription = (
    medication: BasicPrescriberOrderFragment
) => {
    switch (medication.__typename) {
        case 'ClaimGeneratedPrescriberOrder':
            return medication.productDescriptionClaimGenerated;
        case 'DigitallyReceivedPrescriberOrder':
            return medication.productDescription;
        case 'UnclaimedPrescriberOrder':
            return medication.productDescriptionUnclaimed;
        default:
            return undefined;
    }
};

export const getPrescribedProductFragment = (
    medication: BasicPrescriberOrderFragment
) => {
    switch (medication.__typename) {
        case 'ClaimGeneratedPrescriberOrder':
            return medication.prescribedProductClaimGenerated;
        case 'DigitallyReceivedPrescriberOrder':
            return medication.prescribedProduct;
        case 'UnclaimedPrescriberOrder':
            return medication.prescribedProductUnclaimed;
        default:
            return undefined;
    }
};

export const getOrderQuantity = (medication: BasicPrescriberOrderFragment) => {
    switch (medication.__typename) {
        case 'ClaimGeneratedPrescriberOrder':
            return medication.quantityClaimGenerated;
        case 'DigitallyReceivedPrescriberOrder':
            return medication.quantity;
        case 'UnclaimedPrescriberOrder':
            return getFragment(medication.prescribedProductUnclaimed)
                .modeQuantity;
        default:
            return undefined;
    }
};

export const getWrittenDate = (
  prescriberOrderFragment:
    | DigitallyReceivedPrescriberOrderInfoFragment
    | ClaimGeneratedPrescriberOrderInfoFragment
) => {
  switch (prescriberOrderFragment.__typename) {
    case 'ClaimGeneratedPrescriberOrder':
      return prescriberOrderFragment.writtenDateClaimGenerated;
    case 'DigitallyReceivedPrescriberOrder':
      return prescriberOrderFragment.writtenDate;
    default:
      return undefined;
  }
}

export const sortPrescriptionsByDate = (a: Prescription, b: Prescription) => {
  const prescriptionA = getWrittenDate(a) ?? '';
  const prescriptionB = getWrittenDate(b) ?? '';

  return Date.parse(prescriptionB) - Date.parse(prescriptionA);
};
