import {
    Body1,
    List,
    ListItem,
    Modal,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type VerifyPrescriptionInfoModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const VerifyPrescriptionInfoModal = ({
    isOpen,
    onClose,
}: VerifyPrescriptionInfoModalProps) => {
    const { t } = useTranslation();

    const modalBullets = t('verifyPrescription.infoDialog.difficulty.bullets', {
        returnObjects: true,
    }) as Array<{ key: string; content: string }>;

    return (
        <Modal
            height="min-content"
            opened={isOpen}
            onClose={onClose}
            size="lg"
            title={t('verifyPrescription.infoDialog.title')}
        >
            <Stack gap="md">
                <Body1>{t('verifyPrescription.infoDialog.description')}</Body1>
                <Stack gap="xs">
                    <Subtitle1>
                        {t('verifyPrescription.infoDialog.difficulty.heading')}
                    </Subtitle1>
                    <List>
                        {modalBullets.map(({ key, content }) => (
                            <ListItem key={key}>
                                <Body1>{content}</Body1>
                            </ListItem>
                        ))}
                    </List>
                </Stack>
                <Stack gap="xxs">
                    <Subtitle1>
                        {t('verifyPrescription.infoDialog.future.heading')}
                    </Subtitle1>
                    <Body1>
                        {t('verifyPrescription.infoDialog.future.description')}
                    </Body1>
                </Stack>
            </Stack>
        </Modal>
    );
};
