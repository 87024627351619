import { useSearchParams } from 'react-router-dom';

/**
 * Gets a search parameter and converts it to an integer or returns a default value
 *
 * @param {string} paramName - The name of the search parameter to retrieve
 * @param {number} [defaultValue=30] - The default value to return if the parameter is invalid (optional, defaults to 30)
 * @returns {number} - The integer value of the parameter if valid, otherwise, the defaultValue
 */
export const useNumericSearchParam = (
    paramName: string,
    defaultValue: number = 30
): number => {
    const [searchParams] = useSearchParams();
    const queryValue = searchParams.get(paramName);
    const paramValue = queryValue?.length ? Number(queryValue) : defaultValue;

    return paramValue;
};
