import {
    Anchor,
    Body1,
    Box,
    Button,
    Heading1,
    HorizontalDivider,
    Overlay,
    Stack,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UnclaimedPrescriberOrderInfoFragment } from '../../../../graphql/generated/graphql';
import { ContactUsWidget } from '../../../common/ContactUsWidget';
import { UnclaimedPrescriptionDescription } from '../UnclaimedPrescriptionDescription';

import type { VerifyFormDetails } from './verify-form';
import { VerifyForm } from './VerifyForm';
import { VerifyPrescriptionInfoModal } from './VerifyPrescriptionInfoModal';

type VerifyPrescriptionProps = {
    prescriberOrder: UnclaimedPrescriberOrderInfoFragment;
    claimPrescription: (formData: VerifyFormDetails) => Promise<void>;
};

export const VerifyPrescription = ({
    prescriberOrder,
    claimPrescription,
}: VerifyPrescriptionProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, { open, close }] = useDisclosure(false);

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                <Heading1>{t('verifyPrescription.title')}</Heading1>
                <Box>
                    <Body1 inline>{t('verifyPrescription.subtitle')}</Body1>{' '}
                    <Anchor onClick={open}>
                        {t('verifyPrescription.whyVerify')}
                    </Anchor>
                </Box>
            </Stack>
            <UnclaimedPrescriptionDescription
                prescriberOrder={prescriberOrder}
            />
            <HorizontalDivider />
            <VerifyForm
                onSubmit={async (data) => {
                    setIsLoading(true);
                    await claimPrescription(data);
                    setIsLoading(false);
                }}
                onError={() => setIsLoading(false)}
            />
            <HorizontalDivider />
            <ContactUsWidget />
            <Button
                type="submit"
                form="verify-prescription"
                loading={isLoading}
            >
                {t('verifyPrescription.verify')}
            </Button>
            <Overlay loader fullScreen visible={isLoading} />
            <VerifyPrescriptionInfoModal isOpen={showModal} onClose={close} />
        </Stack>
    );
};
