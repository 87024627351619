import { NavBarProvider } from '@phx/design-system';

import { PatientProvider } from '../../contexts/patient/PatientProvider';
import { SidebarProvider } from '../../contexts/SidebarProvider';
import { AutoScrolltop } from '../utility/AutoScrolltop';

import { AppContent } from './AppContent';
import { AppSidebar } from './AppSidebar';

const AppRoot = () => {
    return (
        <>
            <PatientProvider>
                <NavBarProvider>
                    <SidebarProvider>
                        <AppContent />
                        <AppSidebar />
                    </SidebarProvider>
                </NavBarProvider>
            </PatientProvider>
            <AutoScrolltop />
        </>
    );
};

export default AppRoot;
