import {
    Body1,
    Box,
    IconFileOpen,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const DeniedDescription = ({
    onOpenDecisionLetter,
    determiner,
}: {
    onOpenDecisionLetter?: () => void;
    determiner?: string;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Body1>
                {t('priorAuthorization.statusPage.denied.description1')}
            </Body1>
            {onOpenDecisionLetter ? (
                <Box>
                    <TextButton
                        onClick={onOpenDecisionLetter}
                        LeftIcon={IconFileOpen}
                    >
                        {t('priorAuthorization.statusPage.viewDecisionLetter')}
                    </TextButton>
                </Box>
            ) : null}
            <Body1>
                {t('priorAuthorization.statusPage.denied.description2', {
                    determiner,
                })}
            </Body1>
            <Body1>{t('priorAuthorization.statusPage.denied.stepOne')}</Body1>
            <Body1>{t('priorAuthorization.statusPage.denied.stepTwo')}</Body1>
        </Stack>
    );
};
