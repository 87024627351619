import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../../generated/graphql';

import { isValidPatientCost } from './is-valid-patient-cost';
import { isValidPlanCost } from './is-valid-plan-cost';

type OfferPrice =
    | Pick<CashOfferInfoFragment, 'patientResponsibilityCost' | '__typename'>
    | Pick<
          InsuranceOfferInfoFragment,
          'patientResponsibilityCost' | 'planPaysAmount' | '__typename'
      >;

export const isValidOfferPricing = (offer?: OfferPrice) => {
    switch (offer?.__typename) {
        case 'CashOffer':
            return isValidPatientCost(offer);
        case 'InsuranceOffer':
            return isValidPatientCost(offer) && isValidPlanCost(offer);
        default:
            return false;
    }
};
