import {
    Body1,
    Box,
    Flex,
    IconError,
    Stack,
    Subtitle2,
    TextInput,
} from '@phx/design-system';
import type { FormErrors } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { useDobValidation } from '../../hooks/use-dob-validation';

import {
    type MergeAccountFormDetails,
    useMergeAccountFormContext,
} from './merge-account-form';

export type MergeAccountsFormProps = {
    onError?: (errors: FormErrors<MergeAccountFormDetails>) => void;
    onSubmit: (data: MergeAccountFormDetails) => Promise<void>;
};

export const MergeAccountsForm = ({
    onError,
    onSubmit,
}: MergeAccountsFormProps) => {
    const { t } = useTranslation();

    const form = useMergeAccountFormContext();

    const hasNameError = form.errors.firstName || form.errors.lastName;
    const hasDobError = form.errors.dob !== undefined;

    useDobValidation<MergeAccountFormDetails>(form);

    return (
        <form
            onSubmit={form.onSubmit(onSubmit, onError)}
            id="verify-prescryptive-benefits"
        >
            <Stack gap="md">
                <Flex gap="xs">
                    <Box flex={1}>
                        <TextInput
                            name="firstName"
                            label={t('common.formFields.firstName')}
                            {...form.getInputProps('firstName', {
                                withError: false,
                            })}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            label={t('common.formFields.lastName')}
                            name="lastname"
                            {...form.getInputProps('lastName', {
                                withError: false,
                            })}
                        />
                    </Box>
                </Flex>
                {hasNameError && (
                    <Flex align="center" gap="xxs">
                        <IconError color="error" />
                        <Body1
                            color="error"
                            data-testid="full-name-required-error"
                        >
                            {t('common.formErrors.name.required')}
                        </Body1>
                    </Flex>
                )}
                <Stack gap="xxs">
                    <Subtitle2>{t('common.formFields.dob')}</Subtitle2>
                    <Flex gap="xs">
                        <Box flex={1}>
                            <TextInput
                                name="month"
                                type="number"
                                label={t('common.formFields.dobMonth')}
                                inputMode="numeric"
                                error={Boolean(
                                    form.errors.dob || form.errors.month
                                )}
                                {...form.getInputProps('month', {
                                    withError: false,
                                })}
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                name="day"
                                label={t('common.formFields.dobDay')}
                                type="number"
                                inputMode="numeric"
                                error={Boolean(
                                    form.errors.dob || form.errors.day
                                )}
                                {...form.getInputProps('day', {
                                    withError: false,
                                })}
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                name="year"
                                label={t('common.formFields.dobYear')}
                                type="number"
                                inputMode="numeric"
                                error={Boolean(
                                    form.errors.dob || form.errors.year
                                )}
                                {...form.getInputProps('year', {
                                    withError: false,
                                })}
                            />
                        </Box>
                    </Flex>
                    {hasDobError && (
                        <Flex align="center" gap="xxs">
                            <IconError color="error" />
                            <Body1 color="error">{form.errors.dob}</Body1>
                        </Flex>
                    )}
                </Stack>
                <Box>
                    <TextInput
                        name="memberId"
                        label={t('profile.verify.formFields.memberId')}
                        {...form.getInputProps('memberId')}
                    />
                </Box>
            </Stack>
        </form>
    );
};
