import { Navigate, useParams } from 'react-router-dom';

import { PriorAuthorizationStatus } from '../../../components/prior-authorization/PriorAuthorizationStatus';
import { getFragment } from '../../../graphql';
import { GetPrescriberOrderDocument } from '../../../graphql/generated/graphql';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';

const PriorAuthorizationRoute = () => {
    const { prescriberOrderId } = useParams();
    const Loader = QueryLoader<typeof GetPrescriberOrderDocument>;
    if (!prescriberOrderId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetPrescriberOrderDocument}
            variables={{
                prescriberOrderId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            render={(data) => {
                let prescriberOrder;
                let priorAuthInfo;

                if (
                    data?.prescriberOrder?.__typename ===
                    'DigitallyReceivedPrescriberOrder'
                ) {
                    prescriberOrder = getFragment(data?.prescriberOrder);
                    priorAuthInfo = getFragment(
                        prescriberOrder.applicablePriorAuthorization
                    );
                }

                if (!prescriberOrder || !priorAuthInfo) {
                    return <Navigate to={getAbsoluteRoute('error')} />;
                }

                return (
                    <PriorAuthorizationStatus
                        prescriberOrder={prescriberOrder}
                        priorAuthInfo={priorAuthInfo}
                    />
                );
            }}
        />
    );
};

export default PriorAuthorizationRoute;
