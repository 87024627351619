import {
    Body1,
    Box,
    Heading1,
    Heading4,
    HorizontalDivider,
    Image,
    Stack,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import { useErrorSessionContext } from '../../contexts/ErrorSessionProvider';

import { ErrorWidget } from './ErrorWidget';

const ForbiddenErrorRoute = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const { sessionDetails } = useErrorSessionContext();
    console.error(error);

    return (
        <Stack justify="center" align="center" style={{ width: '100%' }}>
            <Box>
                <Image src="/static/img/error-forbidden.svg" alt="forbidden" />
            </Box>
            <Heading1>{t('error.forbidden.title')}</Heading1>
            <Body1>{t('error.forbidden.subtitle')}</Body1>
            <Box mt="10px" style={{ width: '100%' }}>
                <HorizontalDivider />
            </Box>
            <Heading4>{t('error.supportTitle')}</Heading4>
            <Stack gap={'2px'} align="center" justify="center">
                <Body1>{`Session ID: ${sessionDetails?.sessionId}`}</Body1>
                <Body1>{`Trace ID: ${sessionDetails?.traceId}`}</Body1>
            </Stack>
            <ErrorWidget />
        </Stack>
    );
};

export default ForbiddenErrorRoute;
