import { useAuthContext } from '@phx/auth/client';
import {
    Anchor,
    Body1,
    Box,
    Flex,
    Image,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import externalRoutes from '../../external-routes';
import { getAbsoluteRoute } from '../../util';
import { ConditionalComponent } from '../utility/ConditionalComponent';

export const Footer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { isAuthenticated, signIn, signUp } = useAuthContext();

    const login = useCallback(
        () => signIn(location.pathname),
        [signIn, location.pathname]
    );
    const register = useCallback(
        () => signUp(location.pathname),
        [signUp, location.pathname]
    );

    return (
        <Stack px="md" py="xxxl">
            <Stack align="start" gap="xl">
                <Image
                    w="186px"
                    src="/static/img/my-prescryptive-negative.png"
                    alt={t('unauth.footer.altImage')}
                />

                <Flex
                    w="100%"
                    gap="xl"
                    direction={{
                        base: 'column',
                        sm: 'row',
                    }}
                >
                    <Stack flex={{ sm: '1' }} gap="xs">
                        <Subtitle2 color="white">
                            {t('unauth.footer.myPrescryptive')}
                        </Subtitle2>
                        <ConditionalComponent condition={!isAuthenticated}>
                            <Box>
                                <Anchor onClick={login}>
                                    <Body1 color="white">
                                        {t('unauth.footer.signIn')}
                                    </Body1>
                                </Anchor>
                            </Box>
                            <Box>
                                <Anchor onClick={register}>
                                    <Body1 color="white" ta="start">
                                        {t('unauth.footer.createAccount')}
                                    </Body1>
                                </Anchor>
                            </Box>
                        </ConditionalComponent>
                        <Anchor href={getAbsoluteRoute('pharmacy.inNetwork')}>
                            <Body1 color="white">
                                {t('unauth.footer.inNetworkPharmacies')}
                            </Body1>
                        </Anchor>
                    </Stack>
                    <Stack flex={{ sm: '1' }} gap="xs">
                        <Subtitle2 color="white">
                            {t('unauth.footer.company')}
                        </Subtitle2>
                        <Anchor
                            href="https://prescryptive.com/"
                            target="_blank"
                        >
                            <Body1 color="white">
                                {t('unauth.footer.aboutPrescryptive')}
                            </Body1>
                        </Anchor>
                        <Anchor
                            href={externalRoutes.privacyPolicy}
                            target="_blank"
                        >
                            <Body1 color="white">
                                {t('unauth.footer.privacyPolicy')}
                            </Body1>
                        </Anchor>
                        <Anchor
                            href={externalRoutes.termsConditions}
                            target="_blank"
                        >
                            <Body1 color="white">
                                {t('unauth.footer.termsAndConditions')}
                            </Body1>
                        </Anchor>
                        <Anchor
                            href={externalRoutes.waPrivacyNotice}
                            target="_blank"
                        >
                            <Body1 color="white">
                                {t('unauth.footer.WAPrivacyNotice')}
                            </Body1>
                        </Anchor>
                    </Stack>
                </Flex>
            </Stack>
        </Stack>
    );
};
