import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { DrugFamilyHeader } from '../../components/common/DrugFamilyHeader';
import { OfferSelection } from '../../components/provider-offers';
import { getFragment } from '../../graphql';
import { GetProviderOffersDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

export const DrugOfferSelectionRoute = () => {
    const { providerOffersId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{
                id: providerOffersId,
            }}
            render={(data) => {
                const providerOffers = getFragment(data.providerOffers);
                const offer = getFragment(providerOffers?.offers[0]);

                const header = offer ? (
                    <DrugFamilyHeader drugId={offer.product.id} />
                ) : null;

                if (providerOffers) {
                    const onSelect = (selectedOfferId: string) =>
                        navigate(
                            getAbsoluteRoute('transfer.root', {
                                offerId: selectedOfferId,
                                providerOffersId: providerOffersId,
                            })
                        );

                    const buttonText = t(
                        'offerSelection.button.reviewOverview'
                    );

                    return providerOffers.offers.length > 1 ? (
                        <OfferSelection
                            providerOffers={providerOffers}
                            header={header}
                            onSelect={onSelect}
                            buttonText={buttonText}
                        />
                    ) : (
                        <Navigate
                            to={getAbsoluteRoute('transfer.root', {
                                providerOffersId,
                                offerId: getFragment(providerOffers.offers[0])
                                    .id,
                            })}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
