import {
    Body1,
    FilterSelection,
    Heading1,
    IconLocationOn,
    Stack,
    Subtitle1,
    TextInput,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useGeolocation } from '@phx/location-utils';
import { IllustrationCard, LocationFailedIllustration } from '@phx/myphx-lib';
import { type PropsWithChildren as PWC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationPickerCover } from '../../components/location-picker-cover';
import { InNetworkPharmacies } from '../../components/pharmacy/InNetworkPharmacies';
import { getFragment } from '../../graphql';
import {
    type ChainProviderInfoFragment,
    type MailOrderProviderInfoFragment,
    type PharmacyProviderInfoFragment,
    SearchPharmaciesDocument,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';

export const InNetworkPharmaciesRoute = () => {
    const Loader = QueryLoader<typeof SearchPharmaciesDocument>;
    const { geolocation } = useGeolocation();
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    if (geolocation) {
        return (
            <InNetworkPharmaciesShell>
                <Loader
                    query={SearchPharmaciesDocument}
                    variables={{
                        searchParams: {
                            connectionPageInput: {
                                first: 50,
                            },
                            includeInNetworkOnly: true,
                            within: {
                                radiusMiles: 100,
                                coordinates: {
                                    latitude: geolocation.latitude,
                                    longitude: geolocation.longitude,
                                },
                            },
                        },
                    }}
                    component={({ data }) => {
                        const providers = data.findProviders.edges;

                        const {
                            mailOrderProviders,
                            pharmacyProviders,
                            chainProviders,
                        } = useMemo(() => {
                            const chainProviders: ChainProviderInfoFragment[] =
                                [];
                            const mailOrderProviders: MailOrderProviderInfoFragment[] =
                                [];
                            const pharmacyProviders: PharmacyProviderInfoFragment[] =
                                [];

                            providers.forEach((provider) => {
                                switch (provider.node.__typename) {
                                    case 'ChainProvider':
                                        chainProviders.push(
                                            getFragment(provider.node)
                                        );
                                        break;
                                    case 'MailOrderProvider':
                                        mailOrderProviders.push(
                                            getFragment(provider.node)
                                        );
                                        break;
                                    case 'PharmacyProvider':
                                        pharmacyProviders.push(
                                            getFragment(provider.node)
                                        );
                                        break;
                                    default:
                                        break;
                                }
                            });

                            return {
                                chainProviders,
                                mailOrderProviders,
                                pharmacyProviders,
                            };
                        }, [providers]);

                        return (
                            <InNetworkPharmacies
                                mailOrderProviders={mailOrderProviders}
                                chainProviders={chainProviders}
                                pharmacyProviders={pharmacyProviders}
                                geolocation={geolocation}
                            />
                        );
                    }}
                />
            </InNetworkPharmaciesShell>
        );
    }

    return (
        <InNetworkPharmaciesShell>
            <LocationPickerCover isOpen={opened} onClose={close} />
            <FilterSelection
                input={t('locationPickerCover.title')}
                StartIcon={IconLocationOn}
                onInputClick={open}
            />
            <Stack gap="md">
                <TextInput
                    disabled
                    label={t('pharmacy.inNetwork.inputPlaceholder')}
                    value=""
                />
                <IllustrationCard
                    illustrationPosition="top"
                    Illustration={LocationFailedIllustration}
                    illustrationSize="lg"
                    variant="bare"
                    withShadow={false}
                >
                    <Stack gap="xxs">
                        <Subtitle1 ta="center">
                            {t('pharmacy.inNetwork.noLocation.header')}
                        </Subtitle1>
                        <Body1 ta="center">
                            {t('pharmacy.inNetwork.noLocation.body')}
                        </Body1>
                    </Stack>
                </IllustrationCard>
            </Stack>
        </InNetworkPharmaciesShell>
    );
};

const InNetworkPharmaciesShell = ({ children }: PWC) => {
    const { t } = useTranslation();

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                <Heading1>{t('pharmacy.inNetwork.heading')}</Heading1>
                <Body1>{t('pharmacy.inNetwork.subheading')}</Body1>
            </Stack>
            {children}
        </Stack>
    );
};
