import type { FieldReadFunction, Reference } from '@apollo/client';
import { default as i18next } from 'i18next';

import { formatToLocalizedCurrency } from '../../../util';
import { ChipType, type Recommendation } from '../../generated/graphql';
import { useRequiredField } from '../util';

export const readDisplayableRecommendations: FieldReadFunction = (
    _,
    { readField }
) => {
    try {
        const readRequiredField = useRequiredField(readField);
        const recommendations: readonly Recommendation[] =
            readRequiredField<Recommendation[]>('recommendations');

        const saveUpTo = i18next.t('shopping.saveUpto');
        const save = i18next.t('shopping.save');
        const planSaves = i18next.t('shopping.planSaves');
        const combinedRecommendations = new Map();

        recommendations.forEach((recommendation) => {
            if (recommendation.__typename === 'UnknownRecommendation') {
                return;
            }

            const tags = [];
            const providerOffersRef = readRequiredField<Reference>(
                'providerOffers',
                recommendation
            );

            const offers =
                readField<Reference[]>('offers', providerOffersRef) ?? [];

            switch (recommendation.__typename) {
                case 'PatientSavingsRecommendation':
                    tags.push({
                        label: `${offers?.length > 1 ? saveUpTo : save} ${formatToLocalizedCurrency(
                            recommendation.savings,
                            {
                                maximumFractionDigits: 0,
                            }
                        )}`,
                        chipType: ChipType.Success,
                    });
                    break;
                case 'PlanSavingsRecommendation':
                    tags.push({
                        label: `${planSaves} ${formatToLocalizedCurrency(
                            recommendation.savings,
                            {
                                maximumFractionDigits: 0,
                            }
                        )}`,
                        chipType: ChipType.Info,
                    });
                    break;
                default:
                    return;
            }

            const id = readRequiredField<string>('id', providerOffersRef);

            if (combinedRecommendations.has(id)) {
                const currentRecommendation = combinedRecommendations.get(id)!;

                combinedRecommendations.set(id, {
                    __typename: 'DisplayableRecommendation',
                    tags: [...currentRecommendation.tags, ...tags],
                    providerOffers: providerOffersRef,
                    maxSavings: Math.max(
                        currentRecommendation.maxSavings,
                        recommendation.savings
                    ),
                });
            } else {
                combinedRecommendations.set(id, {
                    __typename: 'DisplayableRecommendation',
                    tags,
                    providerOffers: providerOffersRef,
                    maxSavings: recommendation.savings,
                });
            }
        });

        return Array.from(combinedRecommendations.values());
    } catch (error) {
        // TODO report error to telemetry

        return [];
    }
};

export const recommendationsFields = {
    displayableRecommendations: {
        read: readDisplayableRecommendations,
    },
};
