import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';

/**
 *
 * @param offers An offer with a cash and/or insurance offer
 * @returns The lowest cost between the cash and insurance offer from one provider offer
 */
export const getMinCost = (
    offers: (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
) => {
    const relevantOffers = offers.filter(
        (offer) =>
            offer.__typename === 'InsuranceOffer' ||
            offer.__typename === 'CashOffer'
    ) as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[];

    return Math.min(
        ...relevantOffers.map(
            (offer) => offer.patientResponsibilityCost ?? Infinity
        )
    );
};

/**
 *
 * @param offers An array of offers that may or may not have an insurance offer
 * @returns The minimum amount that the insurance plan pays among the provided offers
 */
export const getMinPlanPays = (offers: InsuranceOfferInfoFragment[]) => {
    const relevantOffers = offers.filter(
        (offer) => offer.__typename === 'InsuranceOffer'
    );
    return Math.min(
        ...relevantOffers.map((offer) => offer.planPaysAmount ?? Infinity)
    );
};
