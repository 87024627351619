import { Body1, Box, Modal, Stack, Subtitle1 } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

export type SmartpriceCardInfoModalProps = {
    isOpen: boolean;
    close: () => void;
};

export const SmartpriceCardInfoModal = ({
    isOpen,
    close,
}: SmartpriceCardInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={close}
            title={t('planCards.smartprice.modal.title', {
                tm: constants.unicode.TRADEMARK,
            })}
        >
            <Stack gap="sm">
                <Box>
                    <Subtitle1>
                        {t('planCards.smartprice.modal.whatIsIt.heading', {
                            tm: constants.unicode.TRADEMARK,
                        })}
                    </Subtitle1>
                    <Body1>
                        {t('planCards.smartprice.modal.whatIsIt.body')}
                    </Body1>
                </Box>
                <Box>
                    <Subtitle1>
                        {t('planCards.smartprice.modal.deductible.heading', {
                            tm: constants.unicode.TRADEMARK,
                        })}
                    </Subtitle1>
                    <Body1>
                        {t('planCards.smartprice.modal.deductible.body')}
                    </Body1>
                </Box>
            </Stack>
        </Modal>
    );
};
