import { IconButton, IconInfo } from '@phx/design-system';
import { useCallback, useState } from 'react';

import { MedicineCabinetInfoModal } from './MedicineCabinetInfoModal';

export const MedicineCabinetInfoButton = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
    const closeModal = useCallback(
        () => setIsModalOpen(false),
        [setIsModalOpen]
    );

    return (
        <>
            <IconButton
                alt="manage-prescription-info-button"
                color="primary"
                size="sm"
                onClick={openModal}
                Icon={IconInfo}
            />
            <MedicineCabinetInfoModal isOpen={isModalOpen} close={closeModal} />
        </>
    );
};
