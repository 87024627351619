import { useDisableBackButton } from '@phx/design-system';
import { Navigate, useParams } from 'react-router-dom';

import { OrderConfirmation } from '../../components/order-confirmation/OrderConfirmation';
import { getFragment } from '../../graphql';
import { GetPrescriberOrderDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

const OrderConfirmationRoute = () => {
    const { prescriberOrderId } = useParams();
    const Loader = QueryLoader<typeof GetPrescriberOrderDocument>;
    useDisableBackButton(true);
    if (!prescriberOrderId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetPrescriberOrderDocument}
            variables={{
                prescriberOrderId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            render={(data) => {
                let offer;
                let prescriberOrder;

                // For order confirmation / pricing we will always be dealing with digitally received orders
                if (
                    data?.prescriberOrder?.__typename ===
                    'DigitallyReceivedPrescriberOrder'
                ) {
                    prescriberOrder = getFragment(data?.prescriberOrder);

                    offer = getFragment(prescriberOrder?.latestSelectedOffer);
                }

                if (!offer || !prescriberOrder) {
                    return <Navigate to={getAbsoluteRoute('error')} />;
                }

                return (
                    <OrderConfirmation
                        offer={offer}
                        prescriberOrder={prescriberOrder}
                    />
                );
            }}
        />
    );
};

export default OrderConfirmationRoute;
