import { Navigate, useParams } from 'react-router-dom';

import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { DrugFamilyHeader } from '../../components/common/DrugFamilyHeader';
import { PrescriptionOverview } from '../../components/transfer-flow/PrescriptionOverview';
import { getFragment, getFragments } from '../../graphql';
import { GetProviderOffersDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

export const TransferRoute = () => {
    const { providerOffersId, offerId } = useParams();

    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId || !offerId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{ id: providerOffersId }}
            render={(data) => {
                const providerOffers = getFragment(data.providerOffers);
                const offers = getFragments(providerOffers?.offers);
                const selectedOffer = offers?.find(
                    (offer) => offer.id === offerId
                );

                if (selectedOffer) {
                    return (
                        <PrescriptionOverview
                            header={
                                <ShoppingHeader>
                                    <DrugFamilyHeader
                                        drugId={selectedOffer.product.id}
                                    />
                                </ShoppingHeader>
                            }
                            offer={selectedOffer}
                            providerOffersId={providerOffersId}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
