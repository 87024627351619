import { IconChevronRight, Subtitle1, TextButton } from '@phx/design-system';
import { AddHomeIllustration, IllustrationCard } from '@phx/myphx-lib';
import { getCurrentOs, usePwaContext } from '@phx/pwa';
import { useTranslation } from 'react-i18next';

const userOs = getCurrentOs();

export const AddToHomeCard = () => {
    const { t } = useTranslation();
    const { installApp } = usePwaContext();

    return (
        <IllustrationCard
            Illustration={AddHomeIllustration}
            variant="filled"
            color="info"
            header={<Subtitle1>{t('home.addToHomeCard.heading')}</Subtitle1>}
            onClick={installApp}
            role="tab"
        >
            <TextButton RightIcon={IconChevronRight}>
                {userOs === 'ios'
                    ? t('home.addToHomeCard.button.ios')
                    : t('home.addToHomeCard.button.other')}
            </TextButton>
        </IllustrationCard>
    );
};
