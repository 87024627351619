import type { AltOffer } from '../../components/cabinet/prescription/alternatives/Alternatives';
import type { ProviderOffersInfoFragment } from '../../graphql/generated/graphql';
import { getMaxValue } from '../get-max-value';
import { getPriceableOffers } from '../offers/get-priceable-offers';

export const getBestPatientSavings = (
    originalOffer: AltOffer,
    providerOffers: ProviderOffersInfoFragment | undefined
) => {
    const priceableOffers = providerOffers
        ? getPriceableOffers(providerOffers)
        : [];

    return getMaxValue(
        priceableOffers,
        (offer) =>
            originalOffer.patientResponsibilityCost -
            offer.patientResponsibilityCost
    );
};
