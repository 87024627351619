import { days } from '@phx/design-system';
import type { PharmacyInfoProps } from '@phx/myphx-lib';

import { getFragment } from '../../graphql';
import type { LocationInfoFragment } from '../../graphql/generated/graphql';

import { mapWeeklyHours } from './map-weekly-hours';

export const convertLocationToPharmacyInfo = (
    locationInfoFragment: LocationInfoFragment
): PharmacyInfoProps => {
    const locationInfo = getFragment(locationInfoFragment);
    const address = getFragment(locationInfo.address);
    const pharmacyInfoAddress: PharmacyInfoProps['address'] = {
        ...address,
        line2: address.line2 ?? undefined,
    };
    const weeklyHours = locationInfo.weeklyHoursByDayOfWeek
        ? mapWeeklyHours(getFragment(locationInfo.weeklyHoursByDayOfWeek))
        : undefined;
    const day = days[new Date().getDay()];

    return {
        ...locationInfo,
        address: {
            ...pharmacyInfoAddress,
        },
        weeklyHours,
        hours: weeklyHours?.[day],
        phone: locationInfo.phoneNumber,
        day,
    };
};
