import {
    Body1,
    Flex,
    Heading2,
    IconCalendarToday,
    IconMoreHoriz,
    IconPerson,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import i18n from '../../../i18n';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../../util';
import DrugDetails from '../../common/DrugDetails';
import { ConditionalComponent } from '../../utility/ConditionalComponent';
import type { OrderConfirmationProps } from '../OrderConfirmation';

export const YourPrescription = ({
    prescriberOrder,
    offer,
}: OrderConfirmationProps) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToPrescriptionDetailsPage = () => {
        {
            prescriberOrder.id &&
                navigate(
                    getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: prescriberOrder.id,
                    }),
                    { state: { prev: 'history' } }
                );
        }
    };

    const { quantity } = prescriberOrder;
    const patient = getFragment(prescriberOrder.patient);
    const product = getFragment(offer.product);

    const createdDate = formatToLocalizedDate(
        new Date(offer?.createdDate),
        { dateStyle: 'medium' },
        i18n.language
    );

    return (
        <Stack gap="md">
            <Heading2>
                {t('orderConfirmation.yourPrescription.subHeader')}
            </Heading2>
            <Stack gap="xs">
                <Stack gap="xxs">
                    {prescriberOrder ? (
                        <Subtitle2 data-testid="product-details-header">
                            {drugDetailsSplitEnabled
                                ? product.name
                                : prescriberOrder.productDescription}
                        </Subtitle2>
                    ) : (
                        <Subtitle2>{product.description}</Subtitle2>
                    )}
                    <ConditionalComponent
                        condition={!!quantity && !drugDetailsSplitEnabled}
                    >
                        <Body1>
                            {t('order.prescribedQuantity', {
                                quantity,
                            })}
                        </Body1>
                    </ConditionalComponent>
                </Stack>
                <ConditionalComponent condition={drugDetailsSplitEnabled}>
                    <DrugDetails product={product} quantity={quantity} />
                </ConditionalComponent>

                <Flex gap="xs">
                    <IconPerson color="primary" />
                    <Body1>
                        {t('orderConfirmation.patientInfo', {
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                        })}
                    </Body1>
                </Flex>
                <Flex gap="xs">
                    <IconCalendarToday color="primary" />
                    <Body1>
                        {t('orderConfirmation.sentToPharmacyDate', {
                            date: createdDate,
                        })}
                    </Body1>
                </Flex>
                <Flex
                    gap="xs"
                    style={{ cursor: 'pointer' }}
                    onClick={navigateToPrescriptionDetailsPage}
                >
                    <IconMoreHoriz color="primary" />
                    <Body1> {t('orderConfirmation.viewMoreDetails')}</Body1>
                </Flex>
            </Stack>
        </Stack>
    );
};
