import { jsx as _jsx } from "react/jsx-runtime";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createContext, useMemo } from 'react';
import { useFlagOverrides } from './use-flag-overrides';
export const OverrideContext = createContext({
    overriddenFlags: {},
    setFlagOverride: (_flagState) => { },
    clearFlagOverrides: () => { },
});
export const FeatureFlagProvider = ({ provider, children, }) => {
    const Provider = provider;
    const { clearFlagOverride, clearFlagOverrides, overriddenFlags, setFlagOverride, } = useFlagOverrides();
    const values = useMemo(() => ({
        clearFlagOverride,
        clearFlagOverrides,
        overriddenFlags,
        setFlagOverride,
    }), [
        clearFlagOverride,
        clearFlagOverrides,
        overriddenFlags,
        setFlagOverride,
    ]);
    return (_jsx(Provider, { children: _jsx(OverrideContext.Provider, { value: values, children: children }) }));
};
