import { Body1, Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { UnclaimedPrescriberOrderInfoFragment } from '../../../graphql/generated/graphql';
import i18n from '../../../i18n';
import { formatToLocalizedDate } from '../../../util';

type UnclaimedPrescriptionDescriptionProps = {
    prescriberOrder: UnclaimedPrescriberOrderInfoFragment;
};

export const UnclaimedPrescriptionDescription = (
    props: UnclaimedPrescriptionDescriptionProps
) => {
    const { prescriberOrder } = props;
    const { t } = useTranslation();
    return (
        <Stack gap="xxs">
            <Subtitle2>{prescriberOrder.productDescriptionUnclaimed}</Subtitle2>
            <Body1>
                {prescriberOrder.patientInitials
                    ? t('unclaimedPrescriberOrder.prescriberPatientInitials', {
                          prescriber: prescriberOrder.prescriberInitials,
                          patient: prescriberOrder.patientInitials,
                      })
                    : t('unclaimedPrescriberOrder.prescriberInitials', {
                          prescriber: prescriberOrder.prescriberInitials,
                      })}
            </Body1>
            {prescriberOrder.writtenDateUnclaimed && (
                <Body1>
                    {t('unclaimedPrescriberOrder.recievedOnDate', {
                        date: formatToLocalizedDate(
                            new Date(prescriberOrder.writtenDateUnclaimed),
                            {
                                dateStyle: 'medium',
                            },
                            i18n.language
                        ),
                    })}
                </Body1>
            )}
        </Stack>
    );
};
