import {
    Body1,
    Box,
    IconChevronRight,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { IllustrationCard, MobileExperienceIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';

export const GettingStartedCard = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    const navigate = useNavigate();

    return (
        <IllustrationCard
            Illustration={MobileExperienceIllustration}
            variant="filled"
            header={
                <Subtitle1>{t('helpCenter.gettingStarted.heading')}</Subtitle1>
            }
            // NOTE: HowToSendDrx is already available, this will temporarily route to Start Saving page to match auth home's save money marketing card. When Start Saving's deprecated, this is ready to switch as well.
            // onClick={() => navigate(getAbsoluteRoute('helpCenter.sendrx'))}
            onClick={() => navigate(getAbsoluteRoute('startSaving'))}
        >
            <Body1>{t('helpCenter.gettingStarted.description')}</Body1>
            <Box>
                <TextButton RightIcon={IconChevronRight}>
                    {t('helpCenter.learnMore')}
                </TextButton>
            </Box>
        </IllustrationCard>
    );
};
