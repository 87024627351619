import { Anchor, Body1, Box, Heading2, Stack } from '@phx/design-system';
import { useDisclosure, useIsMobile } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { BenefitCardInfoModal } from './BenefitCardInfoModal';
import { ViewSpendSummarySection } from './ViewSpendSummarySection';

export const BenefitCardDetails = () => {
    const [showModal, handlers] = useDisclosure(false);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Stack>
            <Box>
                <Stack gap="sm">
                    <Heading2 data-testid="carousel-rx-card-title">
                        {t('planCards.benefit.heading')}
                    </Heading2>
                    <Box>
                        <Body1 data-testid="carousel-rx-card-caption">
                            {t('planCards.benefit.body')}
                        </Body1>
                        <Anchor
                            onClick={handlers.open}
                            data-testid="carousel-rx-card-learn-more-link"
                        >
                            {t('planCards.benefit.learnMore')}
                        </Anchor>
                    </Box>
                </Stack>
                <BenefitCardInfoModal
                    isOpen={showModal}
                    close={handlers.close}
                />
            </Box>
            {isMobile ? <ViewSpendSummarySection /> : null}
        </Stack>
    );
};
