import { CollapsibleCard, Flex, Stack } from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import type { GetClaimHistoryQuery } from '../../../graphql/generated/graphql';
import { formatToLocalizedDate } from '../../../util';
import DrugDetails from '../../common/DrugDetails';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import { DateFilled } from './details/DateFilled';
import { DeductibleAmount } from './details/DeductibleAmount';
import { LocationName } from './details/LocationName';
import { PaidAmount } from './details/PaidAmount';

export interface ClaimDetailsProps {
    claim: NonNullable<
        GetClaimHistoryQuery['patient']
    >['claimsConnection']['edges'][number]['node'];
}

const ClaimDetails = ({ claim }: ClaimDetailsProps) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { i18n } = useTranslation();

    const formattedDate =
        claim.fill.dateFilled &&
        formatToLocalizedDate(
            new Date(claim.fill.dateFilled),
            {
                dateStyle: 'medium',
                localeMatcher: 'best fit',
            },
            i18n.language
        );

    const product = getFragment(claim.fill.product);

    return (
        <CollapsibleCard
            variant="outlined"
            data-testid="claim-history-details"
            header={
                <>
                    {product.name}
                    <Flex data-testid="claim-details-expand-more-card-button">
                        <ConditionalComponent
                            condition={!!drugDetailsSplitEnabled}
                        >
                            <DrugDetails
                                product={product}
                                quantity={claim.fill.quantity}
                            />
                        </ConditionalComponent>
                    </Flex>
                </>
            }
            footer={<PaidAmount amount={claim.patientPayAmount} />}
        >
            <Stack
                gap="xxs"
                data-testid="claim-history-pharmacy-details-and-deductible-contributions"
            >
                <DateFilled formattedDate={formattedDate} />
                <LocationName
                    location={
                        getFragment(getFragment(claim.fill.location))?.name
                    }
                />
                <DeductibleAmount amount={claim.amountTowardDeductible} />
            </Stack>
        </CollapsibleCard>
    );
};

export default ClaimDetails;
