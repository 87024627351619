import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import StartSavingRoute from './StartSavingRoute';

const startSavingRoutes: RouteObject[] = [{
    path: routes.startSaving,
    element: <StartSavingRoute />,
}];

export default startSavingRoutes;
