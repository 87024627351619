import {
    Anchor,
    Box,
    Flex,
    Heading1,
    HorizontalDivider,
    IconOpenInNew,
    Modal,
    NavLink,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { type CoverageInfoFragment } from '../../graphql/generated/graphql';

import { FamilySummaryCard } from './components/FamilySummaryCard';
import { IndividualSummaryCard } from './components/IndividualSummaryCard';
import { ModalContent } from './components/ModalContent';

export type PlanSummaryProps = {
    coverageEdges: CoverageInfoFragment[];
    onClaimHistoryClick: () => void;
};

const PlanSummary = ({
    coverageEdges,
    onClaimHistoryClick,
}: PlanSummaryProps) => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <Stack gap="lg">
            <Flex align="center" justify="space-between">
                <Heading1>{t('planSummary.title')}</Heading1>
                <TextButton
                    LeftIcon={IconOpenInNew}
                    color="primary"
                    onClick={() =>
                        //NOTE: Consider this link as an i18n string when the PDF is able to be provided in other languages
                        window.open(
                            'https://strapimediaprod.blob.core.windows.net/media/assets/At_a_glance_PHX_2023_Final_2_afd9a70966.pdf'
                        )
                    }
                >
                    {t('planSummary.downloadPlanDetails')}
                </TextButton>
            </Flex>
            <Stack gap="md">
                <IndividualSummaryCard coverages={coverageEdges} />
                <FamilySummaryCard coverages={coverageEdges} />
            </Stack>
            <Box>
                <Anchor onClick={open}>
                    {t('planSummary.learnMoreAnchor')}
                </Anchor>
            </Box>
            <Modal
                title={t('planSummary.aboutModal')}
                onClose={close}
                opened={opened}
            >
                <ModalContent />
            </Modal>
            <HorizontalDivider />
            <NavLink
                label={t('planSummary.claimHistory')}
                onClick={onClaimHistoryClick}
                variant="subtle"
                data-testid="plan-summary-claim-history"
            />
        </Stack>
    );
};

export default PlanSummary;
