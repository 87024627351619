import { HorizontalDivider, Stack } from '@phx/design-system';
import { Fragment } from 'react';

import { DEFAULT_INITIAL_PRESCRIPTION_COUNT } from '../../../hooks/use-visible-prescription-count';
import type { BasicPrescriberOrderFragment } from '../../../util/cabinet/prescriber-orders';

import { MedicationOrder } from './MedicationOrder';

export type MedicationOrdersProps = {
    orders: BasicPrescriberOrderFragment[];
    showAll?: boolean;
    visibleCount?: number;
};

export const MedicationOrders = ({
    orders,
    showAll,
    visibleCount = DEFAULT_INITIAL_PRESCRIPTION_COUNT,
}: MedicationOrdersProps) => {
    return (
        <Stack gap="sm" role="list">
            {orders
                .slice(0, showAll ? visibleCount : 3)
                .map((medication, idx) => (
                    <Fragment key={medication.id}>
                        {idx > 0 && <HorizontalDivider />}
                        <MedicationOrder order={medication} />
                    </Fragment>
                ))}
        </Stack>
    );
};
