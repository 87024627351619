import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';

import { getMinCost, getMinPlanPays } from './get-minimum-cost';

type SortFunction = (
    offers: ProviderOffersInfoFragment[]
) => ProviderOffersInfoFragment[];

export const sortOptions = {
    distance: 'distance',
    price: 'price',
    planPays: 'planPays',
    deliveryTime: 'deliveryTime',
} as const;

export type SortOption = keyof typeof sortOptions;

/**
 * Sorts an array of provider offers by the minimum amount that the insurance plan pays.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the minimum plan pays amount
 */
export function sortOffersByPlanPays(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinPlanPays = getMinPlanPays(
            a.offers as InsuranceOfferInfoFragment[]
        );
        const bMinPlanPays = getMinPlanPays(
            b.offers as InsuranceOfferInfoFragment[]
        );

        return aMinPlanPays - bMinPlanPays;
    });
}

/**
 * Sorts an array of provider offers by the lowest cost between cash and insurance offers.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the lowest cost
 */
export function sortOffersByPrice(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinCost = getMinCost(
            a.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );
        const bMinCost = getMinCost(
            b.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );

        return aMinCost - bMinCost;
    });
}

/**
 * Sorts an array of provider offers by distance.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by closest distance to furthest
 */
export function sortOffersByDistance(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => a.distance - b.distance);
}

/**
 * Sorts an array of provider offers by delivery time.
 * Prioritizes the highest delivery time (either deliveryDaysMax or deliveryDaysMin if deliveryDaysMax is not
 * available). Offers with null delivery times are treated as Infinity, ensuring they are sorted to the end.
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by fastest delivery time to slowest
 */
export function sortOffersByDeliveryTime(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const getDeliveryTime = (offer: ProviderOffersInfoFragment) => {
            if (offer.provider.__typename === 'MailOrderProvider') {
                const fragment = getFragment(offer.provider);
                const maxDeliveryTime = fragment.deliveryDaysMax ?? Infinity;
                const minDeliveryTime = fragment.deliveryDaysMin ?? Infinity;
                return maxDeliveryTime !== Infinity
                    ? maxDeliveryTime
                    : minDeliveryTime;
            }
            return Infinity;
        };

        const aDeliveryTime = getDeliveryTime(a);
        const bDeliveryTime = getDeliveryTime(b);

        return aDeliveryTime - bDeliveryTime;
    });
}

const sortFunctions: Map<SortOption, SortFunction> = new Map([
    [sortOptions.distance, sortOffersByDistance],
    [sortOptions.deliveryTime, sortOffersByDeliveryTime],
    [sortOptions.planPays, sortOffersByPlanPays],
    [sortOptions.price, sortOffersByPrice],
]);

/**
 * Sorts an array of provider offers based on the selected sorting option.
 *
 * @param offers An array of provider offers
 * @param sortBy The sorting option selected (e.g., distance, delivery time, price)
 * @returns The sorted array of provider offers based on the selected sorting criteria
 */
export function sortProviderOffers(
    offers: ProviderOffersInfoFragment[],
    sortBy: SortOption
): ProviderOffersInfoFragment[] {
    const sortFunction = sortFunctions.get(sortBy);
    return sortFunction ? sortFunction(offers) : offers;
}
