import { constants } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';
import { formatToLocalizedCurrency } from '../util';

export const useGetPricingDetail = () => {
    const { t } = useTranslation();
    return (
        type: 'insurance' | 'cash',
        patientPay: number,
        planPays?: number | null
    ) => {
        const value =
            typeof patientPay === 'number'
                ? formatToLocalizedCurrency(patientPay)
                : '';
        const formattedPlanPrice =
            type === 'insurance' && planPays !== undefined && planPays !== null
                ? formatToLocalizedCurrency(planPays)
                : '';

        switch (type) {
            case 'insurance':
                return {
                    option: t('offers.pricingInfo.withInsurance'),
                    value,
                    details: t('offers.pricingInfo.planPaysAmount', {
                        planPaysAmount: formattedPlanPrice,
                    }),
                };
            case 'cash':
            default:
                return {
                    option: t('offers.pricingInfo.smartprice', {
                        tm: constants.unicode.TRADEMARK,
                    }),
                    value,
                    details: t('offers.pricingInfo.priceWithoutInsurance'),
                };
        }
    };
};
