import { Body1, Box, Button, Flex, Stack, Subtitle1 } from '@phx/design-system';
import { NoClaimsIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';

export const NoClaims = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack gap="lg">
            <Flex justify="center">
                <NoClaimsIllustration data-testid="empty-state-claim-history-shopping-cart-pills-image" />
            </Flex>
            <Stack
                gap="sm"
                align="center"
                data-testid="empty-state-claim-history-text-component"
            >
                <Subtitle1>{t('claimHistory.noClaims.noClaims')}</Subtitle1>
                <Box ta="center">
                    <Body1 data-testid="empty-state-claim-history-text-description">
                        {t('claimHistory.noClaims.purchaseMedication')}
                    </Body1>
                </Box>
            </Stack>
            <Button onClick={() => navigate(getAbsoluteRoute('home'))}>
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};
