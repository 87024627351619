export type SupportedLanguage = 'en' | 'es';
export type LanguageProperties = { name: string; native: string };

export const DEFAULT_LANGUAGE: SupportedLanguage = 'en';

export const languageMapping = new Map<SupportedLanguage, LanguageProperties>([
    ['en', { name: 'English', native: 'English' }],
    ['es', { name: 'Spanish', native: 'Español' }],
]);

export const getConfirmedLanguage = (lang: string): SupportedLanguage => {
    const languageCodeKey = lang as SupportedLanguage;

    if (languageMapping.has(languageCodeKey)) {
        return lang as SupportedLanguage;
    }

    return 'en';
};

export const getDisplayLanguage = (
    languageCode: string
): LanguageProperties => {
    const languageCodeKey = languageCode as SupportedLanguage;

    if (languageMapping.has(languageCodeKey)) {
        return languageMapping.get(languageCodeKey)!;
    }

    return languageMapping.get(DEFAULT_LANGUAGE)!;
};
