import { Body1, Flex } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { titleCase } from '../../../../util';
import { ConditionalComponent } from '../../../utility/ConditionalComponent';

type LocationNameProps = {
    location?: string | null;
};

export const LocationName = ({ location }: LocationNameProps) => {
    const { t } = useTranslation();

    return (
        <ConditionalComponent condition={!!location}>
            <Flex justify="space-between">
                <Body1>{t('claimHistory.claimDetails.pharmacy')}</Body1>
                <Body1 data-testid="claim-history-details-pharmacy-name">
                    {titleCase(location!)}
                </Body1>
            </Flex>
        </ConditionalComponent>
    );
};
