import {
    Anchor,
    Body1,
    Box,
    Button,
    ElevatedCard,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { UpToDateIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFlags } from '../../../hooks/use-flags';
import { getAbsoluteRoute } from '../../../util';

export const CabinetUpToDateCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { medCabTransferEnabled } = useFlags();

    return (
        <Stack
            align="center"
            justify="center"
            data-testid="medicine-cabinet-card-up-to-date"
        >
            <UpToDateIllustration />
            <Subtitle1>{t('cabinet.upToDate.header')}</Subtitle1>
            <Body1 ta="center">{t('cabinet.upToDate.body')}</Body1>

            <Stack gap="lg" w="100%">
                {medCabTransferEnabled ? (
                    <ElevatedCard header={t('cabinet.transferCard.cardTitle')}>
                        <Stack gap="sm">
                            <Body1>{t('cabinet.transferCard.cardBody')}</Body1>
                            <Box>
                                <Button
                                    onClick={() =>
                                        navigate(
                                            getAbsoluteRoute('drug.search.root')
                                        )
                                    }
                                >
                                    {t('cabinet.transferCard.transferBtn')}
                                </Button>
                            </Box>
                        </Stack>
                    </ElevatedCard>
                ) : null}
                <ElevatedCard header={t('cabinet.emptyDetails.cardTitle')}>
                    <Stack gap="sm">
                        <Body1>{t('cabinet.emptyDetails.cardBody')}</Body1>
                        <Box>
                            <Anchor
                                onClick={() =>
                                    navigate(getAbsoluteRoute('startSaving'))
                                }
                            >
                                {t('cabinet.emptyDetails.learnMoreBtn')}
                            </Anchor>
                        </Box>
                    </Stack>
                </ElevatedCard>
            </Stack>
        </Stack>
    );
};
