import {
    Body1,
    Button,
    Heading1,
    HorizontalDivider,
    List,
    ListItem,
    Stack,
} from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { useTranslation } from 'react-i18next';

import type { UnclaimedPrescriberOrderInfoFragment } from '../../../../graphql/generated/graphql';
import { UnclaimedPrescriptionDescription } from '../UnclaimedPrescriptionDescription';

type VerifyPrescriptionNoAttemptsProps = {
    prescriberOrder: UnclaimedPrescriberOrderInfoFragment;
};

export const VerifyPrescriptionNoAttempts = ({
    prescriberOrder,
}: VerifyPrescriptionNoAttemptsProps) => {
    const { t } = useTranslation();

    return (
        <Stack gap="lg">
            <Heading1>{t('verifyPrescription.noAttempts.title')}</Heading1>
            <UnclaimedPrescriptionDescription
                prescriberOrder={prescriberOrder}
            />
            <HorizontalDivider />
            <Body1>{t('verifyPrescription.noAttempts.body')}</Body1>
            <List type="ordered">
                <ListItem>{t('verifyPrescription.noAttempts.point1')}</ListItem>
                <ListItem>{t('verifyPrescription.noAttempts.point2')}</ListItem>
            </List>
            <Button onClick={openTalkativeUI}>
                {t('contactUsWidget.button')}
            </Button>
        </Stack>
    );
};
