import { Avatar, Body2, Flex, Stack, Subtitle2 } from '@phx/design-system';
import { constants, getInitials } from '@phx/design-system/framework';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PatientInfoFragment } from '../../graphql/generated/graphql';
import { useUserPreferences } from '../../hooks/use-user-preferences';
import { formatToLocalizedDate } from '../../util';
import { getRelationshipDisplay } from '../../util/patient/get-relationship-display';

import { DependentDisplayValue } from './DependentDisplayValue';

export type DependentListItemType =
    | 'GUARDIAN'
    | 'DEPENDENT'
    | 'SPOUSE'
    | 'MYSELF'
    | 'PRIMARY';

export interface DependentListItemProps {
    memberId: string | null;
    patient: PatientInfoFragment;
    relationshipType: DependentListItemType;
}

export const DependentListItem = ({
    memberId,
    patient,
    relationshipType,
}: DependentListItemProps) => {
    const { language } = useUserPreferences();
    const { t } = useTranslation();

    const data = useMemo(() => {
        const dataItems = [];

        dataItems.push({
            display: t('member.dateOfBirth'),
            value: formatToLocalizedDate(
                new Date(patient.dateOfBirth),
                { dateStyle: 'medium' },
                language
            ),
        });

        if (memberId) {
            dataItems.push({
                display: t('member.memberId'),
                value: memberId,
            });
        }

        if (patient.nicknames.length) {
            dataItems.push({
                display: t('member.alternateNames'),
                value: patient.nicknames
                    .map((nick) => nick.fullName)
                    .join(` ${constants.unicode.BULLET} `),
            });
        }

        return dataItems;
    }, [language, memberId, patient]);

    const subtitle = getRelationshipDisplay(relationshipType);

    return (
        <Flex gap="xs" data-testid="dependent-list">
            <Avatar initials={getInitials(patient.fullName)} />
            <Stack gap="xxs">
                <Stack gap={0}>
                    <Subtitle2 data-testid="dependent-name">
                        {patient.fullName}
                    </Subtitle2>
                    {subtitle !== undefined ? (
                        <Body2 data-testid="dependent-subtitle">
                            {subtitle}
                        </Body2>
                    ) : null}
                </Stack>

                <Stack gap="xxxs">
                    {data.map((dependent) => (
                        <DependentDisplayValue
                            key={dependent.display}
                            item={dependent}
                        />
                    ))}
                </Stack>
            </Stack>
        </Flex>
    );
};

export default DependentListItem;
