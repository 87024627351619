import { useGeolocation } from '@phx/location-utils';
import { DistanceMeasurementUnit } from '../../../graphql/generated/graphql';

export const usePrescriptionInputVariables = (
    distance: number,
    patientId?: string
) => {
    const { geolocation } = useGeolocation();
    return {
        patientId,
        coordinatesInput: geolocation
            ? {
                  latitude: geolocation.latitude,
                  longitude: geolocation.longitude,
                  maxResults: 50,
                  radiusMiles: distance,
              }
            : undefined,
        filterBy: {
            distance: {
                unit: DistanceMeasurementUnit.Miles,
                value: distance,
            },
        },
    };
};
