import { AuthorizedOutlet } from '@phx/auth/client';
import type { RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import PlanSummaryRoute from './PlanSummaryRoute';

const planRoutes: RouteObject[] = [
    {
        path: routes.plan.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                path: routes.plan.summary,
                element: <PlanSummaryRoute />,
            },
        ],
    },
];

export default planRoutes;
