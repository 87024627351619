import { Caption, FilledCard, Heading2, Stack } from '@phx/design-system';
import { PricingInfo } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    NonPriceableOfferInfoFragment,
} from '../../../graphql/generated/graphql';

import { NoPricing } from './NoPricing';

export const MailOrderPricing = ({
    offer,
}: {
    offer:
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment
        | NonPriceableOfferInfoFragment;
}) => {
    const { t } = useTranslation();

    const priceSection =
        offer.__typename === 'CashOffer' ||
        offer.__typename === 'InsuranceOffer' ? (
            <PricingInfo
                option={offer.pricingDetail.option}
                value={offer.pricingDetail.value}
                details={offer.pricingDetail.details ?? ''}
            />
        ) : (
            <NoPricing />
        );

    return (
        <Stack gap="xxs" data-testid="price-quote-card">
            <Stack gap="sm">
                <Heading2>{t('pricing.subHeader')}</Heading2>
                <FilledCard color="gray">{priceSection}</FilledCard>
            </Stack>
            {/* Remove breakdown until actual data can be mapped
            <PricingInfo
                option={t('pricing.pricingInfo.deliveryFee')}
                value={`${formatToLocalizedCurrency(0)}`}
                details={t('pricing.pricingInfo.details')}
            /> */}
            {/* <FilledCard color="gray">
                <PricingInfo
                    option={t('pricing.pricingInfo.youPay')}
                    value={offer.pricingDetail.value}
                />
            </FilledCard> */}
            <Caption>{`${t('pricing.pricingInfo.pricesVary')} ${t('pricing.pricingInfo.shippingFeesVary')}`}</Caption>
        </Stack>
    );
};
