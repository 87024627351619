import type { FieldReadFunction } from '@apollo/client';

import { formatProductDescription } from './format-product-description';

export const readDescription: FieldReadFunction = (_, { readField }) => {
    const name = readField<string>('name') ?? '';
    const strengthText = readField<string>('strengthText');
    const strengthUnit = readField<string>('strengthUnit');
    const formValue = readField<string>('formValue');

    return formatProductDescription({
        name,
        strengthText,
        strengthUnit,
        formValue,
    });
};
