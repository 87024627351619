import { Body1 } from '@phx/design-system';

type TranslationBlock = {
    title?: string;
    content1: string;
    content2?: string;
};

export type TranslationBlocks = Array<TranslationBlock>;

export const asDefinitionListItem = (contentBlock: TranslationBlock) => {
    const { title, content1, content2 } = contentBlock;
    return {
        title,
        body: (
            <>
                <Body1>{content1}</Body1>
                {content2 && <Body1>{content2}</Body1>}
            </>
        ),
        key: content1,
    };
};
