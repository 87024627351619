import { Navigate, useNavigate } from 'react-router-dom';

import PlanSummary from '../../components/plan-summary/PlanSummary';
import { getFragment } from '../../graphql';
import { GetPatientDocument } from '../../graphql/generated/graphql';
import { isActiveDateRange } from '../../graphql/policies/util';
import { usePatientContext } from '../../hooks/use-patient-context';
import { QueryLoader } from '../../loaders';
import type { ResultOf } from '../../types';
import { getAbsoluteRoute } from '../../util';

const PlanSummaryRoute = () => {
    const Loader = QueryLoader<typeof GetPatientDocument>;
    const { primaryPatientId: patientId } = usePatientContext();

    return (
        <Loader
            query={GetPatientDocument}
            component={DataHandler}
            variables={{ patientId }}
        />
    );
};

type DataHandlerProps = { data: ResultOf<typeof GetPatientDocument> };

const DataHandler = ({ data }: DataHandlerProps) => {
    const coverageEdges = getFragment(data.patient)
        ?.coveragesConnection.edges.map((edge) => getFragment(edge.coverage))
        .filter((x) => isActiveDateRange(x.startDate, x.endDate));

    if (!coverageEdges) {
        return <Navigate to="/" />;
    }
    const navigate = useNavigate();

    return (
        <PlanSummary
            coverageEdges={coverageEdges}
            onClaimHistoryClick={() =>
                navigate(getAbsoluteRoute('claims.history'))
            }
        />
    );
};

export default PlanSummaryRoute;
