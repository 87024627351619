import { Body1, Modal, Stack, Subtitle2 } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

type TotalQuantityDialogProps = {
    formulaText: string;
    isOpen: boolean;
    onClose: () => void;
};
export const TotalQuantityDialog = ({
    formulaText,
    isOpen,
    onClose,
}: TotalQuantityDialogProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('drugConfig.totalQuantity')}
            opened={isOpen}
            onClose={onClose}
        >
            <Stack gap="sm">
                <Body1>{t('drugConfig.totalQuantityDescription')}</Body1>
                <Stack gap="xxs">
                    <Subtitle2>{t('drugConfig.relevantFields')}</Subtitle2>
                    <Body1>{t('drugConfig.calculateDescription')}</Body1>
                </Stack>
                <Stack gap="xxxs" p={2}>
                    <Body1>
                        {constants.unicode.BULLET}{' '}
                        {t('drugConfig.packagingForm')}
                    </Body1>
                    <Body1>
                        {constants.unicode.BULLET} {t('drugConfig.quantity')}
                    </Body1>
                </Stack>
                <Stack gap="xxs">
                    <Subtitle2>{t('drugConfig.totalQuantityCalc')}</Subtitle2>
                    <Body1 bold color="primary">
                        {formulaText}
                    </Body1>
                </Stack>
            </Stack>
        </Modal>
    );
};
