import { useFlags as useLDFlags, } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useFlagOverrides } from './use-flag-overrides';
/**
 * This hook should not be used directly in most cases as each consumer should
 * wrap this hook with associated type and useOverrides logic. It has been
 * marked deprecated to make clear that it should not be imported directly in
 * most contexts.
 * @internal
 * @deprecated
 */
export const useFlags = (useOverrides = true) => {
    const ldFlags = useLDFlags();
    const { overriddenFlags } = useFlagOverrides();
    return useMemo(() => {
        if (useOverrides) {
            return { ...ldFlags, ...overriddenFlags };
        }
        return ldFlags;
    }, [ldFlags, overriddenFlags, useOverrides]);
};
