import { OutlinedCard } from '@phx/design-system';

import {
    type GetBasicPrescriberOrdersByPatientQuery,
    type GetBasicPrescriberOrdersQuery,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    isNewOrder,
    usePrescriberOrders,
} from '../../hooks/use-prescriber-orders';
import { MedicationOrders } from '../cabinet/orders-card/MedicationOrders';
import { OrdersCardHeader } from '../cabinet/orders-card/OrdersCardHeader';

import { HomeCabinetNoOrdersCard } from './HomeCabinetNoOrdersCard';
import { HomeCabinetUpToDateCard } from './HomeCabinetUpToDateCard';

export type HomeMedCabCardProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
    patientId: string;
    showAll?: boolean;
};

export const HomeMedCabCard = ({
    patient,
    patientId,
    showAll,
}: HomeMedCabCardProps) => {
    const { primaryPatientId } = usePatientContext();

    const orders = usePrescriberOrders({
        patient,
        patientId,
        primaryPatientId,
    });

    const newOrders = orders.filter(isNewOrder);

    if (orders.length === 0) {
        return <HomeCabinetNoOrdersCard />;
    }

    if (newOrders.length === 0) {
        return <HomeCabinetUpToDateCard />;
    }

    return (
        <OutlinedCard
            header={<OrdersCardHeader count={newOrders.length} />}
            role="list"
        >
            <MedicationOrders orders={newOrders} showAll={showAll} />
        </OutlinedCard>
    );
};
