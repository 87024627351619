import { useContext, useMemo } from 'react';

import { FavoritePharmacies } from '../../components/pharmacy/FavoritePharmacies';
import { PatientContext } from '../../contexts/patient/PatientProvider';
import { GetPatientFavoriteLocationsDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';

const FavoritePharmaciesRoute = () => {
    const Loader = QueryLoader<typeof GetPatientFavoriteLocationsDocument>;
    const { primaryPatientId: patientId } = useContext(PatientContext);

    return (
        <Loader
            query={GetPatientFavoriteLocationsDocument}
            variables={{ patientId }}
            component={({ data }) => {
                if (!data.patient) {
                    // @TODO: Log telemetry and handle error behavior
                    return <>error</>;
                }
                const locations =
                    data.patient.preferences.patientFavoriteLocationsConnection.edges.map(
                        (x) => x.node
                    );

                const locationsReversed = useMemo(
                    () => [...locations].reverse(),
                    [locations.length]
                );
                return <FavoritePharmacies locations={locationsReversed} />;
            }}
        />
    );
};

export default FavoritePharmaciesRoute;
