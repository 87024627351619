import { Body1, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const ApprovedDescription = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Body1>
                {t('priorAuthorization.statusPage.approved.description')}
            </Body1>
            <Body1>{t('priorAuthorization.statusPage.approved.stepOne')}</Body1>
            <Body1>{t('priorAuthorization.statusPage.approved.stepTwo')}</Body1>
            <Body1>
                {t('priorAuthorization.statusPage.approved.stepThree')}
            </Body1>
            <Body1>
                {t('priorAuthorization.statusPage.approved.footNote')}
            </Body1>
        </Stack>
    );
};
