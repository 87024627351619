import { Carousel, Stack } from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useCallback, useMemo, useState } from 'react';

import { useCoverageCardData } from '../../hooks/use-coverage-card-data';

import { BenefitCardDetails } from './BenefitCardDetails';
import { SmartpriceCardDetails } from './SmartpriceCardDetails';

export const PlanCardsCarousel = () => {
    const [visibleCardIndex, setVisibleCardIndex] = useState(0);

    const coverages = useCoverageCardData();
    const onSlideChange = useCallback(
        (slide: number) => {
            setVisibleCardIndex(slide);
        },
        [setVisibleCardIndex]
    );

    const [cards, details] = useMemo(() => {
        const coverageCards = [];
        const detailSections = [];

        for (const coverage of coverages) {
            coverageCards.push(
                <CoverageCard
                    key={`${coverage.variant}-${coverage.planInfo.bin}`}
                    {...coverage}
                />
            );

            if (coverage.variant === 'benefit') {
                detailSections.push(<BenefitCardDetails key="benefit" />);
            } else {
                detailSections.push(<SmartpriceCardDetails key="smartprice" />);
            }
        }

        return [coverageCards, detailSections];
    }, [coverages]);

    return (
        <Stack gap="lg">
            <Carousel
                cropped
                slides={cards}
                indicatorOffset="-1rem"
                onSlideChange={onSlideChange}
            />
            {details[visibleCardIndex]}
        </Stack>
    );
};
