import { useRef, useSyncExternalStore } from 'react';

import type { SupportedLanguage } from '../util/i18n';

export type StoredPreferences = {
    language: SupportedLanguage;
};

export const USER_PREFS_LOCALSTORAGE_KEY = 'phx:user_preferences';

const DEFAULT_PREFERENCES: StoredPreferences = {
    language: 'en',
};

export const clearStoredPreferences = () => {
    window.localStorage.removeItem(USER_PREFS_LOCALSTORAGE_KEY);
    window.dispatchEvent(
        new StorageEvent('storage', {
            key: USER_PREFS_LOCALSTORAGE_KEY,
            newValue: null,
        })
    );
};

export const updateStoredPreferences = (
    payload: Partial<StoredPreferences>
) => {
    try {
        const storedData = localStorage.getItem(USER_PREFS_LOCALSTORAGE_KEY);
        const prevPrefs = storedData
            ? (JSON.parse(storedData) as StoredPreferences)
            : DEFAULT_PREFERENCES;

        const newValue: StoredPreferences = { ...prevPrefs, ...payload };

        window.localStorage.setItem(
            USER_PREFS_LOCALSTORAGE_KEY,
            JSON.stringify(newValue)
        );
        window.dispatchEvent(
            new StorageEvent('storage', {
                key: USER_PREFS_LOCALSTORAGE_KEY,
                newValue: JSON.stringify(newValue),
            })
        );
    } catch (error) {
        console.error(error, JSON.stringify(payload));
    }
};

export const useUserPreferences = (): StoredPreferences => {
    const latestKey = useRef<null | string>(null);
    const latestValue = useRef<null | StoredPreferences>(null);

    const localStore = {
        getSnapshot: () => {
            const storedData = localStorage.getItem(
                USER_PREFS_LOCALSTORAGE_KEY
            );
            if (!storedData) {
                latestKey.current = null;
                latestValue.current = null;

                return DEFAULT_PREFERENCES;
            }

            try {
                if (
                    storedData === latestKey.current &&
                    latestValue.current !== null
                ) {
                    return latestValue.current;
                }

                latestKey.current = storedData;
                latestValue.current = JSON.parse(
                    storedData
                ) as StoredPreferences;

                return latestValue.current;
            } catch (error) {
                console.error(error);
                // @TODO: telemetry?
                return DEFAULT_PREFERENCES;
            }
        },
        subscribe: (listener: () => void) => {
            window.addEventListener('storage', listener);

            return () => void window.removeEventListener('storage', listener);
        },
    };

    return useSyncExternalStore(localStore.subscribe, localStore.getSnapshot);
};
