import type { Reference } from '@apollo/client';
import { geolocationDistance } from '@phx/location-utils';

import {
    isPhxBenefitsPlan,
    isSmartpricePlan,
    isValidPatientCost,
    isValidPlanCost,
} from '../../util';

import { sortByOption } from './price-quote-sort';

interface LocationForResult {
    __ref: string;
    id: string;
    longitude: number;
    latitude: number;
    chainCode?: string | undefined;
}

interface PriceQuoteForResult {
    __ref: string;
    plan?: { __ref: string; __typename: string } | undefined;
    patientResponsibilityCost?: number | undefined;
    planPaysAmount?: number | undefined;
    location: LocationForResult;
    product: {
        __ref: string;
        id: string;
    };
}

interface PriceSetForResult {
    __typename: 'PharmacyPriceSet';
    id: string;
    prices: PriceQuoteForResult[];
    distance: number | null;
    location: LocationForResult;
    productId?: string;
    invalidPrices?: PriceQuoteForResult[]; //record of invalid quotes that get filtered out of prices.
}

interface ChainPriceSetForResult {
    __typename: 'ChainPharmacyPriceSet';
    id: string;
    pharmacyPrices: PriceSetForResult[];
}
interface QueryResult {
    priceQuotesConnection: {
        edges: {
            node: PriceQuoteForResult;
        }[];
    };
    cheapestPriceQuote?: {
        location: {
            id: string;
        };
    };
}

interface VariablesForPriceQuoteResult {
    coordinatesParams: { longitude: number; latitude: number } | undefined;
    prescriberOrderId: string | undefined;
    productIds: string[] | undefined;
}

export const getPriceQuoteResult = (
    queryResult: QueryResult,
    variables: VariablesForPriceQuoteResult,
    addPlanToPriceQuote: (priceQuote: Reference) => void
) => {
    const priceQuotesConnection = queryResult.priceQuotesConnection;

    const isShoppingRequest = !variables.prescriberOrderId;
    const geolocation = variables.coordinatesParams; // ?? UserVariables.geolocationVar();
    if (!geolocation) {
        throw new Error('TODO: reactive-variables');
    }

    const priceQuotesByLocation: Record<string, PriceSetForResult> =
        groupPriceQuotesByLocation(
            priceQuotesConnection,
            geolocation,
            isShoppingRequest,
            addPlanToPriceQuote
        );

    fixEmptyPriceList(priceQuotesByLocation);
    const bestPriceLocationId = queryResult?.cheapestPriceQuote?.location?.id;

    const priceQuotesByChain = groupPriceQuotesByChain(
        priceQuotesByLocation,
        bestPriceLocationId
    );

    const allGroupedPriceQuotes = [...priceQuotesByChain.values()];
    const productIdKey =
        variables?.productIds?.join(',') ??
        findProductId(allGroupedPriceQuotes[0]) ??
        '';
    const result = {
        __typename: 'PriceQuoteResult',
        id: productIdKey + JSON.stringify(variables),
        pharmacyPriceSets: allGroupedPriceQuotes,
    };
    return result;
};

function fixEmptyPriceList(priceSets: Record<string, PriceSetForResult>) {
    Object.entries(priceSets).forEach(([_key, priceSet]) => {
        if (!priceSet.prices?.length && priceSet?.invalidPrices?.length) {
            if (priceSet?.invalidPrices.length > 1) {
                priceSet.prices.push(priceSet.invalidPrices[0]);
            } else {
                const insurancePrice = priceSet.invalidPrices.find(
                    (price) => price.plan && isSmartpricePlan(price?.plan)
                );
                priceSet.prices.push(
                    insurancePrice ? insurancePrice : priceSet.invalidPrices[0]
                );
            }
        }
    });
}

function groupPriceQuotesByChain(
    priceQuotesByLocation: Record<string, PriceSetForResult>,
    bestPriceLocationId: string | undefined
) {
    // TODO: reactive-variables
    const queryOptions = { sortBy: 'distance' }; //QueryVariables.pricingQueryOptions();
    const sortedLocations = Object.values(priceQuotesByLocation).sort(
        (a: PriceSetForResult, b: PriceSetForResult) =>
            sortByOption[queryOptions.sortBy](a, b, bestPriceLocationId)
    );
    return sortedLocations.reduce(
        (
            all: Map<string, PriceSetForResult | ChainPriceSetForResult>,
            { productId, ...priceSet }: PriceSetForResult
        ) => {
            const chainCode = priceSet.location.chainCode;
            if (chainCode) {
                const chainKey = chainCode + (productId ?? ''); // Include productId so these values are cached by this combination
                const existing =
                    (all.get(chainKey) as ChainPriceSetForResult)
                        ?.pharmacyPrices ?? [];
                return all.set(chainKey, {
                    __typename: 'ChainPharmacyPriceSet' as const,
                    id: chainKey,
                    pharmacyPrices: [...existing, priceSet],
                });
            }
            return all.set(priceSet.id, priceSet);
        },
        // Map is used to retain insert/sort order
        new Map<string, PriceSetForResult | ChainPriceSetForResult>()
    );
}

function groupPriceQuotesByLocation(
    priceQuotesConnection: QueryResult['priceQuotesConnection'],
    geolocation: { latitude: number; longitude: number } | null,
    isShoppingRequest: boolean,
    addPlanToPriceQuote: (priceQuote: Reference) => void
) {
    const priceQuotesByLocation: Record<string, PriceSetForResult> = {};

    //move smart price quotes to bottom of price lists
    priceQuotesConnection.edges?.sort((a) =>
        a.node.plan && isSmartpricePlan(a.node?.plan) ? 1 : -1
    );
    priceQuotesConnection?.edges.forEach((edge) => {
        const priceQuote = edge.node;
        const { plan, patientResponsibilityCost, location, planPaysAmount } =
            priceQuote;

        const isValidPriceQuote =
            isValidPatientCost({ patientResponsibilityCost }) &&
            (!(plan && isPhxBenefitsPlan(plan)) ||
                isValidPlanCost({ planPaysAmount }));

        // Skipping here hides the card/location entirely
        // For prescription requests we want to show 'contact the pharmacy' message, so we don't skip here
        if (isShoppingRequest && !isValidPriceQuote) {
            return;
        }

        const { longitude, latitude } = location;

        const distance = geolocation
            ? geolocationDistance(geolocation, { latitude, longitude })
            : null;

        // Include productId so these values are cached and grouped by this combination
        const pricingKey = location.id + priceQuote.product.id;
        const existingPrices = priceQuotesByLocation[pricingKey]?.prices ?? [];
        const existingInvalidPrices =
            priceQuotesByLocation[pricingKey]?.invalidPrices ?? [];

        const isSmartprice =
            priceQuote?.plan && isSmartpricePlan(priceQuote?.plan);
        if (isSmartprice && !plan) {
            addPlanToPriceQuote(priceQuote);
        }

        // For prescription requests, if the price quote is not valid we add the location to the list. Then add the priceQuote to the invalidPrices list.
        let prices = [...existingPrices, priceQuote];

        let invalidPrices = [...existingInvalidPrices];

        // Keep a record of invalidPrice quotes we have filtered out from prices.
        // In the event that we do not have ANY valid prices we still need a price quote to send to the pharmacy.
        if (!isValidPriceQuote) {
            //invalid price keep existing prices and add current price to invalid list
            prices = existingPrices;
            invalidPrices = [...existingInvalidPrices, priceQuote];
        }

        priceQuotesByLocation[pricingKey] = {
            ...(priceQuotesByLocation[pricingKey] ?? {}),
            __typename: 'PharmacyPriceSet',
            id: pricingKey,
            location,
            distance,
            prices,
            productId: priceQuote.product.id,
            invalidPrices,
        };
    });
    return priceQuotesByLocation;
}

function findProductId(set: PriceSetForResult | ChainPriceSetForResult) {
    if (set?.__typename === 'PharmacyPriceSet') {
        return set.productId ?? '';
    } else if (set?.__typename === 'ChainPharmacyPriceSet') {
        return set.pharmacyPrices?.[0]?.productId ?? '';
    }
    return undefined;
}
