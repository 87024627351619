import type { FieldReadFunction } from '@apollo/client';

import type {
    PatientFavoriteLocationsConnection,
    PatientPreferences,
} from '../../generated/graphql';

export const readHasFavoritePharmacies: FieldReadFunction = (
    _,
    { readField }
) => {
    const preferences = readField<PatientPreferences>('preferences');

    if (!preferences) {
        return false;
    }

    const connection = readField<PatientFavoriteLocationsConnection>(
        'patientFavoriteLocationsConnection',
        preferences
    );

    return !!connection?.edges.length;
};

export const patientPreferences = {
    hasFavoritePharmacies: {
        read: readHasFavoritePharmacies,
    },
};
