import {
    Accordion,
    Body1,
    Cover,
    Heading1,
    Heading2,
    HorizontalDivider,
    Stack,
    Subtitle1,
    useNavBar,
} from '@phx/design-system';
import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactUsWidget } from '../../common/ContactUsWidget';

type PriorAuthInfoCoverProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const PriorAuthInfoCover = ({
    isOpen,
    onClose,
}: PriorAuthInfoCoverProps) => {
    const { t } = useTranslation();
    const { setBackOverrideState } = useNavBar();

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setBackOverrideState({
            overrideFn: onClose,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [isOpen, onClose]);

    const paStatusInfo = useMemo(() => {
        const statuses = t('priorAuthorization.cover.statuses', {
            returnObjects: true,
        });

        if (!Array.isArray(statuses)) {
            return [];
        }

        return statuses.map(({ status, description }) => (
            <Fragment key={status}>
                <Subtitle1>{status}</Subtitle1>
                <Body1>{description}</Body1>
            </Fragment>
        ));
    }, [t]);

    const faqs = useMemo(() => {
        const faqItems = t('priorAuthorization.cover.faq.items', {
            returnObjects: true,
        });

        if (!Array.isArray(faqItems)) {
            return [];
        }

        return faqItems.map((faq) => ({
            header: faq.question,
            key: faq.question,
            children: <Body1>{faq.answer}</Body1>,
        }));
    }, [t]);

    return (
        <Cover visible={isOpen}>
            <Stack gap="xxl" pb="xl">
                {' '}
                {/* remove bottom padding when cover page is updated */}
                <Stack gap="sm">
                    <Heading1>{t('priorAuthorization.cover.heading')}</Heading1>
                    <Body1>{t('priorAuthorization.cover.subtitle1')}</Body1>
                    <Body1>{t('priorAuthorization.cover.subtitle2')}</Body1>
                    <Stack gap="xxs">{paStatusInfo}</Stack>
                </Stack>
                <Stack gap="sm">
                    <Heading2>
                        {t('priorAuthorization.cover.faq.heading')}
                    </Heading2>
                    <Body1>{t('priorAuthorization.cover.faq.subtitle')}</Body1>
                    <Accordion items={faqs} />
                    <HorizontalDivider />
                    <ContactUsWidget />
                </Stack>
            </Stack>
        </Cover>
    );
};
