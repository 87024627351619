import { Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { PharmacyInfo } from '@phx/myphx-lib';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import { type GetPatientFavoriteLocationsQuery } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { convertLocationToPharmacyInfo } from '../../util/pharmacy/convert-location-info';

type Location = NonNullable<
    GetPatientFavoriteLocationsQuery['patient']
>['preferences']['patientFavoriteLocationsConnection']['edges'][0]['node'];

export const FavoritePharmacies = ({
    locations,
}: {
    locations: Location[];
}) => {
    const { t } = useTranslation();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;
    return (
        <Stack gap="lg">
            <Heading1>{t('pharmacy.favorites.header')}</Heading1>
            <Stack>
                {locations.map((location, idx) => {
                    const locationInfoFragment = getFragment(location);

                    return (
                        <Fragment key={`pharmacy-info-container-${idx}`}>
                            {idx !== 0 ? <HorizontalDivider /> : null}
                            <PharmacyInfo
                                {...convertLocationToPharmacyInfo(
                                    locationInfoFragment
                                )}
                                favorite={checkIsFavorite(location.id)}
                                toggleFavorite={() =>
                                    toggleFavorite(location.id)
                                }
                                day={t('pharmacy.hours.today')}
                            />
                        </Fragment>
                    );
                })}
            </Stack>
        </Stack>
    );
};
