import {
    Affix,
    Box,
    Flex,
    IconButton,
    IconFlag,
    IconLogoDev,
    IconTranslate,
    Transition,
} from '@phx/design-system';
import { useClickOutside, useDisclosure } from '@phx/design-system/hooks';
import { Suspense, useCallback } from 'react';
import { showTranslations } from 'translation-check';

import i18n from '../../../i18n';

import { FlagPanel } from './flag-panel/FlagPanel';

const transition = {
    in: {
        opacity: 1,
        transform: 'translateY(-50%) translateX(4.5rem)',
    },
    out: { opacity: 0, transform: 'translateY(-50%) translateX(-100%)' },
    common: {
        top: '50%',
        transformOrigin: 'right',
        whiteSpace: 'nowrap',
        width: 'auto',
    },
    transitionProperty: 'transform, opacity',
};

export const DevTools = () => {
    const [showTools, handlers] = useDisclosure(false);
    const [showFlagsPanel, flagHandlers] = useDisclosure(false);
    const clickOutsideRef = useClickOutside(handlers.close);

    const openMiniLocize = useCallback(async () => {
        handlers.close();
        await i18n.changeLanguage('es');
        await i18n.changeLanguage('en');
        showTranslations(i18n, { sourceLng: 'en', targetLngs: ['es'] });
    }, [handlers.close]);

    const openFlags = useCallback(() => {
        handlers.close();
        flagHandlers.open();
    }, [handlers.close, flagHandlers.open]);

    return (
        <Affix id="devtools" position={{ left: '1rem', bottom: '1rem' }}>
            <Box pos="relative">
                <Suspense>
                    <IconButton Icon={IconLogoDev} onClick={handlers.toggle} />
                    <Transition mounted={showTools} transition={transition}>
                        {(styles) => (
                            <Flex
                                ref={clickOutsideRef}
                                pos="absolute"
                                gap="md"
                                style={{
                                    ...styles,
                                    transformOrigin: 'bottom',
                                }}
                            >
                                <IconButton
                                    Icon={IconTranslate}
                                    onClick={
                                        showTools ? openMiniLocize : undefined
                                    }
                                />
                                <IconButton
                                    Icon={IconFlag}
                                    onClick={openFlags}
                                />
                            </Flex>
                        )}
                    </Transition>
                </Suspense>
            </Box>
            <FlagPanel opened={showFlagsPanel} onClose={flagHandlers.close} />
        </Affix>
    );
};
