import { type TimelineStep } from '@phx/design-system';
import type { TFunction } from 'i18next';
import type { ReactNode } from 'react';

import {
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationStatus,
} from '../../../graphql/generated/graphql';
import { formatDateString } from '../../../util';

const createStep = (
    title: string,
    description?: string,
    subtitle?: string | ReactNode
): TimelineStep => ({
    title,
    description,
    subtitle,
});

const handleAppealSteps = (
    steps: TimelineStep[],
    t: (key: string) => string,
    dates: Partial<Record<PriorAuthorizationStatus, string>>,
    currentStatus: PriorAuthorizationStatus,
    estimatedCompletionDate: string
): TimelineStep[] => {
    const appealSteps = [
        createStep(t('priorAuthorization.status.Denied'), dates?.DENIED),
        createStep(t('priorAuthorization.status.Appealed'), dates?.APPEALED),
    ];

    switch (currentStatus) {
        case PriorAuthorizationStatus.Appealed:
            appealSteps.push(
                createStep(
                    t('priorAuthorization.status.AppealComplete'),
                    undefined,
                    estimatedCompletionDate
                )
            );
            break;
        case PriorAuthorizationStatus.AppealApproved:
            appealSteps.push(
                createStep(
                    t('priorAuthorization.status.AppealApproved'),
                    dates?.[currentStatus]
                )
            );
            break;
        case PriorAuthorizationStatus.AppealDenied:
            appealSteps.push(
                createStep(
                    t('priorAuthorization.status.AppealDenied'),
                    dates?.[currentStatus]
                )
            );
            break;
    }

    const updatedSteps = [...steps];
    updatedSteps.splice(3, 1, ...appealSteps);
    return updatedSteps;
};

export const generateSteps = (
    priorAuthInfo: PriorAuthorizationInfoFragment,
    t: TFunction,
    locale?: string,
    cancelledSubtitle?: ReactNode
): TimelineStep[] => {
    const currentStatus = priorAuthInfo.status;
    const estimatedComplete = priorAuthInfo.estimatedCompletionDate
        ? t('priorAuthorization.status.estimated', {
              date: formatDateString(
                  priorAuthInfo.estimatedCompletionDate,
                  locale,
                  {
                      dateStyle: 'medium',
                  }
              ),
          })
        : t('priorAuthorization.status.completionDateTBD');
    const dates: Partial<Record<PriorAuthorizationStatus, string>> = {};

    priorAuthInfo.history.forEach((item) => {
        dates[item.currentStatus] = formatDateString(item.updatedAt, locale, {
            dateStyle: 'medium',
        });
    });

    const steps: TimelineStep[] = [
        createStep(
            t('priorAuthorization.status.New'),
            formatDateString(priorAuthInfo.createdAt, locale, {
                dateStyle: 'medium',
            })
        ),
        createStep(t('priorAuthorization.status.Received'), dates?.RECEIVED),
        createStep(t('priorAuthorization.status.InReview'), dates?.IN_REVIEW),
        createStep(
            t('priorAuthorization.status.Complete'),
            undefined,
            cancelledSubtitle ? null : estimatedComplete
        ),
    ];

    if (cancelledSubtitle) {
        const cancelIndex = steps.findIndex(
            (step) =>
                step.title === t(`priorAuthorization.status.${currentStatus}`)
        );

        if (cancelIndex !== -1) {
            steps[cancelIndex].subtitle = cancelledSubtitle;
        }
        return steps;
    }

    switch (currentStatus) {
        case PriorAuthorizationStatus.Approved:
        case PriorAuthorizationStatus.Denied:
            steps[3] = createStep(
                currentStatus === PriorAuthorizationStatus.Approved
                    ? t('priorAuthorization.status.Approved')
                    : t('priorAuthorization.status.Denied'),
                dates?.[currentStatus]
            );
            break;
        case PriorAuthorizationStatus.Appealed:
        case PriorAuthorizationStatus.AppealApproved:
        case PriorAuthorizationStatus.AppealDenied:
            return handleAppealSteps(
                steps,
                t,
                dates,
                currentStatus,
                estimatedComplete
            );
    }

    return steps;
};
