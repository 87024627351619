import { days } from '@phx/design-system';
import type { PharmacyInfoProps } from '@phx/myphx-lib';

import { getFragment } from '../../graphql';
import type { DayOfWeekLocationHoursInfoFragment } from '../../graphql/generated/graphql';

export const mapWeeklyHours = (
    weeklyHours: DayOfWeekLocationHoursInfoFragment
): PharmacyInfoProps['weeklyHours'] => {
    return days.reduce(
        (acc, day) => {
            if (!acc) {
                return;
            }
            acc[day] = [
                getFragment(weeklyHours[day][0])?.startTime ?? undefined,
                getFragment(weeklyHours[day][0])?.endTime ?? undefined,
            ];
            return acc;
        },
        {} as PharmacyInfoProps['weeklyHours']
    );
};
