import { useNavBar } from '@phx/design-system';
import { useGeolocation } from '@phx/location-utils';
import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { TransferFrom } from '../../components/transfer-flow/TransferFrom';
import { getFragment } from '../../graphql';
import { SearchPharmaciesDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

export const TransferLocationRoute = () => {
    const navigate = useNavigate();
    const { setBackOverrideState } = useNavBar();
    const { geolocation } = useGeolocation();
    // const distance = useNumericSearchParam('within');
    const { providerOffersId, offerId } = useParams();

    const Loader = QueryLoader<typeof SearchPharmaciesDocument>;

    const searchParams = useMemo(() => {
        if (!geolocation) {
            return { within: {} };
        }

        const { latitude, longitude } = geolocation;

        return {
            within: { coordinates: { longitude, latitude }, radiusMiles: 30 },
        };
    }, [geolocation]);

    useEffect(() => {
        if (providerOffersId && offerId) {
            setBackOverrideState({
                overrideFn: () => {
                    navigate(
                        getAbsoluteRoute('transfer.root', {
                            providerOffersId,
                            offerId,
                        })
                    );
                },
            });

            return () => setBackOverrideState({ overrideFn: null });
        }
    }, [providerOffersId, offerId]);
    if (!providerOffersId || !offerId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={SearchPharmaciesDocument}
            variables={{ searchParams }}
            queryOptions={{ errorPolicy: 'all' }}
            render={(data) => {
                const providers = data.findProviders.edges
                    .map((edge) => getFragment(edge.node))
                    .filter((p) => p.__typename !== 'MailOrderProvider');

                if (providers.length) {
                    return (
                        <TransferFrom
                            providers={providers}
                            providerOffersId={providerOffersId}
                            offerId={offerId}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
