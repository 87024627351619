import { Accordion } from '@phx/design-system';

import type { DigitallyReceivedPrescriberOrderInfoFragment } from '../../../graphql/generated/graphql';
import { PrescriberOrderDetails } from '../../../routes/orders/details/PrescriberOrderDetails';

import { PatientProductDetailsHeader } from './PatientProductDetailsHeader';

export type PatientProductDetailsProps = {
    patientName?: string;
    productDescription?: string;
    quantity?: number | null;
    prescriberOrder?: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const PatientProductDetails = ({
    patientName,
    productDescription,
    quantity,
    prescriberOrder,
}: PatientProductDetailsProps) => {
    const header = (
        <PatientProductDetailsHeader
            patientName={patientName}
            productDescription={productDescription}
            quantity={quantity}
        />
    );

    return (
        <>
            {prescriberOrder ? (
                <Accordion
                    items={[
                        {
                            header,
                            key: 'patient-product-details',
                            children: (
                                <PrescriberOrderDetails
                                    prescriberOrder={prescriberOrder}
                                />
                            ),
                        },
                    ]}
                />
            ) : (
                header
            )}
        </>
    );
};
