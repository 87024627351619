import {
    Body1,
    Box,
    Carousel,
    Flex,
    Heading2,
    HorizontalDivider,
    IconChevronRight,
    Image,
    Stack,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../../util';

import { useWhatsNewSections } from './whats-new-data';

export type WhatsNewSectionProps = {
    img: string;
    alt: string;
    title: string;
    content?: string;
    description?: string;
    date?: string;
    onClick?: () => void;
};

export const WhatsNewSection = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    const navigate = useNavigate();
    const whatsNewSections = useWhatsNewSections();

    return (
        <>
            {whatsNewSections.length ? (
                <>
                    <HorizontalDivider />
                    <Stack>
                        <Flex justify="space-between">
                            <Heading2>
                                {t('helpCenter.subheader.whatsNew')}
                            </Heading2>
                            <TextButton
                                RightIcon={IconChevronRight}
                                onClick={() =>
                                    navigate(
                                        getAbsoluteRoute(
                                            'helpCenter.whatsnew.root'
                                        )
                                    )
                                }
                            >
                                {t('helpCenter.viewAll')}
                            </TextButton>
                        </Flex>
                        <Carousel
                            withIndicators={false}
                            cropped
                            slides={whatsNewSections.map(
                                ({ img, alt, title, description }) => (
                                    <Stack gap="sm" key={title}>
                                        <Image src={img} alt={alt} />
                                        <Subtitle2>{title}</Subtitle2>
                                        <Box
                                            style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Body1>{description}</Body1>
                                        </Box>
                                    </Stack>
                                )
                            )}
                        />
                    </Stack>
                </>
            ) : null}
            <HorizontalDivider />
        </>
    );
};
