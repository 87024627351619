import {
    Button,
    Group,
    Heading1,
    IconInfo,
    Select,
    Stack,
    TextButton,
    TextInput,
    useNavBar,
} from '@phx/design-system';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';

import { getFragment, getFragments } from '../../graphql';
import type {
    DrugFamilyInfoFragment,
    PackageInfoFragment,
    ProductInfoFragment,
} from '../../graphql/generated/graphql';
import {
    computeDaysSupply,
    getAbsoluteRoute,
    getTotalQuantityDescription,
} from '../../util';

import { TotalQuantityDialog } from './TotalQuantityDialog';

type ProductConfigurationViewProps = {
    drugFamily: DrugFamilyInfoFragment;
    product: ProductInfoFragment;
    closeEdit: () => void;
};

/**
 * TODO: Component is TOO large needs to be broken up into smaller pieces.
 */
export const ProductConfigrationView = ({
    drugFamily,
    product,
    closeEdit,
}: ProductConfigurationViewProps) => {
    const { setBackOverrideState } = useNavBar();
    const { telemetryInstance } = useTelemetryContext();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation([
        'myphx',
        'code-locales/package-code-mapping',
        'code-locales/form-code-mapping',
    ]);
    const isMobile = useIsMobile();

    const [selectedForm, setSelectedForm] = useState(product.formCode ?? '');

    const [selectedStrength, setSelectedStrength] = useState(
        product.strengthDisplayText ?? '-'
    );
    const [selectedPackage, setSelectedPackage] = useState(product.id);
    const [daysSupply, setDaysSupply] = useState(product.modeDaysSupply ?? 30);
    const [selectedQuantity, setSelectedQuantity] = useState(
        product?.modeQuantity?.toString() ?? ''
    );
    const inputRef = useRef<HTMLInputElement>(null);

    const [customQuantity, setCustomQuantity] = useState('');

    const chosenQuantity =
        selectedQuantity === 'Custom'
            ? Number(customQuantity)
            : Number(selectedQuantity);

    const filteredForms = useMemo(() => {
        return drugFamily.forms
            .map((form) => getFragment(form))
            .filter((form) => form.formCode === selectedForm)
            .sort(
                (a, b) =>
                    Number(a?.strength?.strengthText) -
                    Number(b?.strength?.strengthText)
            );
    }, [selectedForm, drugFamily.forms]);

    const packageOptions = useMemo(() => {
        const selectedStrengthQuery =
            selectedStrength === '-' ? null : selectedStrength;
        const selectedFormStregnth = filteredForms.find(
            (form) => form.strengthDisplayText === selectedStrengthQuery
        );
        //If selected strength is not in filtered list select index 0
        if (!selectedFormStregnth && filteredForms.length) {
            setSelectedStrength(filteredForms[0]?.strengthDisplayText ?? '-');
        }

        if (selectedFormStregnth) {
            /**
             * Only display unique pacakge format descriptions.
             * If two packages have the same description we do not care which we use.
             */
            const fragments = getFragments(selectedFormStregnth.packages);
            const packageMap = new Map<string, PackageInfoFragment>();
            fragments.forEach((fragment) => {
                packageMap.set(fragment.packageFormatDescription, fragment);
            });

            /**
             * If we have a currently selected package we should ensure it is in our result set.
             */
            const selectedFragment = fragments.find(
                (fragment) => fragment.id === selectedPackage
            );
            if (selectedFragment) {
                packageMap.set(
                    selectedFragment.packageFormatDescription,
                    selectedFragment
                );
            }
            return [...packageMap.values()];
        }
        return [];
    }, [filteredForms, selectedForm, selectedStrength]);

    const quantityOptions = useMemo(() => {
        const packageOption = packageOptions.find(
            (option) => option.id === selectedPackage
        );

        //if package option not found select index 0
        if (!packageOption && packageOptions.length) {
            setSelectedPackage(packageOptions[0].id);
        }

        const options = packageOption ? packageOption.quantityOptions : [];
        const currentlySelectedQuantity = options.find(
            (option) => option.value === selectedQuantity
        );
        if (
            !currentlySelectedQuantity &&
            selectedQuantity !== 'Custom' &&
            options[0]?.value
        ) {
            setSelectedQuantity(options[0]?.value);
        }

        return [
            ...options,
            { value: 'Custom', displayText: t('drugConfig.customQuantity') },
        ];
    }, [selectedPackage, packageOptions]);

    const totalQuantity = useMemo(() => {
        const form = filteredForms.find(
            (form) =>
                form.defaultNdc === selectedPackage ||
                !!getFragments(form.packages).find(
                    (packageOption) => packageOption.id === selectedPackage
                )
        );
        if (form) {
            const formPackage = getFragments(form.packages).find(
                (option) => option.id === selectedPackage
            );

            if (formPackage) {
                return getTotalQuantityDescription(
                    chosenQuantity,
                    form,
                    formPackage
                );
            }
        }

        return {
            shortText: chosenQuantity.toString(),
            formulaText: chosenQuantity.toString(),
            quantityDescriptor: t('drugConfig.quantity'),
            value: chosenQuantity,
        };
    }, [filteredForms, selectedPackage, selectedQuantity, customQuantity]);

    useEffect(() => {
        const quantity =
            searchParams.get('qInput') ?? searchParams.get('quantity');
        if (quantity && quantity !== selectedQuantity) {
            const option = quantityOptions.find(
                (option) => option.value === quantity
            );

            if (option?.value) {
                setSelectedQuantity(quantity);
            } else {
                let quantitySet = false;
                const form = filteredForms.find(
                    (form) =>
                        form.defaultNdc === selectedPackage ||
                        !!getFragments(form.packages).find(
                            (packageOption) =>
                                packageOption.id === selectedPackage
                        )
                );
                const packageOption =
                    form &&
                    getFragments(form.packages).find(
                        (option) => option.id === selectedPackage
                    );

                // Check if any of the total values match our quantity
                if (form && packageOption) {
                    for (const quantityOption of packageOption.quantityOptions) {
                        const total = getTotalQuantityDescription(
                            Number(quantityOption.value),
                            form,
                            packageOption
                        ).value.toString();

                        if (total === quantity) {
                            setSelectedQuantity(quantityOption.value);
                            quantitySet = true;
                            break;
                        }
                    }
                }

                if (!quantitySet) {
                    setSelectedQuantity('Custom');
                    setCustomQuantity(quantity);
                }
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (selectedQuantity === 'Custom') {
            inputRef.current?.focus();
        }
    }, [selectedQuantity]);

    const applyEnabled = !!selectedPackage && chosenQuantity >= 1;

    const applyChanges = useCallback(() => {
        if (!applyEnabled) {
            return;
        }

        if (selectedPackage && chosenQuantity) {
            const searchParams = createSearchParams({
                quantity: totalQuantity.value.toString(),
                daysSupply: daysSupply.toString(),
                familyId: drugFamily.id,
                qInput: chosenQuantity.toString(),
            });

            setSearchParams((previous) => {
                return {
                    ...previous,
                    ...searchParams,
                };
            });

            telemetryInstance.logEvent({
                name: 'Drugconfig apply configuration changes',
                source: 'ProductConfiguration',
                properties: {
                    searchParams: searchParams.toString(),
                    drugId: selectedPackage,
                },
            });

            navigate({
                pathname: getAbsoluteRoute('drug.drugId.root', {
                    drugId: selectedPackage,
                }),
                search: searchParams.toString(),
            });

            closeEdit();
        }
    }, [selectedPackage, chosenQuantity, applyEnabled]);

    function updateDaysSupply(chosenQuantity: number) {
        const packageOption = packageOptions.find(
            (option) => option.id === selectedPackage
        );
        const daysSupply = packageOption
            ? computeDaysSupply(packageOption, chosenQuantity)
            : 30;

        setDaysSupply(daysSupply);
    }

    useEffect(() => {
        setBackOverrideState({
            overrideFn: closeEdit,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [closeEdit]);

    return (
        <Stack gap="lg" flex="1">
            <Stack gap="lg" flex={isMobile ? '1' : '0'}>
                <Heading1>{t('drugConfig.editMedication')}</Heading1>

                <Stack gap="md">
                    <Select
                        placeholder="-"
                        disabled={drugFamily.formCodes.length <= 1}
                        label={t('drugConfig.form')}
                        value={selectedForm}
                        onChange={(value) => {
                            value && setSelectedForm(value);
                        }}
                        data={drugFamily.formCodes.map((code) => ({
                            value: code,
                            label: t(code, {
                                ns: 'code-locales/form-code-mapping',
                                count: 1,
                            }),
                        }))}
                    />
                    <Select
                        placeholder="-"
                        disabled={filteredForms.length <= 1}
                        label={t('drugConfig.strength')}
                        value={selectedStrength}
                        onChange={(value) => {
                            value && setSelectedStrength(value);
                        }}
                        data={filteredForms.map((form) => ({
                            value: form.strengthDisplayText ?? '-',
                            label: form.strengthDisplayText ?? '-',
                        }))}
                    />
                    <Select
                        label={t('drugConfig.packagingForm')}
                        value={selectedPackage}
                        onChange={(value) => value && setSelectedPackage(value)}
                        disabled={packageOptions.length <= 1}
                        placeholder="-"
                        data={packageOptions.map((option) => ({
                            value: option.id,
                            label: option.packageFormatDescription,
                        }))}
                    />
                    <Group justify="space-between" grow wrap="nowrap">
                        <Select
                            label={t('drugConfig.quantity')}
                            disabled={quantityOptions.length <= 1}
                            value={selectedQuantity}
                            onChange={(value) => {
                                value && setSelectedQuantity(value);
                                if (value !== 'Custom') {
                                    updateDaysSupply(Number(value));
                                }
                            }}
                            data={quantityOptions.map((option) => ({
                                value: option.value,
                                label: option.displayText,
                            }))}
                        />
                        {selectedQuantity === 'Custom' ? (
                            <TextInput
                                label={totalQuantity.quantityDescriptor}
                                type="number"
                                value={customQuantity}
                                onChange={(event) => {
                                    setCustomQuantity(event.target.value);
                                    updateDaysSupply(
                                        Number(event.target.value)
                                    );
                                }}
                            />
                        ) : null}
                    </Group>
                </Stack>
                <TextButton
                    justify="left"
                    LeftIcon={IconInfo}
                    disabled={!applyEnabled}
                    onClick={() => setDialogOpen(true)}
                >
                    {t('drugConfig.totalQuantityButton', {
                        quantity: totalQuantity.shortText,
                    })}
                </TextButton>
                <TotalQuantityDialog
                    isOpen={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    formulaText={totalQuantity.formulaText}
                />
            </Stack>
            <Button disabled={!applyEnabled} onClick={applyChanges}>
                {t('drugConfig.applyChanges')}
            </Button>
        </Stack>
    );
};
