import type { FieldReadFunction, Reference } from '@apollo/client';

import {
    getComplexPackageFormatDescription,
    getSimplePackageFormatDescription,
} from '../../../util';

export const readPackageFormatDescription: FieldReadFunction = (
    _,
    { readField }
) => {
    const packageRelevance = readField<Reference>('packageRelevance');

    //if we could not determine relevance then we cannot show options
    if (!packageRelevance) {
        return '-';
    }
    const isSimpleQuantity = readField<boolean>(
        'isSimpleQuantity',
        packageRelevance
    );
    const isPackageRelevant = !!readField<boolean>(
        'isPackageRelevant',
        packageRelevance
    );
    const formCode = readField<string>('formCode') ?? '';
    const packageCode = readField<string>('packageCode') ?? '';
    const packageSize = readField<number>('packageSize') ?? 1;
    const packageSizeUnit = readField<string>('packageSizeUnit') ?? '';
    const packageQuantity = readField<number>('packageQuantity') ?? 1;
    const modeQuantity = readField<number>('modeQuantity') ?? 1;
    const modeDaysSupply = readField<number>('modeDaysSupply') ?? 1;

    const packageInfo = {
        formCode,
        modeQuantity,
        modeDaysSupply,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
    };

    if (isSimpleQuantity) {
        return getSimplePackageFormatDescription(
            packageInfo,
            isPackageRelevant
        );
    }

    return getComplexPackageFormatDescription(packageInfo);
};
