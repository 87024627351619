import type { FieldReadFunction, Reference } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
    PatientCoverageEdge,
    PatientCoveragesConnection,
} from '../../generated/graphql';
import {
    isActiveDateRange,
    isDisplayablePlan,
    isPhxBenefitsPlan,
} from '../util';

const exists = <T>(x: T | undefined): x is any => !!x;

export const readDisplayableCoverages: FieldReadFunction = (
    _,
    { readField }
) => {
    const coverageEdges = readField<PatientCoveragesConnection>(
        'coveragesConnection'
    )?.edges;
    const plans = coverageEdges
        ?.filter((edge: PatientCoverageEdge) =>
            isCoverageEdgeActive(edge, readField)
        )
        .map((edge) => {
            const node = readField<Reference>('node', edge);

            const plan = {
                __typename: readField('__typename', node),
                id: readField('id', node),
                groupPlanCode: readField('groupPlanCode', node),
                bin: readField('bin', node),
                pcn: readField('pcn', node),
                ...node,
            };
            return plan;
        });
    return plans?.filter(exists).filter(isDisplayablePlan) ?? [];
};

export const readHasPbmPlan: FieldReadFunction = (_, { readField }) => {
    const coverageEdges = readField<PatientCoveragesConnection>(
        'coveragesConnection'
    )?.edges;
    const hasPbm = !!coverageEdges?.some((edge: PatientCoverageEdge) => {
        if (!isCoverageEdgeActive(edge, readField)) {
            return false;
        }
        const node = readField<Reference>('node', edge);
        return isPhxBenefitsPlan({ __typename: readField('__typename', node) });
    });
    return hasPbm;
};

export const patientCoverages = {
    displayableCoverages: {
        read: readDisplayableCoverages,
    },
    hasPbmPlan: {
        read: readHasPbmPlan,
    },
};

const isCoverageEdgeActive = (
    edge: PatientCoverageEdge,
    readField: ReadFieldFunction
) => {
    const startDate = readField<string>('startDate', edge.coverage);
    const endDate = readField<string>('endDate', edge.coverage);

    const isCoverageActive =
        edge.coverage && isActiveDateRange(startDate, endDate);

    const node = readField<Reference>('node', edge);

    return node && isCoverageActive;
};
