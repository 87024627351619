import type {
    AltOffer,
    ProductConnection,
} from '../../components/cabinet/prescription/alternatives/Alternatives';
import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';
import { getMaxValue } from '../get-max-value';
import { getOffersByProviderId } from '../offers/get-offers-by-provider-id';
import { getPriceableOffers } from '../offers/get-priceable-offers';

import { getBestPatientSavings } from './get-best-patient-savings';
import { getBestPlanSavings } from './get-best-plan-savings';
import { getOffersForProvider } from './get-offers-for-provider';

export const getAltProducts = (
    altProductConnection: ProductConnection | undefined,
    cheapestOffer: AltOffer,
    providerId: string
) => {
    const altProducts = altProductConnection?.edges.map((x) => x.node) ?? [];

    const products = altProducts
        .map((product) => {
            const featuredOffers = getOffersForProvider(
                getFragment(product.offersConnection),
                providerId
            );

            const priceable = featuredOffers
                ? (getOffersByProviderId(
                      getPriceableOffers(featuredOffers),
                      providerId
                  ) as Array<
                      CashOfferInfoFragment | InsuranceOfferInfoFragment
                  >)
                : [];
            return {
                product: getFragment(product),
                pricing: priceable.map((offer) => offer.pricingDetail),
                patientSavings: getBestPatientSavings(
                    cheapestOffer,
                    featuredOffers
                ),
                planSavings: getBestPlanSavings(cheapestOffer, featuredOffers),
            };
        })
        .filter(
            ({ pricing, patientSavings, planSavings }) =>
                pricing.length && (patientSavings > 1 || planSavings > 20)
        );
    const bestPatientSavings = getMaxValue(products, (x) => x.patientSavings);
    const bestPlanSavings = getMaxValue(products, (x) => x.planSavings);
    return { products, bestPlanSavings, bestPatientSavings };
};
