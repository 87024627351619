import { Body1, Stack, Subtitle1 } from '@phx/design-system';
import { IllustrationCard, NoResultsIllustration } from '@phx/myphx-lib';

type NoSearchResultsProps = {
    message: string;
    title?: string;
};
export const NoSearchResults = ({ message, title }: NoSearchResultsProps) => {
    return (
        <IllustrationCard
            illustrationPosition="top"
            Illustration={NoResultsIllustration}
            illustrationSize="lg"
            variant="bare"
            withShadow={false}
        >
            <Stack gap="xs">
                {title && <Subtitle1 ta="center">{title}</Subtitle1>}
                <Body1 ta="center">{message}</Body1>
            </Stack>
        </IllustrationCard>
    );
};
