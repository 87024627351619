import { createFormContext } from '@phx/design-system/hooks';

export type VerifyFormDetails = {
    firstName: string;
    lastName: string;
    dob: string;
    attest: boolean;
};

export const [VerifyFormProvider, useVerifyFormContext, useVerifyForm] =
    createFormContext<VerifyFormDetails>();
