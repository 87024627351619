import { useLocation } from 'react-router-dom';

import { TransferSubmitted } from '../../components/transfer-flow/TransferSubmitted';

export const TransferSubmittedRoute = () => {
    const location = useLocation();
    const { prescriptionId, offer } = location.state;

    return <TransferSubmitted offer={offer} rxNumber={prescriptionId} />;
};
