import {
    FilterSelection,
    FullBleed,
    HorizontalDivider,
    IconLocalShipping,
    IconLocationOn,
    IconShoppingBag,
    Stack,
    Tabs,
} from '@phx/design-system';
import { useDisclosure, useIsMobile } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { geolocationDistance, useGeolocation } from '@phx/location-utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import {
    type ProviderOffersInfoFragment,
    SuggestedProviderOffersReason,
} from '../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { getAbsoluteRoute, getPrimaryProvider } from '../../util';
import { sortOffersByPrice } from '../../util/offers/sort';
import { InfoHeading } from '../common/InfoHeading';
import { LocationPickerCover } from '../location-picker-cover';

import { AlternativesLink } from './alternatives-link/AlternativesLink';
import { ProviderOffersCard, ProviderOffersCardList } from './cards';
import { NoLocation } from './cards/NoLocation';

type ProviderSelectionProps = {
    header: React.ReactNode;
    providerOffersByType: {
        delivery: ProviderOffersInfoFragment[];
        pickup: ProviderOffersInfoFragment[];
    };
    onSelect: (providerOffersId: string, providerId?: string) => void;
    featuredProvider?: {
        providerOffers: ProviderOffersInfoFragment;
        reason: SuggestedProviderOffersReason;
    };
};

export const ProviderSelection = ({
    header,
    providerOffersByType,
    onSelect,
    featuredProvider,
}: ProviderSelectionProps) => {
    const { telemetryInstance } = useTelemetryContext();

    const { t } = useTranslation();
    const { geolocation } = useGeolocation();
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();
    const isMobile = useIsMobile('md');
    const [searchParams, setSearchParms] = useSearchParams();

    const sortedPickupOffers = useMemo(() => {
        return providerOffersByType.pickup
            .map((offer) => {
                const primaryPharmacy = getPrimaryProvider(offer, geolocation);
                const distance =
                    primaryPharmacy &&
                    geolocation &&
                    primaryPharmacy.__typename === 'PharmacyProvider'
                        ? geolocationDistance(geolocation, {
                              latitude: primaryPharmacy.latitude,
                              longitude: primaryPharmacy.longitude,
                          })
                        : Infinity;
                return { ...offer, distance };
            })
            .sort((a, b) => a.distance - b.distance);
    }, [providerOffersByType.pickup]);

    const sortedDeliveryOffers = useMemo(() => {
        return sortOffersByPrice(providerOffersByType.delivery);
    }, [providerOffersByType.delivery]);

    const [
        pickupFiltersOpened,
        { open: openPickupFilters, close: closePickupFilters },
    ] = useDisclosure(false);
    const [
        deliveryFiltersOpened,
        { open: openDeliveryFilters, close: closeDeliveryFilters },
    ] = useDisclosure(false);

    const distance = useNumericSearchParam('within');
    const { prescriptionId } = useParams();

    const handleAltsClick = () => {
        if (prescriptionId && featuredProvider) {
            navigate({
                pathname: getAbsoluteRoute('cabinet.rxId.alternatives', {
                    prescriptionId,
                }),
                search: createSearchParams({
                    within: distance.toString(),
                }).toString(),
            });
        }
    };

    const defaultTab = searchParams.get('tab') || 'pickup';

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                {header}
                <AlternativesLink
                    onAltsClick={handleAltsClick}
                    cheapestOffers={featuredProvider?.providerOffers}
                />
            </Stack>
            {header && <HorizontalDivider />}
            <Stack gap={0}>
                <InfoHeading
                    title={t('pickYourPharmacy.title')}
                    informationDialog={{
                        title: t('pickYourPharmacy.infoDialog.title'),
                        content: t('pickYourPharmacy.infoDialog.body'),
                    }}
                />
            </Stack>

            <Stack gap="lg" role="list">
                <LocationPickerCover isOpen={opened} onClose={close} />
                <FilterSelection
                    inputTestId="pick-your-pharmacy-location-picker-button"
                    input={
                        geolocation?.name ??
                        t('pickYourPharmacy.location.setLocationAndDistance')
                    }
                    StartIcon={IconLocationOn}
                    onInputClick={open}
                    distance={
                        geolocation?.name
                            ? `${distance} ${t('common.distance.distanceUnit')}`
                            : undefined
                    }
                />
                {featuredProvider && (
                    <ProviderOffersCard
                        role="listitem"
                        suggestedReasons={[featuredProvider.reason]}
                        providerOffers={featuredProvider.providerOffers}
                        onSelected={onSelect}
                    />
                )}
            </Stack>

            {geolocation ? (
                <Tabs
                    defaultValue={defaultTab}
                    grow
                    onChange={(value: string | null) => {
                        if (value) {
                            setSearchParms((searchParams) => {
                                searchParams.set('tab', value);
                                return searchParams;
                            });
                            telemetryInstance.logEvent({
                                name: `Provider select view toggle, ${value}`,
                                source: 'ProviderSelection',
                            });
                        }
                    }}
                    tabBarProps={{ fullBleed: isMobile }}
                    tabs={[
                        {
                            label: t('pickYourPharmacy.pickUp'),
                            panel: (
                                <FullBleed breakpoint="md" color="gray">
                                    <ProviderOffersCardList
                                        providerOffers={sortedPickupOffers}
                                        onSelect={onSelect}
                                        pickUp
                                        filterOpen={pickupFiltersOpened}
                                        openFilter={openPickupFilters}
                                        closeFilter={closePickupFilters}
                                    />
                                </FullBleed>
                            ),
                            value: 'pickup',
                            LeftIcon: IconShoppingBag,
                        },
                        {
                            label: t('pickYourPharmacy.delivery'),
                            panel: (
                                <FullBleed breakpoint="md" color="gray">
                                    <ProviderOffersCardList
                                        providerOffers={sortedDeliveryOffers}
                                        onSelect={onSelect}
                                        filterOpen={deliveryFiltersOpened}
                                        openFilter={openDeliveryFilters}
                                        closeFilter={closeDeliveryFilters}
                                    />
                                </FullBleed>
                            ),
                            value: 'delivery',
                            LeftIcon: IconLocalShipping,
                        },
                    ]}
                />
            ) : (
                <NoLocation />
            )}
        </Stack>
    );
};
