import { getFragment } from '../../graphql';
import type { ProviderOffersInfoFragment } from '../../graphql/generated/graphql';
import type { usePatientContext } from '../../hooks/use-patient-context';

/**
 * Filters an array of provider offers by pricing options.
 *
 * @param offers An array of provider offers
 * @param withInsurance Boolean indicating whether to filter for insurance offers
 * @param smartPrice Boolean indicating whether to filter for cash offers
 * @returns The filtered array of provider offers based on the pricing options
 */
export function filterByPricing(
    offers: ProviderOffersInfoFragment[],
    {
        withInsurance,
        smartPrice,
    }: { withInsurance: boolean; smartPrice: boolean }
) {
    return offers.filter((offer) => {
        const hasInsurance = offer.offers.some(
            (o) => o.__typename === 'InsuranceOffer'
        );
        const hasCashOffer = offer.offers.some(
            (o) => o.__typename === 'CashOffer'
        );

        if (withInsurance && smartPrice) {
            return hasInsurance && hasCashOffer;
        }

        if (withInsurance) {
            return hasInsurance;
        }

        if (smartPrice) {
            return hasCashOffer;
        }

        return true;
    });
}

/**
 * Filters an array of provider offers by the user's favorite providers.
 *
 * @param offers An array of provider offers
 * @param checkIsFavorite A function that checks if a provider is marked as a favorite
 * @returns The filtered array of provider offers based on the user's favorites
 */
export function filterByFavorites(
    offers: ProviderOffersInfoFragment[],
    checkIsFavorite: ReturnType<
        typeof usePatientContext
    >['preferences']['favoritePharmacies']['checkIsFavorite']
) {
    return offers.filter((offer) => {
        const provider = getFragment(offer.provider);
        if (provider.__typename === 'ChainProvider') {
            return provider.pharmacies?.some((fragment) => {
                const pharmacy = getFragment(fragment);
                return checkIsFavorite(pharmacy.id);
            });
        }
        return checkIsFavorite(provider.id);
    });
}
