import type {
    InsuranceOffer,
    ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';
import { getMaxValue } from '../get-max-value';
import type { AltOffer } from '../../components/cabinet/prescription/alternatives/Alternatives';

export const getBestPlanSavings = (
    originalOffer: AltOffer,
    providerOffers: ProviderOffersInfoFragment | undefined
) => {
    if (originalOffer.__typename !== 'InsuranceOffer') {
        return 0;
    }
    return getMaxValue(
        providerOffers?.offers.filter(
            (x) => x.__typename === 'InsuranceOffer'
        ) ?? [],
        (offer) =>
            originalOffer.planPaysAmount -
            (offer as InsuranceOffer).planPaysAmount
    );
};
