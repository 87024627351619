import {
    Alert,
    Anchor,
    Body1,
    Button,
    Flex,
    FullBleed,
    Heading1,
    HorizontalDivider,
    Modal,
    Stack,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { openTalkativeUI } from '@phx/talkative';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../../graphql';
import type {
    PatientInfoFragment,
    UnclaimedPrescriberOrderInfoFragment,
} from '../../../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../../../util';
import { UnclaimedPrescriptionDescription } from '../UnclaimedPrescriptionDescription';

import { MemberSelection } from './MemberSelection';

type VerifyPatientProps = {
    prescriberOrder: UnclaimedPrescriberOrderInfoFragment;
    claimPrescription: (patient: PatientInfoFragment) => void;
};

export const VerifyPatient = (props: VerifyPatientProps) => {
    const { prescriberOrder, claimPrescription } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedPatient, setSelectedPatient] =
        useState<PatientInfoFragment | null>(null);
    const [claimError, setClaimError] = useState<string | null>(null);
    const [isClaimLoading, setIsClaimLoading] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);

    const handleForSomeoneElse = () => {
        navigate(
            getAbsoluteRoute('cabinet.rxId.verify', {
                prescriptionId: prescriberOrder.drxId ?? prescriberOrder.id,
            })
        );
    };
    const handleSelectPatient = (patient: PatientInfoFragment) => {
        setSelectedPatient(patient);
        open();
    };
    const handleClaimPrescription = async () => {
        if (selectedPatient) {
            setIsClaimLoading(true);
            setClaimError(null);
            try {
                await claimPrescription(selectedPatient);
            } catch (error) {
                setClaimError(t('verifyPatient.error'));
                setIsClaimLoading(false);
            }
        }
    };
    return (
        <>
            {claimError && (
                <FullBleed breakpoint="md" color="error">
                    <Alert severity="error">{claimError}</Alert>
                </FullBleed>
            )}
            <Stack gap="lg">
                <Stack gap="sm">
                    <Heading1>{t('verifyPatient.title')}</Heading1>
                    <Body1>{t('verifyPatient.subtitle')}</Body1>{' '}
                </Stack>
                <UnclaimedPrescriptionDescription
                    prescriberOrder={prescriberOrder}
                />
                <HorizontalDivider />
                <Stack gap="sm">
                    {prescriberOrder.matchingPatientsConnection?.edges.map(
                        (x) => (
                            <MemberSelection
                                key={getFragment(x.node).id}
                                patient={getFragment(x.node)}
                                relationshipType={
                                    x.relationship.relationshipType
                                }
                                selectPatient={handleSelectPatient}
                            />
                        )
                    )}
                </Stack>
                <Button
                    size="lg"
                    variant="outline"
                    onClick={handleForSomeoneElse}
                >
                    {t('verifyPatient.forSomeoneElse')}
                </Button>
                <Flex gap="xxxs" justify="center">
                    <Body1>{t('verifyPatient.needHelp')}</Body1>
                    <Anchor onClick={openTalkativeUI}>
                        {t('verifyPatient.contactUs')}
                    </Anchor>
                </Flex>
                <Modal
                    onClose={close}
                    opened={opened || isClaimLoading}
                    title={t('verifyPatient.confirm.title')}
                >
                    <Stack gap="lg">
                        <Stack gap="sm">
                            <Body1>
                                {t('verifyPatient.confirm.verifyFor', {
                                    name: selectedPatient?.fullName,
                                })}
                            </Body1>
                            <UnclaimedPrescriptionDescription
                                prescriberOrder={prescriberOrder}
                            />
                        </Stack>
                        <Stack gap="sm">
                            <Button
                                onClick={handleClaimPrescription}
                                loading={isClaimLoading}
                            >
                                {t('verifyPatient.confirm.confirm')}
                            </Button>
                            <Button variant="outline" onClick={close}>
                                {t('verifyPatient.confirm.cancel')}
                            </Button>
                        </Stack>
                    </Stack>
                </Modal>
            </Stack>
        </>
    );
};
