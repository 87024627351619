import { Body1, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type {
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../../graphql/generated/graphql';
import { LocationInfo } from '../../../common/LocationInfo';

type EstimatedPriceSectionProps = {
    pharmacyProvider:
        | MailOrderProviderInfoFragment
        | PharmacyProviderInfoFragment;
};

export const EstimatedPriceSection = ({
    pharmacyProvider,
}: EstimatedPriceSectionProps) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Body1>{t('alternatives.estimatedPrice.description')}</Body1>
            {pharmacyProvider ? (
                <LocationInfo
                    location={pharmacyProvider}
                    showFavorite={false}
                />
            ) : null}
        </Stack>
    );
};
