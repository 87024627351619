import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    AddLocationsToFavoritesDocument,
    GetLocationDocument,
    GetPatientFavoriteLocationsDocument,
    RemoveLocationsFromFavoritesDocument,
} from '../../graphql/generated/graphql';

import { getOptimisticPatient } from './get-optimistic-patient';
import { getOtherFavoriteLocations } from './get-other-favorite-locations';
import { usePatientFavoritesCache } from './use-patient-favorites-cache';

const successResponse = {
    code: '200',
    success: true,
    message: 'Success',
    inFlight: true,
};

/**
 * useFavoritePharmacies is meant to only encapsulate logic for the
 * PatientProvider and not to be used elsewhere. Methods relating to
 * favorite pharmacy behavior should be consumed by proxy of the provider.
 */
export const useFavoritePharmacies = (patientId: string) => {
    const client = useApolloClient();

    const updateCachedFavorites = usePatientFavoritesCache(patientId);
    const refetchQueries = [
        {
            query: GetPatientFavoriteLocationsDocument,
            variables: { patientId },
        },
    ];

    const fetchLocationData = useCallback(
        async (locationId: string) => {
            const { data } = await client.query({
                query: GetLocationDocument,
                variables: { id: locationId },
            });
            return data;
        },
        [client]
    );

    const [addFavoritePharmacy] = useMutation(AddLocationsToFavoritesDocument, {
        update: (_, { data }) => {
            const newEdges =
                data?.addToPatientFavoritePharmacies?.patient?.preferences
                    .patientFavoriteLocationsConnection.edges;
            updateCachedFavorites(newEdges);
        },
        refetchQueries,
    });

    const handleAddFavoritePharmacy = async (locationId: string) => {
        const locationData = await fetchLocationData(locationId);
        const otherLocations = getOtherFavoriteLocations(
            locationId,
            locationData
        );
        addFavoritePharmacy({
            variables: { patientId, locationIds: [locationId] },
            optimisticResponse: otherLocations
                ? {
                      addToPatientFavoritePharmacies: {
                          ...successResponse,
                          ...getOptimisticPatient(otherLocations),
                      },
                  }
                : undefined,
            refetchQueries,
        });
    };

    const [removeFavoritePharmacy] = useMutation(
        RemoveLocationsFromFavoritesDocument,
        {
            update: (_, { data }) => {
                const newEdges =
                    data?.removeFromPatientFavoritePharmacies?.patient
                        ?.preferences.patientFavoriteLocationsConnection.edges;
                updateCachedFavorites(newEdges);
            },
            refetchQueries,
        }
    );

    const handleRemoveFavoritePharmacy = async (locationId: string) => {
        const locationData = await fetchLocationData(locationId);
        const otherLocations = getOtherFavoriteLocations(
            locationId,
            locationData
        );
        removeFavoritePharmacy({
            variables: { patientId, locationIds: [locationId] },
            optimisticResponse: otherLocations
                ? {
                      removeFromPatientFavoritePharmacies: {
                          ...successResponse,
                          ...getOptimisticPatient(otherLocations),
                      },
                  }
                : undefined,
            refetchQueries,
        });
    };

    return {
        removeFavorite: handleRemoveFavoritePharmacy,
        addFavorite: handleAddFavoritePharmacy,
    };
};
