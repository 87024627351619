import type { FieldReadFunction } from '@apollo/client';

export const locationField: FieldReadFunction = (
    existingDataFromCache,
    { args, toReference }
) => {
    return (
        existingDataFromCache ||
        toReference({
            __typename: 'Location',
            id: args?.id,
        })
    );
};
